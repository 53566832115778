import type { IBusinessSector} from "@silexpert/core";
import { Sector, businessSectors } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type BusinessSectorFormItem = {
  tag: string;
  isSelected: boolean | null;
  idSector: number | null;
};

export type BusinessSectorForm = {
  [key: string]: BusinessSectorFormItem;
};

type SettingAccountingPlanState = {
  step: number;
  businessSectorAccountingPlan: IBusinessSector[];
  form: BusinessSectorForm;
};

const defaultAccountingPlanState = ref<SettingAccountingPlanState>({
  step: 0,
  businessSectorAccountingPlan: [],
  form: {
    BASE_PLAN: {
      tag: "BASE_PLAN",
      isSelected: false,
      idSector: null,
    },
    SERVICE: {
      tag: "SERVICE",
      isSelected: false,
      idSector: null,
    },
    MARCHANDISES: {
      tag: "MARCHANDISES",
      isSelected: false,
      idSector: null,
    },
    PRODUITS: {
      tag: "PRODUITS",
      isSelected: false,
      idSector: null,
    },
    IS: {
      tag: "IS",
      isSelected: null,
      idSector: null,
    },
    GERANCE_MAJORITAIRE: {
      tag: "GERANCE_MAJORITAIRE",
      isSelected: null,
      idSector: null,
    },
    VAT: {
      tag: "VAT",
      isSelected: null,
      idSector: null,
    },
    ESPECES: {
      tag: "ESPECES",
      isSelected: null,
      idSector: null,
    },
    VEHICULE: {
      tag: "VEHICULE",
      isSelected: null,
      idSector: null,
    },
  },
});

export const useSettingAccountingPlanStore = defineStore("settingsAccountingPlan", {
  state: () => ref<SettingAccountingPlanState>(cloneDeep(defaultAccountingPlanState.value)),
  actions: {
    reset() {
      this.$state = cloneDeep(defaultAccountingPlanState.value);
    },
    async fetchBusinessSectors() {
      const societyStore = useSocietyStore();
      this.businessSectorAccountingPlan = await $silex().businessSector.get(
        societyStore.society!.id!,
        {},
      );
    },
    feedBusinessSectorsForm() {
      const societyStore = useSocietyStore();

      const businessSectorsForm = this.businessSectorAccountingPlan;
      const sectors = Object.values(businessSectors)
        .filter((sector) => !sector.module)
        .map((sector) => sector.id);
      businessSectorsForm.forEach((rBS) => {
        if (sectors.includes(rBS.idSector)) {
          this.form.BASE_PLAN.idSector = rBS.idSector;
          this.form.BASE_PLAN.isSelected = !!rBS.idSector;
        } else {
          const key = this.form[Sector[rBS.idSector]].tag;
          this.form[key].idSector = rBS.idSector;
          this.form[key].isSelected = rBS.idSector !== Sector.VAT;
        }
      });

      const businessSectorsFormid = businessSectorsForm.map((s) => s.idSector);
      const modules = Object.values(businessSectors)
        .filter((sector) => sector.module)
        .map((sector) => sector.id);
      const sectorNotChoosen = modules.filter((id) => !businessSectorsFormid.includes(id));
      sectorNotChoosen.forEach((id) => {
        const key = this.form[Sector[id]].tag;
        this.form[key].idSector = id;
        this.form[key].isSelected = id === Sector.VAT;
      });

      if (this.form.BASE_PLAN.idSector === null) {
        this.form.BASE_PLAN.idSector = societyStore.society?.idSector ?? null;
        this.form.BASE_PLAN.isSelected = !!societyStore.society?.idSector;
      }
    },
  },
});
