import type { BasePaginate, IThirdParty } from "@silexpert/core";
import { ThirdPartyType, generalThirdParties } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type ThirdPartiesTab = "providers" | "customers";
export type ThirdParties = (IThirdParty & { isLoading: boolean })[];

// a common format for GenericThirdParty and ThirdParty so they can be displayed together
export type FormattedThirdParty = {
  id: number | null;
  label: string;
  type: string | "generic" | "provider" | "customer" | "account" | "super generic";
  // if the thirdParty is associated to a category
  idAccount: number | null | undefined;
  color?: string;
};

export type ThirdPartiesQueryProperties = {
  tab: ThirdPartiesTab | null;
  search: string | undefined;
  page: number;
  limit: number;
  // the SP is opened if thirdPartyId is defined
  thirdPartyId: number | null;
};

export const defaultThirdPartiesQueryProperties = ref<ThirdPartiesQueryProperties>({
  tab: null,
  search: undefined,
  page: 1,
  limit: 25,
  thirdPartyId: null,
});

type ThirdPartyState = {
  queryProperties: ThirdPartiesQueryProperties;
  thirdParties: ThirdParties | null;
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  checkedIds: number[];
  allThirdParties: IThirdParty[];
  genericThirdParties: IThirdParty[];
  hasAnyItem: boolean | null;
  controllers: {
    hasAny: AbortController | null;
    thirdParties: AbortController | null;
  };
};

export const useThirdPartiesStore = defineStore("thirdParties", {
  state: () =>
    ref<ThirdPartyState>({
      queryProperties: cloneDeep(defaultThirdPartiesQueryProperties.value),
      thirdParties: null,
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: true,
      checkedIds: [],
      hasAnyItem: null,
      genericThirdParties: [],
      allThirdParties: [],
      controllers: {
        hasAny: null,
        thirdParties: null,
      },
    }),
  getters: {
    isProviderTab: (state) => state.queryProperties.tab === "providers",
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultThirdPartiesQueryProperties.value);
      this.thirdParties = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.checkedIds = [];
      this.allThirdParties = [];
      this.genericThirdParties = [];
      this.hasAnyItem = null;
    },
    getFormattedThirdParties(params: {
      generic: boolean;
      accountSpe?: boolean;
      provider: boolean;
      customer: boolean;
      withDeleted?: boolean;
    }) {
      const { generic, accountSpe = false, provider, customer, withDeleted = false } = params;

      const udsColors = getUdsColors();
      const categoryStore = useCategoryStore();
      const genericThirdParties = this.genericThirdParties ?? [];
      const superThirdParties = generalThirdParties;

      const listAccountsFiltered = (categoryStore.categories ?? []).filter(
        (acc) =>
          !acc.isHidden &&
          ["455", "467", "451", "108"].includes(acc.number?.toString().substring(0, 3)),
      );

      const providerThirdParties = withDeleted
        ? (this.allThirdParties ?? []).filter(
            (tP) => tP.idThirdPartyType === ThirdPartyType.PROVIDER,
          )
        : (this.allThirdParties ?? []).filter(
            (tP) => tP.idThirdPartyType === ThirdPartyType.PROVIDER && !isDefined(tP.deletedAt),
          );
      const customerThirdParties = withDeleted
        ? (this.allThirdParties ?? []).filter(
            (tP) => tP.idThirdPartyType === ThirdPartyType.CUSTOMER,
          )
        : (this.allThirdParties ?? []).filter(
            (tP) => tP.idThirdPartyType === ThirdPartyType.CUSTOMER && !isDefined(tP.deletedAt),
          );

      return [
        ...(generic
          ? genericThirdParties.map((tp) => {
              return {
                id: tp.id ?? 0,
                label:
                  isDefined(tp?.company) && tp.company !== ""
                    ? tp.company
                    : (tp.fullname ?? tp.fullnameAccountant ?? ""),
                type: "generic",
                idAccount: null,
                color: udsColors.udsCerulean500,
              };
            })
          : []),
        ...(accountSpe
          ? listAccountsFiltered.map((tp) => {
              return {
                id: null,
                label: tp.name ?? "",
                type: "account",
                idAccount: tp.id,
                color: udsColors.udsOrange500,
              };
            })
          : []),
        ...(provider
          ? providerThirdParties.map((tp) => {
              return {
                id: tp.id ?? 0,
                label:
                  isDefined(tp?.company) && tp.company !== ""
                    ? tp.company
                    : (tp.fullname ?? tp.fullnameAccountant ?? ""),
                type: tp.idThirdPartyType === ThirdPartyType.PROVIDER ? "provider" : "customer",
                idAccount: tp.idAccount,
                color:
                  tp.idThirdPartyType === ThirdPartyType.PROVIDER
                    ? udsColors.udsFire500
                    : udsColors.udsFern500,
              };
            })
          : []),
        ...(customer
          ? customerThirdParties.map((tp) => {
              return {
                id: tp.id ?? 0,
                label:
                  isDefined(tp?.company) && tp.company !== ""
                    ? tp.company
                    : (tp.fullname ?? tp.fullnameAccountant ?? ""),
                type: tp.idThirdPartyType === ThirdPartyType.PROVIDER ? "provider" : "customer",
                idAccount: tp.idAccount,
                color:
                  tp.idThirdPartyType === ThirdPartyType.PROVIDER
                    ? udsColors.udsFire500
                    : udsColors.udsFern500,
              };
            })
          : []),
        ...superThirdParties.map((tp) => {
          return {
            id: 0,
            label: tp.company ?? "",
            idAccount: tp.idAccount,
            type: "super generic",
            color: udsColors.udsFire500,
          };
        }),
      ];
    },
    getMatchingThirdParty(params: { idThirdParty: number | null; idAccount?: number | null }) {
      const formattedThirdParties = this.getFormattedThirdParties({
        generic: true,
        provider: true,
        customer: true,
        accountSpe: true,
        withDeleted: true,
      });
      const { idThirdParty, idAccount } = params;
      const matchingThirdParty = formattedThirdParties.find((tP: any) =>
        isDefined(idThirdParty)
          ? tP.id === idThirdParty
          : isDefined(idAccount)
            ? tP.idAccount === idAccount
            : null,
      );
      return matchingThirdParty;
    },
    async fetchThirdParties() {
      this.isLoading = true;

      const { tab, search, page, limit } = this.queryProperties;
      let response: any = null;

      if (this.controllers.thirdParties && this.controllers.thirdParties?.signal) {
        this.controllers.thirdParties.abort();
      }
      this.controllers.thirdParties = new AbortController();

      await $silex()
        .thirdParty.getPaginated(
          { page, limit },
          { search, type: tab === "providers" ? ThirdPartyType.PROVIDER : ThirdPartyType.CUSTOMER },
          undefined,
          this.controllers.thirdParties.signal,
        )
        .then((res: BasePaginate<IThirdParty>) => {
          response = res;

          this.maxPages = response?.maxPages ?? 0;
          this.currentPage = response?.currentPage ?? 0;
          this.totalItems = response?.totalItems ?? 0;
          const data: ThirdParties = response?.data ?? [];

          const thirdParties = data.map((tier) => {
            return {
              ...tier,
              isLoading: false,
            };
          });

          this.thirdParties = thirdParties;
          this.isLoading = false;
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });
    },
    async fetchHasAnyItem() {
      this.hasAnyItem = null;

      if (this.controllers.hasAny && this.controllers.hasAny?.signal) {
        this.controllers.hasAny.abort();
      }
      this.controllers.hasAny = new AbortController();

      try {
        const response = await $silex().thirdParty.getPaginated(
          { page: 1, limit: 1 },
          {
            type:
              this.queryProperties.tab === "providers"
                ? ThirdPartyType.PROVIDER
                : ThirdPartyType.CUSTOMER,
          },
          undefined,
          this.controllers.hasAny.signal,
        );

        const totalItems = response?.totalItems ?? 0;
        const hasAnyItem = totalItems > 0;

        this.hasAnyItem = hasAnyItem;
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async updateThirdParty({
      thirdPartyId,
      payload,
    }: {
      thirdPartyId: number;
      payload: Partial<IThirdParty>;
    }) {
      this.isLoading = true;
      await $silex()
        .thirdParty.update(thirdPartyId, payload)
        .then((thirdParty: IThirdParty) => {
          this.setTier({
            thirdPartyId,
            thirdParty,
          });

          $notifier().open({
            content: "Changement enregistré",
            type: "success",
          });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });

      this.isLoading = false;
    },
    setTier(payload: { thirdPartyId: number; thirdParty: IThirdParty }) {
      const { thirdPartyId, thirdParty } = payload;
      const thirdParties = this.thirdParties ?? [];
      const allThirdParties = this.allThirdParties ?? [];
      const tierIndexToChange = thirdParties.findIndex((t) => t.id === thirdPartyId);
      const allThirdPartiesIndexToChange = allThirdParties.findIndex((t) => t.id === thirdPartyId);
      if (tierIndexToChange > -1 && this.thirdParties) {
        this.thirdParties[tierIndexToChange] = { ...thirdParty, isLoading: false };
      }
      if (allThirdPartiesIndexToChange > -1 && this.allThirdParties) {
        this.allThirdParties[tierIndexToChange] = thirdParty;
      }
    },
    setNewThirdParty(thirdParty: IThirdParty) {
      if (thirdParty.isGeneric) {
        this.genericThirdParties.push(thirdParty);
      } else {
        this.allThirdParties.push(thirdParty);
      }
    },
    async fetchThirdPartiesAndGenericThirdParties() {
      const thirdParties = await $silex().thirdParty.getAll();

      const sortedResponse = [...thirdParties].sort((a, b) =>
        `${a.fullname || a.company || a.fullnameAccountant || "Aucun nom"}`
          .toLowerCase()
          .localeCompare(
            `${b.fullname || b.company || a.fullnameAccountant || "Aucun nom"}`.toLowerCase(),
          ),
      );

      const thirdPartiesFiltered = sortedResponse.filter((thirdParty) => !thirdParty.isGeneric);
      const genericThirdParties = sortedResponse.filter((thirdParty) => thirdParty.isGeneric);

      this.allThirdParties = thirdPartiesFiltered;
      this.genericThirdParties = genericThirdParties;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
