import { Role, SubscriptionType } from "@silexpert/core";

export function useAccessComposable() {
  const freemiumPaths = [
    "/sales",
    "/settings",
    "/creation",
    "/autoLogin",
    "/login",
    "/appointment",
    "/registration-company",
    "/previsional-balance",
  ];

  function isFreemiumRestrictedPath(path: string): boolean {
    return freemiumPaths.find((p) => path.startsWith(p)) === undefined;
  }

  function isRestricted(): boolean {
    const brand = useBrandsComposable();
    const roleComposable = useRolesComposable();
    const societyStore = useSocietyStore();

    const dayjs = useDayjs();

    const isFreemiumBrand = ["Comptalib", "Pourcentage", "Clementine"].includes(
      brand.getBrandName(),
    );
    const isFreemium = [null, SubscriptionType.FREEMIUM].includes(
      societyStore.society?.idSubscriptionType ?? null,
    );
    const isClient = roleComposable.hasOneOfTheseRoles([Role.CLIENT]);
    if (isFreemiumBrand && isFreemium && isClient) {
      /**
       * Facturation gratuite
       * Si l'utilisateur est freemium avec une période d'essai expirée
       * On le redirige vers la page sales2
       */

      const isTrialPeriodExpired =
        dayjs() >
        dayjs(societyStore.society?.dateInscription ?? societyStore.society?.createdAt).add(
          15,
          "days",
        );

      if (isTrialPeriodExpired) {
        return true;
      }
    }
    return false;
  }

  return { isFreemiumRestrictedPath, freemiumPaths, isRestricted };
}
