import { PrestationType, Role, RoleAllowedToLogInAsClient } from "@silexpert/core";
import { EPermissions } from "@/composables/permissions";

type DrawerSubItem = {
  icon: string;
  title: string;
  to: string;
};

export type DrawerItem = {
  id: number;
  isVisible: boolean;
  icon: string;
  iconV2?: string;
  // the tooltip that will appear over the icon when the drawer is collapsed
  tooltipCollapsed?: string;
  // the text on the item when the drawer is not collapsed
  title: string;
  // where a click on the item should redirect, absent if the drawer is a subMenu
  to?: string;
  // a number on the right of the item
  count?: number;
  // the tooltip that will appear over the counter
  countTooltip?: string;
  subMenu?: DrawerSubItem[];
  newTag?: boolean;
  isLocked?: boolean;
  counterLink?: string;
  // needInfoTabText?: string; replace with count 1 and tooltip ?
  subDrawer: boolean;
};

// const { hasOneOfTheseRoles } = useRolesComposable();
// const { hasOneOfThesePermissions } = usePermissionsComposable();

export function useDrawersComposable() {
  const permissionComposable = usePermissionsComposable();
  const roleComposable = useRolesComposable();
  const brandComposable = useBrandsComposable();
  const prestationComposable = usePrestationsComposable();
  const unlockedStore = useUnlockedStore();
  const societyStore = useSocietyStore();
  const adminSocietiesStore = useAdminSocietiesStore();
  const genericAccountStore = useGenericAccountStore();

  const { isNotSubjectToVat } = storeToRefs(societyStore);

  const havePermission = roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient);

  const isClientFromAccountantPartner = computed<boolean>(() => {
    return isDefined(societyStore.society?.partneredAccountantRelated);
  });

  const adminDrawers: DrawerItem[] = ref([
    ...(roleComposable.hasOneOfTheseRoles([Role.PARTNERED_ACCOUNTANT])
      ? [
          {
            id: 1,
            isVisible: true,
            icon: "mail_outline",
            to: "/expert/performances",
            title: "Mes demandes",
            subDrawer: false,
          },
          {
            id: 2,
            isVisible: true,
            icon: "folder",
            to: "/expert/clients",
            title: "Clients",
            subDrawer: false,
          },
          {
            id: 3,
            isVisible: true,
            icon: "description",
            to: "/expert/factures",
            title: "Factures",
            subDrawer: false,
          },
        ]
      : [
          {
            id: 1,
            isVisible: true,
            icon: "show_chart",
            to: "/admin/performances",
            title: "Console",
            subDrawer: false,
          },
          {
            id: 2,
            isVisible: true,
            icon: "home",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultAdminSocietiesQueryProperties.value,
              {
                ...adminSocietiesStore.queryProperties,
              },
              `/admin/societies`,
            ),
            title: "Sociétés",
            subDrawer: false,
          },
          {
            id: 3,
            isVisible: true,
            icon: "account_balance",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultGenericAccountQueryProperties.value,
              {
                ...genericAccountStore.queryProperties,
                page: 1,
                limit: 25,
                idVisible: 2,
              },
              `/admin/genericaccount`,
            ),
            title: "Plan comptables",
            subDrawer: false,
          },
        ]),
  ]).value;

  const drawers: DrawerItem[] = ref([
    // DASHBOARD
    {
      id: 0,
      isVisible:
        (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.STATS])) ||
        havePermission,
      // hasOneOfTheseRoles([ERoles.ADMIN]) &&
      // hasOneOfThesePermissions([EPermissions.COMPTA]),
      icon: "insert_chart",
      iconV2: "dashboard",
      tooltipCollapsed: "Performances",
      title: "Performances",
      to: "/dashboard",
      subDrawer: false,
    },
    // BANKS
    {
      id: 1,
      isVisible:
        (permissionComposable.hasOneOfThesePermissions([EPermissions.COMPTA]) &&
          roleComposable.hasOneOfTheseRoles([Role.CLIENT])) ||
        havePermission,
      icon: "account_balance",
      iconV2: "bank",
      tooltipCollapsed: "Banques",
      title: "Banques",
      count: (unlockedStore.bankDocument ?? 0) + (unlockedStore.cashDocument ?? 0),
      countTooltip: "Opération(s) à annoter",
      to: "/banks",
      counterLink: `/banks?toAnnotate=true&startDate="1970-01-01"&endDate="2170-12-31"`,
      subDrawer: false,
    },
    // PURCHASES
    {
      id: 2,
      isVisible:
        (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.PURCHASE])) ||
        havePermission,
      icon: "shopping_cart",
      iconV2: "purchase",
      tooltipCollapsed: "Achats",
      countTooltip: "Facture(s) à annoter",
      count: (unlockedStore.personalDocument ?? 0) + (unlockedStore.purchaseDocument ?? 0),
      title: "Achats",
      to: "/purchases",
      ...(unlockedStore.personalDocument === 0
        ? {
            counterLink:
              '/purchases?tab="invoices"&startDate="1970-01-01"&endDate="2170-12-31"&isLocked=false&toAnnotate=true',
          }
        : {}),
      subDrawer: false,
    },
    // SALES
    {
      id: 3,
      isVisible:
        (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.GESCOM])) ||
        havePermission,
      icon: "description",
      iconV2: "sale",
      tooltipCollapsed: "Ventes",
      countTooltip: "Facture(s) à annoter",
      count: unlockedStore.saleDocument,
      title: "Ventes",
      to: "/sales",
      counterLink: `/sales?tab="Factures"&startDate="1970-01-01"&endDate="2170-12-31"&isLocked=false&toAnnotate=true`,
      subDrawer: false,
    },
    // CREATION
    {
      id: 10,
      isVisible:
        brandComposable.isClementine() &&
        prestationComposable.hasOneOfThesePrestations([
          PrestationType.STATUTS_OPTION_1,
          PrestationType.STATUTS_OPTION_2,
          PrestationType.STATUTS_FREE,
          PrestationType.CREATION_SOCIETE,
        ]),
      icon: "rocket_launch",
      iconV2: "rocket_launch",
      tooltipCollapsed: "Juridique",
      title: "Juridique",
      to: "/juridical",
      subDrawer: false,
    },

    // EMPLOYEES
    {
      id: 4,
      isVisible:
        (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.SOCIAL]) &&
          brandComposable.hasOneOfTheseBrands([
            EBrandNames.CLEMENTINE,
            EBrandNames.COMPTASTART,
            EBrandNames.POURCENTAGE,
          ])) ||
        (havePermission &&
          brandComposable.hasOneOfTheseBrands([
            EBrandNames.CLEMENTINE,
            EBrandNames.COMPTASTART,
            EBrandNames.POURCENTAGE,
          ])),
      isLocked:
        brandComposable.hasOneOfTheseBrands([EBrandNames.CLEMENTINE, EBrandNames.POURCENTAGE]) &&
        !prestationComposable.hasSignedPaidSocialPrestation(),
      icon: "group",
      iconV2: "salaries",
      tooltipCollapsed: "Salariés",
      title: "Salariés",
      to: "/employees",
      subDrawer: false,
    },
    // LIBRARY
    {
      id: 5,
      isVisible:
        (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.DOC_TRESO])) ||
        havePermission,
      icon: "work",
      iconV2: "library",
      tooltipCollapsed: "Bibliothèque",
      title: "Bibliothèque",
      to: '/library?tab="Généralités"',
      subDrawer: false,
    },
    // VAT & DAS2
    {
      id: 6,
      isVisible:
        (!isNotSubjectToVat.value &&
          permissionComposable.hasOneOfThesePermissions([EPermissions.TVA]) &&
          brandComposable.hasOneOfTheseBrands([EBrandNames.COMPTALIB, EBrandNames.COMPTASTART])) ||
        havePermission,
      icon: "description",
      iconV2: "vat",
      tooltipCollapsed: "Déclarations",
      title: "TVA",
      to: "/vat",
      subDrawer: false,
    },
    // BALANCESHEET
    ...(brandComposable.isComptaStart()
      ? [
          {
            id: 7,
            isVisible: !prestationComposable.hasOneOfThesePrestationsSignedPaid([
              PrestationType.COMPTA_PRO_COMPTASTART,
              PrestationType.COMPTA_PRO_COMPTASTART_EXPRESS,
            ]),
            icon: "local_offer",
            tooltipCollapsed: "Faire mon bilan",
            title: "Faire mon bilan",
            to: "/freemium/balancesheet",
            subDrawer: false,
          },
          {
            id: 7,
            isVisible:
              havePermission ||
              permissionComposable.hasOneOfThesePermissions([EPermissions.BALANCESHEET]),
            icon: "assignment",
            tooltipCollapsed: "Bilan",
            title: "Bilan",
            to: "/balancesheet",
            subDrawer: false,
          },
        ]
      : [
          {
            id: 7,
            isVisible:
              (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
                permissionComposable.hasOneOfThesePermissions([EPermissions.BALANCESHEET])) ||
              havePermission,
            icon: "assignment",
            iconV2: "balancesheet",
            tooltipCollapsed: "Bilan",
            title: "Bilan",
            to: "/balancesheet",
            subDrawer: false,
          },
        ]),

    // SUB-DRAWER
    // #################################

    // SETTINGS
    ...(!brandComposable.isComptalib()
      ? [
          {
            id: 8,
            isVisible:
              (roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
                (!brandComposable.isClementine() ||
                  permissionComposable.hasOneOfThesePermissions([EPermissions.SETTING]))) ||
              havePermission,
            icon: "settings",
            iconV2: "settings",
            tooltipCollapsed: "Paramètres",
            title: "Paramètres",
            to: "/settings",
            subDrawer: true,
          },
        ]
      : []),
    // PARTNER ACCOUNTANTS
    ...(brandComposable.isComptalib()
      ? [
          {
            id: 11,
            isVisible: !isClientFromAccountantPartner.value,
            icon: "settings",
            iconV2: "partner-accountants",
            tooltipCollapsed: "Experts-Comptables",
            title: "Experts-Comptables",
            to: "/partner-accountants",
            subDrawer: true,
          },
        ]
      : []),
    // PARTNERS
    ...(brandComposable.hasOneOfTheseBrands([
      EBrandNames.CLEMENTINE,
      EBrandNames.COMPTALIB,
      EBrandNames.POURCENTAGE,
    ])
      ? [
          {
            id: 9,
            isVisible: roleComposable.hasOneOfTheseRoles([Role.CLIENT]) || havePermission,
            icon: "show_chart",
            iconV2: "market",
            tooltipCollapsed: "Partenaires",
            title: "Partenaires",
            to: "/partners",
            subDrawer: true,
          },
        ]
      : []),
    // Shop
    ...(brandComposable.hasOneOfTheseBrands([EBrandNames.CLEMENTINE, EBrandNames.POURCENTAGE])
      ? [
          {
            id: 10,
            isVisible:
              roleComposable.hasOneOfTheseRoles([Role.CLIENT, Role.PARTNERED_ACCOUNTANT]) ||
              havePermission,
            icon: "shopping_basket",
            tooltipCollapsed: "Boutique",
            title: "Boutique",
            to: "/shop/services",
            subDrawer: true,
          },
        ]
      : []),
  ]).value;

  return { drawers, adminDrawers };
}
