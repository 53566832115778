import type {
  CerfaAddressFromQuestion,
  CreateExerciceQuestions,
  IAnswerType,
  IBalanceSheetQuestion,
  IBusinessPartner,
  ISociety,
  ImmobilizationSelected,
  OtherActivity,
  OtherAllocation,
  PartnersLegalEntity,
  PartnersNaturalPerson,
} from "@silexpert/core";
import {
  ActivityDetail,
  AnswerType,
  AnswerTypeValue,
  CerfaType,
  CountryId,
  LegalForm,
  LegalFormTypeEnum,
  Quality,
  QualityList,
  Question,
  QuestionCategoryWording,
  QuestionWording,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type Address = {
  city: null | string;
  idCountry: CountryId | null;
  number: string | null;
  street: string | null;
  zipCode: string | null;
};

export type Affiliate = {
  address: Address;
  holdingPercent: number;
  legalForm: string;
  name: string;
  siret: string;
};

export type QuestionPreFill = {
  answer: string | null;
  address: Address | null;
  affiliates: Affiliate[];
  list: ImmobilizationSelected[];
  otherActivity: OtherActivity[];
  otherDistribution: OtherAllocation[];
  addresses: Address[];
  partners: null | {
    legalEntities?: PartnersLegalEntity[];
    naturalPersons?: PartnersNaturalPerson[];
  };
  idQuestion: number;
  answerType: IAnswerType;
  label: string;
  idAnswerTypeValue: null | any;
  isEmail?: boolean;
  isSiret?: boolean;
};

type QuestionObject = { [x: string]: QuestionPreFill };

type BalanceSheetFormStep = {
  id?: number;
  mainTitle?: string;
  mainStep?: number;
  title: string;
  questions: QuestionObject;
};

interface BalanceSheetFormState {
  questions: IBalanceSheetQuestion[];
  isLoading: boolean;
  isSaving: boolean;
  step: number;
  cerfa2031: null | {
    steps: BalanceSheetFormStep[];
  };
  cerfa2035: null | {
    steps: BalanceSheetFormStep[];
  };
  cerfa2065: null | {
    steps: BalanceSheetFormStep[];
  };
  companyTaxes: null | {
    step: number;
    steps: BalanceSheetFormStep[];
  };
  meta: {
    hasPayment?: boolean;
    hasPreviousDeficit?: boolean;
    hasReportDeficit?: boolean;
    hasMostValues?: boolean;
    hasRentGains?: boolean;
    hasDonation?: boolean;
    hasAdvancesFromRent?: boolean;
    hasRuleProvision?: boolean;
    isParentCompany?: boolean;
    hasPreviousAddressChanged?: boolean;
    hasAffiliates?: boolean;
    isClosingExercice?: boolean;
    isInGroup?: boolean;
    isInCountryToCountry?: boolean;
    hasBenefitButExcludeToAdvance?: boolean;
    hasRuledProvisions?: boolean;
    hasRemainingDeficit?: boolean;
    hasTransferredDeficit?: boolean;
    hasIncubentEmployee?: boolean;
    hasRebilling?: boolean;
    hasRent?: boolean;
    hasDepreciation?: boolean;
    hasExoneration?: boolean;
    hasCharges?: boolean;
    hasTaxes?: boolean;
    hasRemuneration?: boolean;
    hasCapitalExonered?: boolean;
    hasCapitalImposit?: boolean;
    hasNetResult?: boolean;
    isOnLeasingManagement?: boolean;
    hasEmployees?: boolean;
    hasReserve?: boolean;
    hasDebts?: boolean;
    hasReceivables?: boolean;
    hasInvested?: boolean;
    hasPremiumsAndContributions?: boolean;
    hasDoneDirectDebit?: boolean;
    hadHelp?: boolean;
    hasInvestAmortization?: boolean;
    hasMedicalWork?: boolean;
    liberal?: boolean;
    isCivilMember?: boolean;
    hasOtherActivities?: boolean;
    gainSpreading?: boolean;
    gainSpreadingLastExercices?: boolean;
    gainDeffered?: boolean;
    gainSpreadingLastExercicesLong?: boolean;
    hasIndirectContributions?: boolean;
    hasEntitiesOutsideFrance?: boolean;
  };
}

export const defaultBalanceSheetAddress: CerfaAddressFromQuestion = {
  city: null,
  idCountry: CountryId.FRANCE,
  number: null,
  street: null,
  zipCode: null,
};

export const defaultBalanceSheetNaturalPerson: PartnersNaturalPerson = {
  address: cloneDeep(defaultBalanceSheetAddress),
  birthDate: "",
  birthDepartment: "",
  birthPlace: "",
  firstname: "",
  holdingPercent: 0,
  idBirthCountry: CountryId.FRANCE,
  idCivility: 1,
  individualProfessionalExpenses: 0,
  isOwner: false,
  lastname: "",
  marriedName: "",
  shareNumber: 0,
  taxNumber: "",
};

export const defaultBalanceSheetLegalEntity: PartnersLegalEntity = {
  address: cloneDeep(defaultBalanceSheetAddress),
  holdingPercent: 0,
  legalForm: "",
  name: "",
  shareNumber: 0,
  siret: "",
  isParentCompany: false,
};

export const defaultBalanceSheetAffiliates = {
  address: cloneDeep(defaultBalanceSheetAddress),
  holdingPercent: 0,
  legalForm: "",
  name: "",
  siret: "",
};

function createQuestionByName(name: Question, questions: IBalanceSheetQuestion[]): QuestionPreFill {
  const question = questions.find((q) => q.name === name);
  if (!question) throw new Error(`La question ${name} est manquante`);

  return {
    answer: null,
    address: null,
    addresses: [],
    partners: null,
    idAnswerTypeValue: null,
    idQuestion: question!.id!,
    answerType: question.answerType!,
    affiliates: [],
    otherActivity: [],
    otherDistribution: [],
    list: [],
    // @ts-expect-error
    label: QuestionWording[question.name] ?? "",
  };
}

function preFillNaturalPartner(
  society: ISociety,
  businessPartner: IBusinessPartner,
): PartnersNaturalPerson {
  const dayjs = useDayjs();
  const user = businessPartner.user;

  return {
    ...defaultBalanceSheetNaturalPerson,
    birthDate: user?.birthdate ? dayjs(user.birthdate).format("YYYY-MM-DD") : "",
    address: {
      ...defaultBalanceSheetNaturalPerson.address,
      number: user?.streetNumber ?? null,
      street: user?.address ?? null,
      zipCode: user?.zipCode ?? null,
      city: user?.city ?? null,
    },
    birthDepartment: user?.departmentBirth ?? "",
    birthPlace: user?.birthPlace ?? user?.cityOfBirth ?? "",
    firstname: user?.firstname ?? "",
    holdingPercent: businessPartner?.sharesHeld
      ? (businessPartner?.sharesHeld * 100) /
        (society.totalSharesHeld ?? businessPartner?.sharesHeld)
      : 0,
    shareNumber: businessPartner?.sharesHeld ?? 0,
    idBirthCountry: user?.idCountryBirth ?? CountryId.FRANCE,
    idCivility: user?.idCivility ?? 1,
    individualProfessionalExpenses: 0,
    isOwner: [Quality.GERANT, Quality.PRESIDENT, Quality.PDG].includes(
      businessPartner?.idQuality ?? -1,
    ),
    lastname: user?.lastname ?? "",
    marriedName: "",
    taxNumber: "",
  };
}

function preFillLegalEntityPartner(
  society: ISociety,
  businessPartner: IBusinessPartner,
): PartnersLegalEntity {
  return {
    ...defaultBalanceSheetAffiliates,
    address: {
      city: businessPartner?.society?.city ?? defaultBalanceSheetAddress.city,
      idCountry: businessPartner?.society?.idCountry ?? defaultBalanceSheetAddress.idCountry,
      number:
        businessPartner?.society?.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
      street:
        businessPartner?.society?.address?.replace(/[0-9]/g, "") ??
        defaultBalanceSheetAddress.street,
      zipCode: businessPartner?.society?.zipCode ?? defaultBalanceSheetAddress.zipCode,
    },
    name: businessPartner?.society?.name ?? "",
    holdingPercent: businessPartner?.sharesHeld
      ? (businessPartner?.sharesHeld * 100) /
        (society.totalSharesHeld ?? businessPartner?.sharesHeld)
      : 0,
    legalForm: businessPartner?.society?.idLegalForm
      ? (Object.values(LegalForm).find((l) => l.id === businessPartner?.society?.idLegalForm)
          ?.name ?? "")
      : "",
    shareNumber: businessPartner?.sharesHeld ?? 0,
    siret: businessPartner?.society?.siret ?? "",
    isParentCompany: false,
  };
}

function addEmptyStringIntoNullValue(answer: any) {
  for (const key in answer) {
    if (answer[key] === null) {
      answer[key] = "";
    }
  }

  return answer;
}

export const useBalanceSheetFormStore = defineStore("balanceSheetForm", {
  state: () =>
    ref<BalanceSheetFormState>({
      cerfa2031: null,
      cerfa2035: null,
      cerfa2065: null,
      companyTaxes: null,
      isLoading: false,
      isSaving: false,
      questions: [],
      step: 1,
      meta: {},
    }),
  actions: {
    reset() {
      this.cerfa2031 = null;
      this.cerfa2035 = null;
      this.cerfa2065 = null;
      this.companyTaxes = null;
      this.questions = [];
      this.isLoading = false;
      this.isSaving = false;
      this.step = 1;
      this.meta = {};
    },
    isValidAnswers(
      questions: QuestionObject,
      params: {
        answers?: Question[];
        address?: Question[];
        addresses?: Question[];
        naturalPersons?: Question[];
        legalEntities?: Question[];
        affiliates?: Question[];
        answerTypeValues?: Question[];
        otherActivity?: Question[];
      },
    ): boolean {
      const {
        answers,
        address,
        addresses,
        naturalPersons,
        legalEntities,
        affiliates,
        answerTypeValues,
        otherActivity,
      } = params;
      let valid = true;

      answers?.forEach((ra) => {
        if (!isDefined(questions![ra].answer) || questions![ra].answer === "") {
          valid = false;
        }
      });

      answerTypeValues?.forEach((atv) => {
        if (
          !isDefined(questions![atv].idAnswerTypeValue) ||
          questions![atv].idAnswerTypeValue === ""
        ) {
          valid = false;
        }
      });

      otherActivity?.forEach((oa) => {
        questions![oa].otherActivity.forEach((question) => {
          if (!isDefined(question?.legalForm) || question?.legalForm === "") {
            valid = false;
            return;
          }

          if (!isDefined(question?.name) || question?.name === "") {
            valid = false;
            return;
          }

          if (!isDefined(question?.salary)) {
            valid = false;
          }
        });
      });

      address?.forEach((ra) => {
        const question = questions![ra].address;
        if (!isDefined(question?.number)) {
          valid = false;
          return;
        }
        if (!isDefined(question?.street) || question?.street === "") {
          valid = false;
          return;
        }
        if (!isDefined(question?.zipCode) || question?.zipCode === "") {
          valid = false;
          return;
        }
        if (!isDefined(question?.city) || question?.city === "") {
          valid = false;
          return;
        }
        if (!isDefined(question?.idCountry)) {
          valid = false;
        }
      });

      addresses?.forEach((ra) => {
        questions![ra].addresses.forEach((question) => {
          if (!isDefined(question?.number) || question?.number === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.street) || question?.street === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.zipCode) || question?.zipCode === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.city) || question?.city === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.idCountry)) {
            valid = false;
          }
        });
      });

      naturalPersons?.forEach((np) => {
        questions![np].partners!.naturalPersons!.forEach((question) => {
          if (!isDefined(question.idCivility)) {
            valid = false;
            return;
          }
          if (!isDefined(question?.firstname) || question?.firstname === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.lastname) || question?.lastname === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.birthDate) || question?.birthDate === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.birthPlace) || question?.birthPlace === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.birthDepartment) || question?.birthDepartment === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.holdingPercent)) {
            valid = false;
            return;
          }
          // if (!isDefined(question?.shareNumber)) {
          //   valid = false;
          //   return;
          // }
          if (!isDefined(question?.taxNumber) || question?.taxNumber === "") {
            valid = false;
            return;
          }
          // if (
          //   !isDefined(question?.individualProfessionalExpenses) && question?.individualProfessionalExpenses !== ""
          // ) {
          //   valid = false;
          //   return;
          // }
          if (!isDefined(question?.address?.number) || question?.address?.number === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.street) || question?.address?.street === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.zipCode) || question?.address?.zipCode === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.city) || question?.address?.city === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.idCountry)) {
            valid = false;
          }
        });
      });

      legalEntities?.forEach((np) => {
        questions![np].partners!.legalEntities!.forEach((question) => {
          if (!isDefined(question.name) || question.name === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.legalForm) || question.legalForm === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.siret) || question.siret === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.address.street) || question.address.street === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.address.number) || question.address.number === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.address.zipCode) || question.address.zipCode === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.address.city) || question.address.city === "") {
            valid = false;
            return;
          }
          if (!isDefined(question.address.idCountry)) {
            valid = false;
            return;
          }
          if (!isDefined(question.shareNumber)) {
            valid = false;
            return;
          }
          if (!isDefined(question.holdingPercent)) {
            valid = false;
          }
        });
      });

      affiliates?.forEach((a) => {
        questions![a].affiliates.forEach((question) => {
          if (!isDefined(question?.legalForm) || question?.legalForm === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.name) || question?.name === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.siret) || question?.siret === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.holdingPercent)) {
            valid = false;
            return;
          }
          if (!isDefined(question?.address.number) || question?.address.number === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address.street) || question?.address.street === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.zipCode) || question?.address?.zipCode === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.city) || question?.address?.city === "") {
            valid = false;
            return;
          }
          if (!isDefined(question?.address?.idCountry)) {
            valid = false;
          }
        });
      });

      return valid;
    },
    async saveAnswers(key: "cerfa2031" | "cerfa2035" | "cerfa2065" | "companyTaxes") {
      try {
        this.isSaving = true;

        const payload: CreateExerciceQuestions = { exerciceQuestions: [] };
        const dayjs = useDayjs();

        this[key]?.steps.forEach((step, index) => {
          Object.values(step.questions).forEach((question) => {
            let answer: string | null | undefined | number = question.answer;

            if (question.answerType.type === AnswerType["DATE_DD/MM/YYYY"]) {
              answer = answer ? dayjs(answer).format("DD/MM/YYYY") : undefined;
            } else if (question.answerType.type === AnswerType["DATE_DD/MM/YYYY_OR_NA"]) {
              answer = answer ? dayjs(answer).format("DD/MM/YYYY") : AnswerTypeValue.NA;
            } else if (question.answerType.type === AnswerType.INTEGER_OR_NA) {
              answer =
                !answer || isNaN(+answer) || +answer === 0
                  ? AnswerTypeValue.NA
                  : Math.round(+answer);
            } else if (
              question.answerType.type === AnswerType.NEGATIVE_PRICE_OR_NA ||
              question.answerType.type === AnswerType.POSITIVE_PRICE_OR_NA
            ) {
              answer = isDefined(question.answer) ? `${question.answer}` : AnswerTypeValue.NA;
            } else if (question.answerType.type === AnswerType.FREE_TEXT && answer === null) {
              return "";
            } else if (question.answerType.type === AnswerType.JSON) {
              if (question.addresses.length) {
                answer = JSON.stringify(question.addresses);
              } else if (question.affiliates.length) {
                answer = JSON.stringify(question.affiliates);
              } else if (question.otherActivity.length) {
                answer = JSON.stringify(question.otherActivity);
              } else if (question.otherDistribution.length) {
                answer = JSON.stringify(question.otherDistribution);
              } else if (question.list?.length) {
                answer = JSON.stringify(question.list);
              } else if (question.address) {
                answer = JSON.stringify(addEmptyStringIntoNullValue(question.address));
              } else if (question.partners) {
                answer = JSON.stringify(addEmptyStringIntoNullValue(question.partners));
              }
            } else if (question.answerType.type === AnswerType.PHONE_OR_NA) {
              if (answer === null) {
                answer = AnswerTypeValue.NA;
              }
            }

            const data = {
              idQuestion: question.idQuestion,
              idAnswerTypeValue: isDefined(question.idAnswerTypeValue)
                ? +question.idAnswerTypeValue
                : undefined,
              answer,
              step: index,
            };
            if (
              !isDefined(data.idAnswerTypeValue) &&
              (!isDefined(data.answer) || data.answer === "{}")
            ) {
              data.answer = null;
            }

            // @ts-expect-error
            payload.exerciceQuestions.push(data);
          });
        });

        const exerciceStore = useExerciceStore();
        await $silex().exerciceQuestion.updateOrCreate(exerciceStore.current!.id!, payload);

        if (key !== "companyTaxes") {
          const balanceSheetStore = useBalanceSheetStore();
          await $silex().registration.updateOrCreate(exerciceStore.current!.id!, {
            idCerfaType:
              key === "cerfa2031"
                ? CerfaType.CERFA_2031
                : key === "cerfa2035"
                  ? CerfaType.CERFA_2035
                  : key === "cerfa2065"
                    ? CerfaType.CERFA_2065
                    : -1,
          });

          if (key === "cerfa2065") {
            const idCerfaType =
              +dayjs(exerciceStore.current!.startDate).format("YYYY") <= 2021
                ? CerfaType.CERFA_2572_2022
                : CerfaType.CERFA_2572_2023;

            await $silex().registration.updateOrCreate(exerciceStore.current!.id!, {
              idCerfaType,
            });
          }

          await balanceSheetStore.fetchRegistrations();

          // Reset du formulaire si tout est ok
          this.step = 1;

          this.fetchForm(key);
          $notifier().open({ type: "success", content: "Votre liasse a bien été générée" });
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isSaving = false;
    },
    async fetchQuestions() {
      try {
        this.isLoading = true;
        this.questions = await $silex().balanceSheetQuestion.getAll();
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    fetchForm(key: "cerfa2031" | "cerfa2035" | "cerfa2065" | "companyTaxes") {
      const societyStore = useSocietyStore();
      const userStore = useUsersStore();
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      const society = societyStore.society!;
      const owner = societyStore.getOwner;
      const partners = societyStore.partners;

      const endYear = +dayjs(exerciceStore.current?.endDate ?? new Date()).format("YYYY");

      const naturalsPartners = [
        ...partners.filter((p) => isDefined(p.user)).map((u) => preFillNaturalPartner(society, u)),
      ];

      const isEiOrEirl = computed(() =>
        [LegalFormTypeEnum.EI, LegalFormTypeEnum.EIRL].includes(society!.idLegalForm!),
      );

      const legalEntitiesPartners = [
        ...partners
          .filter((p) => isDefined(p.society))
          .map((u) => preFillLegalEntityPartner(society, u)),
      ];

      const user = userStore.client;

      const activityType = society.idActivity
        ? Object.values(ActivityDetail).find((a) => a.id === society.idActivity)?.name ||
          (society.descriptionActivity ?? null)
        : null;
      if (key === "cerfa2031") {
        this.cerfa2031 = {
          steps: [
            {
              title: QuestionCategoryWording.GENERAL_INFORMATIONS,
              questions: {
                [Question.ACTIVITY_TYPE]: {
                  ...createQuestionByName(Question.ACTIVITY_TYPE, this.questions),
                  answer: activityType,
                },
                [Question.NAME]: {
                  ...createQuestionByName(Question.NAME, this.questions),
                  answer: society!.name ?? null,
                },
                [Question.EMAIL]: {
                  ...createQuestionByName(Question.EMAIL, this.questions),
                  answer: user?.email ?? null,
                  isEmail: true,
                },
                [Question.SIRET]: {
                  ...createQuestionByName(Question.SIRET, this.questions),
                  answer: society.siret ?? null,
                  isSiret: true,
                },
                [Question["2031_DIRECTORY_OF_TRADES"]]: {
                  ...createQuestionByName(Question["2031_DIRECTORY_OF_TRADES"], this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question["2031_DIRECTORY_OF_TRADES"],
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.PREVIOUS_ENTITY_ADDRESS]: {
                  ...createQuestionByName(Question.PREVIOUS_ENTITY_ADDRESS, this.questions),
                  address: null,
                },
                [Question.MAIN_ESTABLISHEMENT_ADDRESS]: {
                  ...createQuestionByName(Question.MAIN_ESTABLISHEMENT_ADDRESS, this.questions),
                  address: {
                    city: society.city ?? defaultBalanceSheetAddress.city,
                    idCountry: society.idCountry ?? defaultBalanceSheetAddress.idCountry,
                    number: society.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
                    street:
                      society.address?.replace(/[0-9]/g, "") ?? defaultBalanceSheetAddress.street,
                    zipCode: society.zipCode ?? defaultBalanceSheetAddress.zipCode,
                  },
                },
                [Question.ENTITY_ADDRESS]: {
                  ...createQuestionByName(Question.ENTITY_ADDRESS, this.questions),
                  address: {
                    city: society.city ?? defaultBalanceSheetAddress.city,
                    idCountry: society.idCountry ?? defaultBalanceSheetAddress.idCountry,
                    number: society.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
                    street:
                      society.address?.replace(/[0-9]/g, "") ?? defaultBalanceSheetAddress.street,
                    zipCode: society.zipCode ?? defaultBalanceSheetAddress.zipCode,
                  },
                },
                [Question.SECONDARY_ADDRESSES]: {
                  ...createQuestionByName(Question.SECONDARY_ADDRESSES, this.questions),
                  addresses: [],
                },
              },
            },
            {
              title: QuestionCategoryWording.PARTNERS,
              questions: {
                [Question.PARTNERS]: {
                  ...createQuestionByName(Question.PARTNERS, this.questions),
                  partners: {
                    legalEntities: isEiOrEirl
                      ? []
                      : legalEntitiesPartners.length
                        ? legalEntitiesPartners
                        : [defaultBalanceSheetLegalEntity],
                    naturalPersons: naturalsPartners.length
                      ? naturalsPartners
                      : [defaultBalanceSheetNaturalPerson],
                  },
                },
              },
            },
            {
              title: QuestionCategoryWording.AFFILIATES,
              questions: {
                [Question.AFFILIATES]: {
                  ...createQuestionByName(Question.AFFILIATES, this.questions),
                },
              },
            },
            {
              title: `${QuestionCategoryWording.ACCOUNTING} (1/2)`,
              questions: {
                [Question.HAS_ACCOUNTANT]: {
                  ...createQuestionByName(Question.HAS_ACCOUNTANT, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_ACCOUNTANT,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.EXTERNAL_PROVIDER]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.EXTERNAL_PROVIDER,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS]: {
                  ...createQuestionByName(
                    Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS,
                    this.questions,
                  ),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_NAME]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_NAME,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_PHONE]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_PHONE,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_ADDRESS]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.EXTERNAL_PROVIDER_NAME]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_NAME,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_PHONE]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_PHONE,
                  this.questions,
                ),
                [Question.HAS_FISCAL_COMPLIANCE_REVIEW]: {
                  ...createQuestionByName(Question.HAS_FISCAL_COMPLIANCE_REVIEW, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_FISCAL_COMPLIANCE_REVIEW,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.HAS_AMO_OR_APPOINTED_AUDITOR]: {
                  ...createQuestionByName(Question.HAS_AMO_OR_APPOINTED_AUDITOR, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_AMO_OR_APPOINTED_AUDITOR,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NONE)?.id,
                },
                [Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME]: {
                  ...createQuestionByName(
                    Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME,
                    this.questions,
                  ),
                },
                [Question.ACCOUNTANT_ADDRESS]: {
                  ...createQuestionByName(Question.ACCOUNTANT_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.ACCOUNTANT_NAME]: createQuestionByName(
                  Question.ACCOUNTANT_NAME,
                  this.questions,
                ),
                [Question.ACCOUNTANT_PHONE]: createQuestionByName(
                  Question.ACCOUNTANT_PHONE,
                  this.questions,
                ),
              },
            },
            {
              title: `${QuestionCategoryWording.ACCOUNTING} (2/2)`,
              questions: {
                [Question.DATE_OF_TERMINATION]: createQuestionByName(
                  Question.DATE_OF_TERMINATION,
                  this.questions,
                ),
                [Question.TERMINATION_MOTIVE]: {
                  ...createQuestionByName(Question.TERMINATION_MOTIVE, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.TERMINATION_MOTIVE,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NA)?.id,
                },
                [Question.ENTITY_PLAN]: {
                  ...createQuestionByName(Question.ENTITY_PLAN, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.ENTITY_PLAN,
                    this.questions,
                  ).answerType.answerTypeValues?.find(
                    (a) => a.value === AnswerTypeValue.PROFESSIONAL,
                  )?.id,
                },
              },
            },
            {
              title: QuestionCategoryWording["2031_ADDED_VALUE"],
              questions: {
                "2031_EXONERATE_LONG_TERM_ADDED_VALUE": {
                  ...createQuestionByName(
                    Question["2031_EXONERATE_LONG_TERM_ADDED_VALUE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                "2031_EXONERATE_SHORT_TERM_ADDED_VALUE": {
                  ...createQuestionByName(
                    Question["2031_EXONERATE_SHORT_TERM_ADDED_VALUE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                "2031_INDIVIDUAL_ADDED_VALUE": {
                  ...createQuestionByName(Question["2031_INDIVIDUAL_ADDED_VALUE"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                "2031_LONG_TERM_2_YEARS_ADDED_VALUE": {
                  ...createQuestionByName(
                    Question["2031_LONG_TERM_2_YEARS_ADDED_VALUE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                "2031_LONG_TERM_ADDED_VAUE_12_DOT_8": {
                  ...createQuestionByName(
                    Question["2031_LONG_TERM_ADDED_VAUE_12_DOT_8"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                "2031_SHORT_AND_LONG_TERM_ADDED_VALUE": {
                  ...createQuestionByName(
                    Question["2031_SHORT_AND_LONG_TERM_ADDED_VALUE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                "2031_SHOT_TERM_ADDED_VALUE_12_DOT_8": {
                  ...createQuestionByName(
                    Question["2031_SHOT_TERM_ADDED_VALUE_12_DOT_8"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2031_BENEFIT_ALLOWANCE"],
              questions: {
                [Question.START_UPS]: {
                  ...createQuestionByName(Question.START_UPS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                URBAN_FREE_ZONE: {
                  ...createQuestionByName(Question.URBAN_FREE_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                INNOVATIVE_YOUNG_COMPANY: {
                  ...createQuestionByName(Question.INNOVATIVE_YOUNG_COMPANY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                DEFENSE_RESTRUCTURING_ZONE: {
                  ...createQuestionByName(Question.DEFENSE_RESTRUCTURING_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                RURAL_DEVELOPMENT_ZONE: {
                  ...createQuestionByName(Question.RURAL_DEVELOPMENT_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                URBAN_ZONE_TO_BE_REVITALIZED: {
                  ...createQuestionByName(Question.URBAN_ZONE_TO_BE_REVITALIZED, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                NEW_GENERATION_ACTIVITY_ZONE: {
                  ...createQuestionByName(Question.NEW_GENERATION_ACTIVITY_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                PRIORITY_DEVELOPMENT_ZONE: {
                  ...createQuestionByName(Question.PRIORITY_DEVELOPMENT_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                TAKEOVER_OF_AILING_FIRMS: {
                  ...createQuestionByName(Question.TAKEOVER_OF_AILING_FIRMS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                EMPLOYMENT_AREA_TO_BE_REVITALIZED: {
                  ...createQuestionByName(
                    Question.EMPLOYMENT_AREA_TO_BE_REVITALIZED,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                ...(endYear < 2024
                  ? {
                      OVERSEAS_INVESTMENTS: {
                        ...createQuestionByName(Question.OVERSEAS_INVESTMENTS, this.questions),
                        answer: AnswerTypeValue.NA,
                      },
                    }
                  : {
                      [Question.OVERSEAS_INVESTMENTS_2024]: {
                        ...createQuestionByName(Question.OVERSEAS_INVESTMENTS_2024, this.questions),
                        answer: AnswerTypeValue.NA,
                      },
                    }),
              },
            },
            {
              title: `Informations revenus professionnels / non professionnels`,
              questions: {
                [Question["2031_PROFESSIONAL_CAPITAL_LOSS_SHORT_TERM"]]: createQuestionByName(
                  Question["2031_PROFESSIONAL_CAPITAL_LOSS_SHORT_TERM"],
                  this.questions,
                ),
                [Question["2031_PROFESSIONAL_TAXABLE_EXCLUDED_SOURCE_TAX_SHARE"]]:
                  createQuestionByName(
                    Question["2031_PROFESSIONAL_TAXABLE_EXCLUDED_SOURCE_TAX_SHARE"],
                    this.questions,
                  ),
                [Question["2031_NON_PROFESSIONAL_TAXABLE_EXCLUDED_SOURCE_TAX_SHARE"]]:
                  createQuestionByName(
                    Question["2031_NON_PROFESSIONAL_TAXABLE_EXCLUDED_SOURCE_TAX_SHARE"],
                    this.questions,
                  ),
                [Question["2031_NON_PRO_FURNISHED_RENTAL_BENEFICE"]]: {
                  ...createQuestionByName(
                    Question["2031_NON_PRO_FURNISHED_RENTAL_BENEFICE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_NON_PRO_FURNISHED_RENTAL_LOSS"]]: {
                  ...createQuestionByName(
                    Question["2031_NON_PRO_FURNISHED_RENTAL_LOSS"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_OTHER_FURNISHED_RENTAL_BENEFICE"]]: {
                  ...createQuestionByName(
                    Question["2031_OTHER_FURNISHED_RENTAL_BENEFICE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_OTHER_FURNISHED_RENTAL_LOSS"]]: {
                  ...createQuestionByName(
                    Question["2031_OTHER_FURNISHED_RENTAL_LOSS"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_LEASE_MANAGEMENT_BENEFICE"]]: {
                  ...createQuestionByName(
                    Question["2031_LEASE_MANAGEMENT_BENEFICE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_LEASE_MANAGEMENT_LOSS"]]: {
                  ...createQuestionByName(Question["2031_LEASE_MANAGEMENT_LOSS"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_NON_PRO_STALLION_BENEFICE"]]: {
                  ...createQuestionByName(
                    Question["2031_NON_PRO_STALLION_BENEFICE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_NON_PRO_STALLION_LOSS"]]: {
                  ...createQuestionByName(Question["2031_NON_PRO_STALLION_LOSS"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_OTHER_NON_PRO_BENEFICE"]]: {
                  ...createQuestionByName(Question["2031_OTHER_NON_PRO_BENEFICE"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_OTHER_NON_PRO_LOSS"]]: {
                  ...createQuestionByName(Question["2031_OTHER_NON_PRO_LOSS"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2033_REGULARIZATION"],
              questions: {
                [Question.REGULARIZATION_IMMOBILIZATION_RATE_0]: {
                  ...createQuestionByName(
                    Question.REGULARIZATION_IMMOBILIZATION_RATE_0,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.REGULARIZATION_IMMOBILIZATION_RATE_15_12]: {
                  ...createQuestionByName(
                    Question.REGULARIZATION_IMMOBILIZATION_RATE_15_12,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.REGULARIZATION_IMMOBILIZATION_RATE_19]: {
                  ...createQuestionByName(
                    Question.REGULARIZATION_IMMOBILIZATION_RATE_19,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.REGULARIZATION_IMMOBILIZATION_ST]: {
                  ...createQuestionByName(
                    Question.REGULARIZATION_IMMOBILIZATION_ST,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_1"],
              questions: {
                [Question.EXCEPTIONAL_ENDOWMENT_INCREASE_30]: createQuestionByName(
                  Question.EXCEPTIONAL_ENDOWMENT_INCREASE_30,
                  this.questions,
                ),
                [Question.REGULATED_PROVISIONS_ENDOWMENT]: createQuestionByName(
                  Question.REGULATED_PROVISIONS_ENDOWMENT,
                  this.questions,
                ),
                [Question.REGULATED_PROVISIONS_RECOVERY]: createQuestionByName(
                  Question.REGULATED_PROVISIONS_RECOVERY,
                  this.questions,
                ),
                [Question.COMMERCIAL_FUNDS_ENDOWMENT]: {
                  ...createQuestionByName(Question.COMMERCIAL_FUNDS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.COMMERCIAL_FOUNDS_RECOVERY]: {
                  ...createQuestionByName(Question.COMMERCIAL_FOUNDS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INTANGIBLE_IMMOBILIZATIONS_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.INTANGIBLE_IMMOBILIZATIONS_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INTANGIBLE_IMMOBILIZATIONS_RECOVERY]: {
                  ...createQuestionByName(
                    Question.INTANGIBLE_IMMOBILIZATIONS_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SITE_ENDWOMENT]: {
                  ...createQuestionByName(Question.SITE_ENDWOMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SITE_RECOVERY]: {
                  ...createQuestionByName(Question.SITE_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.CONSTRUCTIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.CONSTRUCTIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.CONSTRUCTIONS_RECOVERY]: {
                  ...createQuestionByName(Question.CONSTRUCTIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TECHNICAL_INSTALLATIONS_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.TECHNICAL_INSTALLATIONS_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TECHNICAL_INSTALLATIONS_RECOVERY]: {
                  ...createQuestionByName(
                    Question.TECHNICAL_INSTALLATIONS_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.GENERAL_INSTALLATIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.GENERAL_INSTALLATIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.GENERAL_INSTALLATIONS_RECOVERY]: {
                  ...createQuestionByName(Question.GENERAL_INSTALLATIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TRANSPORTATION_EQUIPMENT_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.TRANSPORTATION_EQUIPMENT_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TRANSPORTATION_EQUIPMENT_RECOVERY]: {
                  ...createQuestionByName(
                    Question.TRANSPORTATION_EQUIPMENT_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OTHER_IMMOBILIZATIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.OTHER_IMMOBILIZATIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OTHER_IMMOBILIZATIONS_RECOVERY]: {
                  ...createQuestionByName(Question.OTHER_IMMOBILIZATIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_2"],
              questions: {
                [Question["N-1_DEFICIT_REPORTED"]]: createQuestionByName(
                  Question["N-1_DEFICIT_REPORTED"],
                  this.questions,
                ),
                [Question.FULL_RIGHT_DEFICIT_TRANSFERRED]: createQuestionByName(
                  Question.FULL_RIGHT_DEFICIT_TRANSFERRED,
                  this.questions,
                ),
                [Question.FULL_RIGHT_DEFICIT_IMPACTED]: createQuestionByName(
                  Question.FULL_RIGHT_DEFICIT_IMPACTED,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_3"],
              questions: {
                [Question.HANDMADE_ASSIGNED_EMPLOYEES]: createQuestionByName(
                  Question.HANDMADE_ASSIGNED_EMPLOYEES,
                  this.questions,
                ),
                [Question.DISABLED_EMPLOYEES_WORKFORCE]: createQuestionByName(
                  Question.DISABLED_EMPLOYEES_WORKFORCE,
                  this.questions,
                ),
                [Question.APPRENTICE_EMPLOYEES_WORKFORCE]: createQuestionByName(
                  Question.APPRENTICE_EMPLOYEES_WORKFORCE,
                  this.questions,
                ),
                [Question.TOTAL_WORKFORCE]: createQuestionByName(
                  Question.TOTAL_WORKFORCE,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2033_ADDED_VALUE"],
              questions: {
                "2033_CVAE_ENTITY_STAFF": createQuestionByName(
                  Question["2033_CVAE_ENTITY_STAFF"],
                  this.questions,
                ),
                "2033_CVAE_MONO_FACILITY": {
                  ...createQuestionByName(Question["2033_CVAE_MONO_FACILITY"], this.questions),
                  idAnswerTypeValue:
                    createQuestionByName(Question["2033_CVAE_MONO_FACILITY"], this.questions)!
                      .answerType?.answerTypeValues?.[1]?.id ?? null,
                },
                [Question.INVOICE_CHARGE_TRANSFER]: createQuestionByName(
                  Question.INVOICE_CHARGE_TRANSFER,
                  this.questions,
                ),
                [Question.REAL_ESTATE_ROYALTY_FEES_RENTS]: {
                  ...createQuestionByName(Question.REAL_ESTATE_ROYALTY_FEES_RENTS, this.questions),
                },
                [Question.DEDUCTIBLE_TAX_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_TAX_ADDED_VALUE, this.questions),
                },
                [Question.DEDUCTIBLE_EXPENSE_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_EXPENSE_ADDED_VALUE, this.questions),
                },
                [Question.DEDUCTIBLE_FRACTION_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_FRACTION_ADDED_VALUE, this.questions),
                },
                [Question["2033_CFE_CVAE"]]: createQuestionByName(
                  Question["2033_CFE_CVAE"],
                  this.questions,
                ),
                [Question.CA_ECONOMIC_GROUP]: createQuestionByName(
                  Question.CA_ECONOMIC_GROUP,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (1/4)",
              questions: {
                [Question.NON_DEDUCTIBLE_PERSONAL_PAY_AND_BENEFITS]: createQuestionByName(
                  Question.NON_DEDUCTIBLE_PERSONAL_PAY_AND_BENEFITS,
                  this.questions,
                ),
                [Question["2031_EXONERATE_MOVABLE_CAPITAL"]]: createQuestionByName(
                  Question["2031_EXONERATE_MOVABLE_CAPITAL"],
                  this.questions,
                ),
                [Question["2031_EXONERATE_MOVABLE_CAPITAL_SHARE"]]: createQuestionByName(
                  Question["2031_EXONERATE_MOVABLE_CAPITAL_SHARE"],
                  this.questions,
                ),
                [Question["2031_INDUSTRIAL_TRANSFER_TAXABLE_AT_10_RATE"]]: createQuestionByName(
                  Question["2031_INDUSTRIAL_TRANSFER_TAXABLE_AT_10_RATE"],
                  this.questions,
                ),
                [Question["2031_SUBMITTED_MOVABLE_CAPITAL"]]: createQuestionByName(
                  Question["2031_SUBMITTED_MOVABLE_CAPITAL"],
                  this.questions,
                ),
                [Question["2031_TONNAGE_TAX_EXPENSES_FOR_SEA_TRANSPORT_COMPANY"]]: {
                  ...createQuestionByName(
                    Question["2031_TONNAGE_TAX_EXPENSES_FOR_SEA_TRANSPORT_COMPANY"],
                    this.questions,
                  ),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_ACCOUNTANT,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
              },
            },
            {
              title: "Informations complémentaires (2/4)",
              questions: {
                [Question.LEASE_MANAGEMENT_NAME]: createQuestionByName(
                  Question.LEASE_MANAGEMENT_NAME,
                  this.questions,
                ),
                [Question.LEASE_MANAGEMENT_ADDRESS]: {
                  ...createQuestionByName(Question.LEASE_MANAGEMENT_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question["2031_ALL_KIND_GITFS_EXPENSE"]]: {
                  ...createQuestionByName(Question["2031_ALL_KIND_GITFS_EXPENSE"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2031_COMMISION_RETRO_TRANSFER_WORKFORCE"]]: createQuestionByName(
                  Question["2031_COMMISION_RETRO_TRANSFER_WORKFORCE"],
                  this.questions,
                ),
                [Question["2031_GROSS_AMOUNT_WORKFORCE"]]: createQuestionByName(
                  Question["2031_GROSS_AMOUNT_WORKFORCE"],
                  this.questions,
                ),
                [Question["2031_LUNCH_TRAVEL_EXPENSE"]]: {
                  ...createQuestionByName(Question["2031_LUNCH_TRAVEL_EXPENSE"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: "Informations complémentaires (3/4)",
              questions: {
                [Question.ORIGINAL_ARTWORK_RESERVE]: createQuestionByName(
                  Question.ORIGINAL_ARTWORK_RESERVE,
                  this.questions,
                ),
                [Question.DEBTS_SUPERIOR_YEAR]: createQuestionByName(
                  Question.DEBTS_SUPERIOR_YEAR,
                  this.questions,
                ),
                [Question.RECEIVABLES_SUPERIOR_YEAR]: createQuestionByName(
                  Question.RECEIVABLES_SUPERIOR_YEAR,
                  this.questions,
                ),
                [Question.EXCEPTIONAL_FEES_INNOVATIVE_COMPANY]: {
                  ...createQuestionByName(
                    Question.EXCEPTIONAL_FEES_INNOVATIVE_COMPANY,
                    this.questions,
                  ),
                  list: [],
                },
                [Question.OPTIONAL_CONTRIBUTIONS_DEDUCTIBLE]: createQuestionByName(
                  Question.OPTIONAL_CONTRIBUTIONS_DEDUCTIBLE,
                  this.questions,
                ),
                [Question.OPTIONAL_CONTRIBUTIONS]: createQuestionByName(
                  Question.OPTIONAL_CONTRIBUTIONS,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (4/4)",
              questions: {
                [Question.MANDATORY_PERSO_CONTRIBUTION_DEDUCTIBLE]: createQuestionByName(
                  Question.MANDATORY_PERSO_CONTRIBUTION_DEDUCTIBLE,
                  this.questions,
                ),
                [Question.MANDATORY_PERSO_CONTRIBUTION]: createQuestionByName(
                  Question.MANDATORY_PERSO_CONTRIBUTION,
                  this.questions,
                ),
                [Question.PERSONAL_TAKING_AMOUNT]: createQuestionByName(
                  Question.PERSONAL_TAKING_AMOUNT,
                  this.questions,
                ),
                [Question.TAX_REDUCTION_AID]: createQuestionByName(
                  Question.TAX_REDUCTION_AID,
                  this.questions,
                ),
                [Question.INVESTMENT_AMOUNT_RECEIVED]: createQuestionByName(
                  Question.INVESTMENT_AMOUNT_RECEIVED,
                  this.questions,
                ),
              },
            },
          ],
        };
      } else if (key === "cerfa2035") {
        this.cerfa2035 = {
          steps: [
            {
              title: QuestionCategoryWording.GENERAL_INFORMATIONS,
              questions: {
                [Question.ACTIVITY_TYPE]: {
                  ...createQuestionByName(Question.ACTIVITY_TYPE, this.questions),
                  answer: activityType,
                },
                [Question.NAME]: {
                  ...createQuestionByName(Question.NAME, this.questions),
                  answer: society!.name ?? null,
                },
                [Question.PHONE]: createQuestionByName(Question.PHONE, this.questions),
                [Question.MEDICAL_PRACTITIONER_ACTIVITY_CODE]: createQuestionByName(
                  Question.MEDICAL_PRACTITIONER_ACTIVITY_CODE,
                  this.questions,
                ),
                [Question.EMAIL]: {
                  ...createQuestionByName(Question.EMAIL, this.questions),
                  answer: user?.email ?? null,
                  isEmail: true,
                },
                [Question.SIRET]: {
                  ...createQuestionByName(Question.SIRET, this.questions),
                  answer: society.siret ?? null,
                  isSiret: true,
                },
                [Question.INITIAL_DATE]: {
                  ...createQuestionByName(Question.INITIAL_DATE, this.questions),
                },
                [Question.ENTITY_ADDRESS]: {
                  ...createQuestionByName(Question.ENTITY_ADDRESS, this.questions),
                  address: {
                    city: society.city ?? defaultBalanceSheetAddress.city,
                    idCountry: society.idCountry ?? defaultBalanceSheetAddress.idCountry,
                    number: society.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
                    street:
                      society.address?.replace(/[0-9]/g, "") ?? defaultBalanceSheetAddress.street,
                    zipCode: society.zipCode ?? defaultBalanceSheetAddress.zipCode,
                  },
                },
                [Question.DECLARANT_ADDRESS]: {
                  ...createQuestionByName(Question.DECLARANT_ADDRESS, this.questions),
                  address: defaultBalanceSheetAddress,
                },
                [Question.SECONDARY_ADDRESSES]: {
                  ...createQuestionByName(Question.SECONDARY_ADDRESSES, this.questions),
                  addresses: [],
                },
              },
            },
            {
              title: QuestionCategoryWording.PARTNERS,
              questions: {
                [Question.PARTNERS]: {
                  ...createQuestionByName(Question.PARTNERS, this.questions),
                  partners: {
                    legalEntities: isEiOrEirl
                      ? []
                      : legalEntitiesPartners.length
                        ? legalEntitiesPartners
                        : [defaultBalanceSheetLegalEntity],
                    naturalPersons: naturalsPartners.length
                      ? naturalsPartners
                      : [defaultBalanceSheetNaturalPerson],
                  },
                },
              },
            },
            {
              title: QuestionCategoryWording.AFFILIATES,
              questions: {
                [Question.AFFILIATES]: {
                  ...createQuestionByName(Question.AFFILIATES, this.questions),
                },
              },
            },
            {
              title: QuestionCategoryWording.LEGAL_STRUCTURE,
              questions: {
                [Question.INDEPENDANT_GROUPING]: {
                  ...createQuestionByName(Question.INDEPENDANT_GROUPING, this.questions),
                  address: null,
                },
                [Question.CIVIL_SOCIETY_MEMBER]: {
                  ...createQuestionByName(Question.CIVIL_SOCIETY_MEMBER, this.questions),
                  address: null,
                },
                [Question.ENTITY_PLAN]: {
                  ...createQuestionByName(Question.ENTITY_PLAN, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.ENTITY_PLAN,
                    this.questions,
                  ).answerType.answerTypeValues?.find(
                    (a) => a.value === AnswerTypeValue.PROFESSIONAL,
                  )?.id,
                },
                [Question.DATE_OF_TERMINATION]: createQuestionByName(
                  Question.DATE_OF_TERMINATION,
                  this.questions,
                ),
                [Question.TERMINATION_MOTIVE]: {
                  ...createQuestionByName(Question.TERMINATION_MOTIVE, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.TERMINATION_MOTIVE,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NA)?.id,
                },
                [Question.OTHER_ACTIVITIES]: createQuestionByName(
                  Question.OTHER_ACTIVITIES,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording.INCOMES_EXEMPTIONS,
              questions: {
                [Question.EXEMPTION_START_UPS_44_SEXIES]: {
                  ...createQuestionByName(Question.EXEMPTION_START_UPS_44_SEXIES, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                ...(endYear < 2024
                  ? {
                      [Question.EXEMPTION_START_UPS_44_QUINDECIES]: {
                        ...createQuestionByName(
                          Question.EXEMPTION_START_UPS_44_QUINDECIES,
                          this.questions,
                        ),
                        answer: AnswerTypeValue.NA,
                      },
                    }
                  : {}),
                [Question.EXEMPTION_URBAN_FREE_ZONE_ACTIVITIES]: {
                  ...createQuestionByName(
                    Question.EXEMPTION_URBAN_FREE_ZONE_ACTIVITIES,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                ...(endYear >= 2024
                  ? {
                      [Question.EXEMPTION_44_QUINDECIES_2024]: {
                        ...createQuestionByName(
                          Question.EXEMPTION_44_QUINDECIES_2024,
                          this.questions,
                        ),
                        answer: AnswerTypeValue.NA,
                      },
                    }
                  : {}),
                [Question.DEFICIT_ZONE_DOCTOR_EXEMPTIONS]: {
                  ...createQuestionByName(Question.DEFICIT_ZONE_DOCTOR_EXEMPTIONS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SECTOR_1_DOCTOR_EXEMPTIONS]: {
                  ...createQuestionByName(Question.SECTOR_1_DOCTOR_EXEMPTIONS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.YOUNG_ARTIST_BENEFIT_EXEMPTIONS]: {
                  ...createQuestionByName(Question.YOUNG_ARTIST_BENEFIT_EXEMPTIONS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SALARY_SAVINGS_CONTRIBUTION]: {
                  ...createQuestionByName(Question.SALARY_SAVINGS_CONTRIBUTION, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                ...(endYear < 2024
                  ? {
                      [Question.COVID_19_FUNDS]: {
                        ...createQuestionByName(Question.COVID_19_FUNDS, this.questions),
                        answer: AnswerTypeValue.NA,
                      },
                    }
                  : {}),
                [Question.EXEMPTION_INNOVATIVE_START_UPS]: {
                  ...createQuestionByName(Question.EXEMPTION_INNOVATIVE_START_UPS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.BENEFIT_EXEMPTIONS]: {
                  ...createQuestionByName(Question.BENEFIT_EXEMPTIONS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INCOME_TAX_EXCLUDED_EXPENSES]: {
                  ...createQuestionByName(Question.INCOME_TAX_EXCLUDED_EXPENSES, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INCOME_TAX_EXCLUDED_YIELDS]: {
                  ...createQuestionByName(Question.INCOME_TAX_EXCLUDED_YIELDS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.PATENT_TRANSFER_OR_INTANGIBLE_ASSTES_NET_TOTAL]: {
                  ...createQuestionByName(
                    Question.PATENT_TRANSFER_OR_INTANGIBLE_ASSTES_NET_TOTAL,
                    this.questions,
                  ),
                },
                [Question.INVESTMENT_INCOME]: {
                  ...createQuestionByName(Question.INVESTMENT_INCOME, this.questions),
                },
                [Question["PROFESSIONAL_LONG_RUN_TAXABLE_GAIN_AT_12-8_EXEMPTIONS"]]: {
                  ...createQuestionByName(
                    Question["PROFESSIONAL_LONG_RUN_TAXABLE_GAIN_AT_12-8_EXEMPTIONS"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.EXEMPTION_ENTRY_DATE]: {
                  ...createQuestionByName(Question.EXEMPTION_ENTRY_DATE, this.questions),
                },
                [Question.EXEMPTION_ACTIVITY_START_DATE]: {
                  ...createQuestionByName(Question.EXEMPTION_ACTIVITY_START_DATE, this.questions),
                },
                [Question.BENEFIT_EXEMPTION]: {
                  ...createQuestionByName(Question.BENEFIT_EXEMPTION, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INCLUDED_YOUNG_ARTIST_EXEMPTION]: {
                  ...createQuestionByName(Question.INCLUDED_YOUNG_ARTIST_EXEMPTION, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["LONG_RUN_TAXABLE_GAIN_AT_12-8_RATE"]]: {
                  ...createQuestionByName(
                    Question["LONG_RUN_TAXABLE_GAIN_AT_12-8_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.NON_PROFESSIONAL_SHORT_RUN_TAXABLE_GAIN]: {
                  ...createQuestionByName(
                    Question.NON_PROFESSIONAL_SHORT_RUN_TAXABLE_GAIN,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording.ACCOUNTING,
              questions: {
                [Question.HAS_ACCOUNTANT]: {
                  ...createQuestionByName(Question.HAS_ACCOUNTANT, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_ACCOUNTANT,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.EXTERNAL_PROVIDER]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.EXTERNAL_PROVIDER,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS]: {
                  ...createQuestionByName(
                    Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS,
                    this.questions,
                  ),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_NAME]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_NAME,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_PHONE]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_PHONE,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_ADDRESS]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.EXTERNAL_PROVIDER_NAME]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_NAME,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_PHONE]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_PHONE,
                  this.questions,
                ),
                [Question.HAS_FISCAL_COMPLIANCE_REVIEW]: {
                  ...createQuestionByName(Question.HAS_FISCAL_COMPLIANCE_REVIEW, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_FISCAL_COMPLIANCE_REVIEW,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.HAS_AMO_OR_APPOINTED_AUDITOR]: {
                  ...createQuestionByName(Question.HAS_AMO_OR_APPOINTED_AUDITOR, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_AMO_OR_APPOINTED_AUDITOR,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NONE)?.id,
                },
                [Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME]: createQuestionByName(
                  Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME,
                  this.questions,
                ),
                [Question.ACCOUNTANT_ADDRESS]: {
                  ...createQuestionByName(Question.ACCOUNTANT_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.ACCOUNTANT_NAME]: createQuestionByName(
                  Question.ACCOUNTANT_NAME,
                  this.questions,
                ),
                [Question.ACCOUNTANT_PHONE]: createQuestionByName(
                  Question.ACCOUNTANT_PHONE,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording.TOTAL_PAYROLL,
              questions: {
                [Question.TOTAL_EMPLOYEES]: createQuestionByName(
                  Question.TOTAL_EMPLOYEES,
                  this.questions,
                ),
                [Question.EMPLOYEE_SALARIES]: createQuestionByName(
                  Question.EMPLOYEE_SALARIES,
                  this.questions,
                ),
                [Question.APPRENTICE_EMPLOYEES]: createQuestionByName(
                  Question.APPRENTICE_EMPLOYEES,
                  this.questions,
                ),
                [Question.DISABLED_EMPLOYEES]: createQuestionByName(
                  Question.DISABLED_EMPLOYEES,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording.TAXABLE_GAIN,
              questions: {
                [Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_238_QUINDECIES]: {
                  ...createQuestionByName(
                    Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_238_QUINDECIES,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_A]: {
                  ...createQuestionByName(
                    Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_A,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES]: {
                  ...createQuestionByName(
                    Question.SHORT_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.N_TAXABLE_GAIN_SPREADING]: createQuestionByName(
                  Question.N_TAXABLE_GAIN_SPREADING,
                  this.questions,
                ),
                [Question["N-1_TAXABLE_GAIN_SPREADING"]]: createQuestionByName(
                  Question["N-1_TAXABLE_GAIN_SPREADING"],
                  this.questions,
                ),
                [Question["N-2_TAXABLE_GAIN_SPREADING"]]: createQuestionByName(
                  Question["N-2_TAXABLE_GAIN_SPREADING"],
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording.TAXABLE_GAIN_PART_2,
              questions: {
                [Question.N_LONG_RUN_ADDED_GAIN_DEFERRED_2_YEARS]: createQuestionByName(
                  Question.N_LONG_RUN_ADDED_GAIN_DEFERRED_2_YEARS,
                  this.questions,
                ),
                [Question["N-1_LONG_RUN_ADDED_GAIN_DEFERRED_2_YEARS"]]: createQuestionByName(
                  Question["N-1_LONG_RUN_ADDED_GAIN_DEFERRED_2_YEARS"],
                  this.questions,
                ),
                [Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_A]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_A,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_B]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_151_SEPTIES_B,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_238_QUINDECIES]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_TAXABLE_GAIN_EXEMPTION_238_QUINDECIES,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: `Informations complémentaires (1/3)`,
              questions: {
                [Question.STOCK]: {
                  ...createQuestionByName(Question.STOCK, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.STOCK,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.RENTS_AND_ROYALTIES]: {
                  ...createQuestionByName(Question.RENTS_AND_ROYALTIES, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.RENTS_AND_ROYALTIES,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.FISCAL_PERIOD_STARTING_STOCK]: createQuestionByName(
                  Question.FISCAL_PERIOD_STARTING_STOCK,
                  this.questions,
                ),
                [Question.FISCAL_PERIOD_ENDING_STOCK]: createQuestionByName(
                  Question.FISCAL_PERIOD_ENDING_STOCK,
                  this.questions,
                ),
                [Question.RENTS_AND_ROYALTIES_INCOME]: createQuestionByName(
                  Question.RENTS_AND_ROYALTIES_INCOME,
                  this.questions,
                ),
                [Question.RENTS_AND_ROYALTIES_ENDOWMENTS]: createQuestionByName(
                  Question.RENTS_AND_ROYALTIES_ENDOWMENTS,
                  this.questions,
                ),
                [Question.ENERGY_COMMODITIES_PARTICIPATION]: createQuestionByName(
                  Question.ENERGY_COMMODITIES_PARTICIPATION,
                  this.questions,
                ),
              },
            },
            {
              title: `Informations complémentaires (2/3)`,
              questions: {
                [Question.MANDATORY_SOCIAL_CHARGES]: {
                  ...createQuestionByName(Question.MANDATORY_SOCIAL_CHARGES, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OPTIONAL_SOCIAL_CHARGES]: {
                  ...createQuestionByName(Question.OPTIONAL_SOCIAL_CHARGES, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OPTIONAL_SOCIAL_CHARGES_FOR_RETIREMENT_SAVINGS]: {
                  ...createQuestionByName(
                    Question.OPTIONAL_SOCIAL_CHARGES_FOR_RETIREMENT_SAVINGS,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.RETROCESSION_FEES]: createQuestionByName(
                  Question.RETROCESSION_FEES,
                  this.questions,
                ),
                [Question.COLLABORATION_FEES]: createQuestionByName(
                  Question.COLLABORATION_FEES,
                  this.questions,
                ),
              },
            },
            {
              title: `Informations complémentaires (3/3)`,
              questions: {
                [Question.VAT_REGULARISATION]: createQuestionByName(
                  Question.VAT_REGULARISATION,
                  this.questions,
                ),
                [Question.ACCOUNTING_TAX_CREDIT]: createQuestionByName(
                  Question.ACCOUNTING_TAX_CREDIT,
                  this.questions,
                ),
                [Question.NOW_TAXABLE_ADDED_GAIN]: createQuestionByName(
                  Question.NOW_TAXABLE_ADDED_GAIN,
                  this.questions,
                ),
              },
            },
          ],
        };
      } else if (key === "cerfa2065") {
        this.cerfa2065 = {
          steps: [
            {
              title: QuestionCategoryWording.GENERAL_INFORMATIONS,
              questions: {
                [Question.HAS_ACTIVTY_CHANGED]: {
                  ...createQuestionByName(Question.HAS_ACTIVTY_CHANGED, this.questions),
                  idAnswerTypeValue:
                    createQuestionByName(Question.HAS_ACTIVTY_CHANGED, this.questions)!.answerType
                      ?.answerTypeValues?.[1]?.id ?? null,
                },
                [Question.DECLARANT_NAME]: {
                  ...createQuestionByName(Question.DECLARANT_NAME, this.questions),
                  answer:
                    owner?.firstname && owner?.lastname
                      ? `${owner?.firstname} ${owner?.lastname}`
                      : null,
                },
                [Question.DECLARANT_OCCUPATION]: {
                  ...createQuestionByName(Question.DECLARANT_OCCUPATION, this.questions),
                  answer:
                    QualityList.find((q) => q.id === owner?.businessPartners?.[0]?.idQuality)
                      ?.label ?? null,
                },
                [Question.ACTIVITY_TYPE]: {
                  ...createQuestionByName(Question.ACTIVITY_TYPE, this.questions),
                  answer: activityType,
                },
                [Question.NAME]: {
                  ...createQuestionByName(Question.NAME, this.questions),
                  answer: society!.name ?? null,
                },
                [Question.EMAIL]: {
                  ...createQuestionByName(Question.EMAIL, this.questions),
                  answer: user?.email ?? null,
                  isEmail: true,
                },
                [Question.SIRET]: {
                  ...createQuestionByName(Question.SIRET, this.questions),
                  answer: society.siret ?? null,
                  isSiret: true,
                },
                [Question.PREVIOUS_ENTITY_ADDRESS]: {
                  ...createQuestionByName(Question.PREVIOUS_ENTITY_ADDRESS, this.questions),
                  address: null,
                },
                [Question.MAIN_ESTABLISHEMENT_ADDRESS]: {
                  ...createQuestionByName(Question.MAIN_ESTABLISHEMENT_ADDRESS, this.questions),
                  address: {
                    city: society.city ?? defaultBalanceSheetAddress.city,
                    idCountry: society.idCountry ?? defaultBalanceSheetAddress.idCountry,
                    number: society.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
                    street:
                      society.address?.replace(/[0-9]/g, "") ?? defaultBalanceSheetAddress.street,
                    zipCode: society.zipCode ?? defaultBalanceSheetAddress.zipCode,
                  },
                },
                [Question.ENTITY_ADDRESS]: {
                  ...createQuestionByName(Question.ENTITY_ADDRESS, this.questions),
                  address: {
                    city: society.city ?? defaultBalanceSheetAddress.city,
                    idCountry: society.idCountry ?? defaultBalanceSheetAddress.idCountry,
                    number: society.address?.match(/\d+/)?.[0] ?? defaultBalanceSheetAddress.number,
                    street:
                      society.address?.replace(/[0-9]/g, "") ?? defaultBalanceSheetAddress.street,
                    zipCode: society.zipCode ?? defaultBalanceSheetAddress.zipCode,
                  },
                },
                [Question.SECONDARY_ADDRESSES]: {
                  ...createQuestionByName(Question.SECONDARY_ADDRESSES, this.questions),
                  addresses: [],
                },
              },
            },
            {
              title: QuestionCategoryWording.PARTNERS,
              questions: {
                [Question.PARTNERS]: {
                  ...createQuestionByName(Question.PARTNERS, this.questions),
                  partners: {
                    legalEntities: isEiOrEirl
                      ? []
                      : legalEntitiesPartners.length
                        ? legalEntitiesPartners
                        : [defaultBalanceSheetLegalEntity],
                    naturalPersons: naturalsPartners.length
                      ? naturalsPartners
                      : [defaultBalanceSheetNaturalPerson],
                  },
                },
              },
            },
            {
              title: QuestionCategoryWording.AFFILIATES,
              questions: {
                [Question.AFFILIATES]: {
                  ...createQuestionByName(Question.AFFILIATES, this.questions),
                },
              },
            },
            {
              title: `${QuestionCategoryWording.ACCOUNTING} (1/2)`,
              questions: {
                [Question.HAS_ACCOUNTANT]: {
                  ...createQuestionByName(Question.HAS_ACCOUNTANT, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_ACCOUNTANT,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.EXTERNAL_PROVIDER]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.EXTERNAL_PROVIDER,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS]: {
                  ...createQuestionByName(
                    Question.APPROVED_MANAGEMENT_ORGANISM_ADDRESS,
                    this.questions,
                  ),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.APPROVED_MANAGEMENT_ORGANISM_NAME]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_NAME,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_APPROVAL_NUMBER,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_MEMBERSHIP_YEAR,
                  this.questions,
                ),
                [Question.APPROVED_MANAGEMENT_ORGANISM_PHONE]: createQuestionByName(
                  Question.APPROVED_MANAGEMENT_ORGANISM_PHONE,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_ADDRESS]: {
                  ...createQuestionByName(Question.EXTERNAL_PROVIDER_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.EXTERNAL_PROVIDER_NAME]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_NAME,
                  this.questions,
                ),
                [Question.EXTERNAL_PROVIDER_PHONE]: createQuestionByName(
                  Question.EXTERNAL_PROVIDER_PHONE,
                  this.questions,
                ),
                [Question.HAS_FISCAL_COMPLIANCE_REVIEW]: {
                  ...createQuestionByName(Question.HAS_FISCAL_COMPLIANCE_REVIEW, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_FISCAL_COMPLIANCE_REVIEW,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.HAS_AMO_OR_APPOINTED_AUDITOR]: {
                  ...createQuestionByName(Question.HAS_AMO_OR_APPOINTED_AUDITOR, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_AMO_OR_APPOINTED_AUDITOR,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NONE)?.id,
                },
                [Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME]: {
                  ...createQuestionByName(
                    Question.FISCAL_COMPLIANCE_REVIEW_PROVIDER_NAME,
                    this.questions,
                  ),
                },
                [Question.ACCOUNTANT_ADDRESS]: {
                  ...createQuestionByName(Question.ACCOUNTANT_ADDRESS, this.questions),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question.ACCOUNTANT_NAME]: createQuestionByName(
                  Question.ACCOUNTANT_NAME,
                  this.questions,
                ),
                [Question.ACCOUNTANT_PHONE]: createQuestionByName(
                  Question.ACCOUNTANT_PHONE,
                  this.questions,
                ),
              },
            },
            {
              title: `${QuestionCategoryWording.ACCOUNTING} (2/2)`,
              questions: {
                [Question.DATE_OF_TERMINATION]: createQuestionByName(
                  Question.DATE_OF_TERMINATION,
                  this.questions,
                ),
                [Question.TERMINATION_MOTIVE]: {
                  ...createQuestionByName(Question.TERMINATION_MOTIVE, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.TERMINATION_MOTIVE,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NA)?.id,
                },
              },
            },
            {
              title: QuestionCategoryWording["2065_GROUP"],
              questions: {
                [Question.DATE_OF_ENTRY_IN_THE_GROUP]: createQuestionByName(
                  Question.DATE_OF_ENTRY_IN_THE_GROUP,
                  this.questions,
                ),
                [Question["2258-SD_HEAD_OF_GROUP"]]: {
                  ...createQuestionByName(Question["2258-SD_HEAD_OF_GROUP"], this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question["2258-SD_HEAD_OF_GROUP"],
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question["2258-SD_HEAD_OF_GROUP_HAS_DESIGNATED_OTHER_ENTITY"]]: {
                  ...createQuestionByName(
                    Question["2258-SD_HEAD_OF_GROUP_HAS_DESIGNATED_OTHER_ENTITY"],
                    this.questions,
                  ),
                  idAnswerTypeValue: createQuestionByName(
                    Question["2258-SD_HEAD_OF_GROUP_HAS_DESIGNATED_OTHER_ENTITY"],
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question["2258-SD_DESIGNATED_COMPANY"]]: {
                  ...createQuestionByName(Question["2258-SD_DESIGNATED_COMPANY"], this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question["2258-SD_DESIGNATED_COMPANY"],
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_ADDRESS"]]: {
                  ...createQuestionByName(
                    Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_ADDRESS"],
                    this.questions,
                  ),
                  address: cloneDeep(defaultBalanceSheetAddress),
                },
                [Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_NAME"]]: createQuestionByName(
                  Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_NAME"],
                  this.questions,
                ),
                [Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_NUMBER"]]: createQuestionByName(
                  Question["2258-SD_HEAD_OF_GROUP_DESIGNATED_ENTITY_NUMBER"],
                  this.questions,
                ),
                ...(endYear >= 2024
                  ? {
                      [Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ADDRESS]: {
                        ...createQuestionByName(
                          Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ADDRESS,
                          this.questions,
                        ),
                        address: null,
                      },
                      [Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ENTITY_NUMBER]:
                        createQuestionByName(
                          Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ENTITY_NUMBER,
                          this.questions,
                        ),
                      [Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ENTITY_NAME]: createQuestionByName(
                        Question.COUNTRY_TO_COUNTRY_ESTABLISHMENT_ENTITY_NAME,
                        this.questions,
                      ),
                    }
                  : {}),
              },
            },
            {
              title: QuestionCategoryWording["2065_IMPUTATION"],
              questions: {
                [Question.FRENCH_OR_FOREIGN_MOVABLE_INCOME]: {
                  ...createQuestionByName(
                    Question.FRENCH_OR_FOREIGN_MOVABLE_INCOME,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.FOREIGN_FISCAL_CONVENTION_INCOME]: {
                  ...createQuestionByName(
                    Question.FOREIGN_FISCAL_CONVENTION_INCOME,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2065_CAPITAL_LOSS"],
              questions: {
                [Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_0_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_0_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_15_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_15_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_19_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_TO_POSTPONE_AT_19_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_0_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_0_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_15_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_15_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_19_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_ALLOCATED_ON_ADDED_VALUES_AT_19_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_0_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_0_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_15_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_15_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_19_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_UNDER_FISCAL_PERIOD_AT_19_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_0_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_0_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_15_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_15_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_19_RATE]: {
                  ...createQuestionByName(
                    Question.LONG_RUN_CAPITAL_LOSS_LEFT_TO_POSTPONE_AT_19_RATE,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2065_INCOME_DISTRIBUTION"],
              questions: {
                [Question.AFFILIATES_AND_SHAREHOLDERS_INCOMES_PAID_BY_COMPANY]: {
                  ...createQuestionByName(
                    Question.AFFILIATES_AND_SHAREHOLDERS_INCOMES_PAID_BY_COMPANY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.AFFILIATES_AND_SHAREHOLDERS_INCOMES_PAID_BY_SHARE_MANAGEMENT_ESTABLISHMENT]:
                  {
                    ...createQuestionByName(
                      Question.AFFILIATES_AND_SHAREHOLDERS_INCOMES_PAID_BY_SHARE_MANAGEMENT_ESTABLISHMENT,
                      this.questions,
                    ),
                    answer: AnswerTypeValue.NA,
                  },
                [Question.PAYMENTS_OR_ADVANTAGES_TO_UNKNOWN_BENEFICIARY]: {
                  ...createQuestionByName(
                    Question.PAYMENTS_OR_ADVANTAGES_TO_UNKNOWN_BENEFICIARY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.AFFILIATES_OR_SHAREHOLDERS_PREPAYMENTS]: {
                  ...createQuestionByName(
                    Question.AFFILIATES_OR_SHAREHOLDERS_PREPAYMENTS,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OTHER_DISTRIBUTIONS]: createQuestionByName(
                  Question.OTHER_DISTRIBUTIONS,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2065_INCOME_DISTRIBUTION_PART_2"],
              questions: {
                [Question.DEDUCTIBLE_DISTRIBUTED_INCOMES]: createQuestionByName(
                  Question.DEDUCTIBLE_DISTRIBUTED_INCOMES,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2065_PARTNERS_REMUNERATION"],
              questions: {},
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_1"],
              questions: {
                [Question.EXCEPTIONAL_ENDOWMENT_INCREASE_30]: {
                  ...createQuestionByName(
                    Question.EXCEPTIONAL_ENDOWMENT_INCREASE_30,
                    this.questions,
                  ),
                },
                [Question.REGULATED_PROVISIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.REGULATED_PROVISIONS_ENDOWMENT, this.questions),
                },
                [Question.REGULATED_PROVISIONS_RECOVERY]: {
                  ...createQuestionByName(Question.REGULATED_PROVISIONS_RECOVERY, this.questions),
                },
                [Question.COMMERCIAL_FUNDS_ENDOWMENT]: {
                  ...createQuestionByName(Question.COMMERCIAL_FUNDS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.COMMERCIAL_FOUNDS_RECOVERY]: {
                  ...createQuestionByName(Question.COMMERCIAL_FOUNDS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INTANGIBLE_IMMOBILIZATIONS_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.INTANGIBLE_IMMOBILIZATIONS_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INTANGIBLE_IMMOBILIZATIONS_RECOVERY]: {
                  ...createQuestionByName(
                    Question.INTANGIBLE_IMMOBILIZATIONS_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SITE_ENDWOMENT]: {
                  ...createQuestionByName(Question.SITE_ENDWOMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.SITE_RECOVERY]: {
                  ...createQuestionByName(Question.SITE_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.CONSTRUCTIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.CONSTRUCTIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.CONSTRUCTIONS_RECOVERY]: {
                  ...createQuestionByName(Question.CONSTRUCTIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TECHNICAL_INSTALLATIONS_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.TECHNICAL_INSTALLATIONS_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TECHNICAL_INSTALLATIONS_RECOVERY]: {
                  ...createQuestionByName(
                    Question.TECHNICAL_INSTALLATIONS_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.GENERAL_INSTALLATIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.GENERAL_INSTALLATIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.GENERAL_INSTALLATIONS_RECOVERY]: {
                  ...createQuestionByName(Question.GENERAL_INSTALLATIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TRANSPORTATION_EQUIPMENT_ENDOWMENT]: {
                  ...createQuestionByName(
                    Question.TRANSPORTATION_EQUIPMENT_ENDOWMENT,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TRANSPORTATION_EQUIPMENT_RECOVERY]: {
                  ...createQuestionByName(
                    Question.TRANSPORTATION_EQUIPMENT_RECOVERY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OTHER_IMMOBILIZATIONS_ENDOWMENT]: {
                  ...createQuestionByName(Question.OTHER_IMMOBILIZATIONS_ENDOWMENT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.OTHER_IMMOBILIZATIONS_RECOVERY]: {
                  ...createQuestionByName(Question.OTHER_IMMOBILIZATIONS_RECOVERY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_2"],
              questions: {
                [Question["N-1_DEFICIT_REPORTED"]]: createQuestionByName(
                  Question["N-1_DEFICIT_REPORTED"],
                  this.questions,
                ),
                [Question.FULL_RIGHT_DEFICIT_TRANSFERRED]: createQuestionByName(
                  Question.FULL_RIGHT_DEFICIT_TRANSFERRED,
                  this.questions,
                ),
                [Question.FULL_RIGHT_DEFICIT_IMPACTED]: createQuestionByName(
                  Question.FULL_RIGHT_DEFICIT_IMPACTED,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2033_PROVISION_AMORTIZATION_DEFICIT_PART_3"],
              questions: {
                [Question.HANDMADE_ASSIGNED_EMPLOYEES]: createQuestionByName(
                  Question.HANDMADE_ASSIGNED_EMPLOYEES,
                  this.questions,
                ),
                [Question.DISABLED_EMPLOYEES_WORKFORCE]: createQuestionByName(
                  Question.DISABLED_EMPLOYEES_WORKFORCE,
                  this.questions,
                ),
                [Question.APPRENTICE_EMPLOYEES_WORKFORCE]: createQuestionByName(
                  Question.APPRENTICE_EMPLOYEES_WORKFORCE,
                  this.questions,
                ),
                [Question.TOTAL_WORKFORCE]: createQuestionByName(
                  Question.TOTAL_WORKFORCE,
                  this.questions,
                ),
              },
            },
            {
              title: QuestionCategoryWording["2033_ADDED_VALUE"],
              questions: {
                "2033_CVAE_ENTITY_STAFF": createQuestionByName(
                  Question["2033_CVAE_ENTITY_STAFF"],
                  this.questions,
                ),
                "2033_CVAE_MONO_FACILITY": {
                  ...createQuestionByName(Question["2033_CVAE_MONO_FACILITY"], this.questions),
                  idAnswerTypeValue:
                    createQuestionByName(Question["2033_CVAE_MONO_FACILITY"], this.questions)!
                      .answerType?.answerTypeValues?.[1]?.id ?? null,
                },
                [Question.INVOICE_CHARGE_TRANSFER]: createQuestionByName(
                  Question.INVOICE_CHARGE_TRANSFER,
                  this.questions,
                ),
                [Question.REAL_ESTATE_ROYALTY_FEES_RENTS]: {
                  ...createQuestionByName(Question.REAL_ESTATE_ROYALTY_FEES_RENTS, this.questions),
                },
                [Question.DEDUCTIBLE_TAX_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_TAX_ADDED_VALUE, this.questions),
                },
                [Question.DEDUCTIBLE_EXPENSE_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_EXPENSE_ADDED_VALUE, this.questions),
                },
                [Question.DEDUCTIBLE_FRACTION_ADDED_VALUE]: {
                  ...createQuestionByName(Question.DEDUCTIBLE_FRACTION_ADDED_VALUE, this.questions),
                },
                [Question["2033_CFE_CVAE"]]: createQuestionByName(
                  Question["2033_CFE_CVAE"],
                  this.questions,
                ),
                [Question.CA_ECONOMIC_GROUP]: createQuestionByName(
                  Question.CA_ECONOMIC_GROUP,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (1/4)",
              questions: {
                [Question.ORIGINAL_ARTWORK_RESERVE]: createQuestionByName(
                  Question.ORIGINAL_ARTWORK_RESERVE,
                  this.questions,
                ),
                [Question.DEBTS_SUPERIOR_YEAR]: createQuestionByName(
                  Question.DEBTS_SUPERIOR_YEAR,
                  this.questions,
                ),
                [Question.RECEIVABLES_SUPERIOR_YEAR]: createQuestionByName(
                  Question.RECEIVABLES_SUPERIOR_YEAR,
                  this.questions,
                ),
                [Question.EXCEPTIONAL_FEES_INNOVATIVE_COMPANY]: {
                  ...createQuestionByName(
                    Question.EXCEPTIONAL_FEES_INNOVATIVE_COMPANY,
                    this.questions,
                  ),
                  list: [],
                },
                [Question.OPTIONAL_CONTRIBUTIONS_DEDUCTIBLE]: createQuestionByName(
                  Question.OPTIONAL_CONTRIBUTIONS_DEDUCTIBLE,
                  this.questions,
                ),
                [Question.OPTIONAL_CONTRIBUTIONS]: createQuestionByName(
                  Question.OPTIONAL_CONTRIBUTIONS,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (2/4)",
              questions: {
                [Question.MANDATORY_PERSO_CONTRIBUTION_DEDUCTIBLE]: createQuestionByName(
                  Question.MANDATORY_PERSO_CONTRIBUTION_DEDUCTIBLE,
                  this.questions,
                ),
                [Question.MANDATORY_PERSO_CONTRIBUTION]: createQuestionByName(
                  Question.MANDATORY_PERSO_CONTRIBUTION,
                  this.questions,
                ),
                [Question.PERSONAL_TAKING_AMOUNT]: createQuestionByName(
                  Question.PERSONAL_TAKING_AMOUNT,
                  this.questions,
                ),
                [Question.TAX_REDUCTION_AID]: createQuestionByName(
                  Question.TAX_REDUCTION_AID,
                  this.questions,
                ),
                [Question.INVESTMENT_AMOUNT_RECEIVED]: createQuestionByName(
                  Question.INVESTMENT_AMOUNT_RECEIVED,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (3/4)",
              questions: {
                [Question.RENTAL_INCOME_DEPOSIT_AMOUNT]: createQuestionByName(
                  Question.RENTAL_INCOME_DEPOSIT_AMOUNT,
                  this.questions,
                ),
                [Question.RENTAL_INCOME_CONTRIBUTION]: createQuestionByName(
                  Question.RENTAL_INCOME_CONTRIBUTION,
                  this.questions,
                ),
                [Question.DONATION_NUMBER]: createQuestionByName(
                  Question.DONATION_NUMBER,
                  this.questions,
                ),
                [Question.DONATION_AMOUNT]: createQuestionByName(
                  Question.DONATION_AMOUNT,
                  this.questions,
                ),
                [Question.LEASE_MANAGEMENT_NAME]: createQuestionByName(
                  Question.LEASE_MANAGEMENT_NAME,
                  this.questions,
                ),
                [Question.LEASE_MANAGEMENT_ADDRESS]: createQuestionByName(
                  Question.LEASE_MANAGEMENT_ADDRESS,
                  this.questions,
                ),
              },
            },
            {
              title: "Informations complémentaires (4/4)",
              questions: {
                [Question.TOTAL_AMOUNT_OF_GROSS_WAGES]: createQuestionByName(
                  Question.TOTAL_AMOUNT_OF_GROSS_WAGES,
                  this.questions,
                ),
                [Question.RETROCESSION_FEE_INCOMES]: createQuestionByName(
                  Question.RETROCESSION_FEE_INCOMES,
                  this.questions,
                ),
                [Question.EXEMPTED_ADDED_VALUES_RELATED_TO_15_RATE]: createQuestionByName(
                  Question.EXEMPTED_ADDED_VALUES_RELATED_TO_15_RATE,
                  this.questions,
                ),
                [Question.IS_SME]: {
                  ...createQuestionByName(Question.IS_SME, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.IS_SME,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.HAS_TONNAGE_OPTION]: {
                  ...createQuestionByName(Question.HAS_TONNAGE_OPTION, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.HAS_TONNAGE_OPTION,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
              },
            },
          ],
        };
      } else if (key === "companyTaxes") {
        this.companyTaxes = {
          step: 1,
          steps: [
            {
              id: 1,
              mainStep: 1,
              mainTitle: "Début",
              title: "",
              questions: {},
            },
            {
              id: 2,
              mainStep: 1,
              title: "",
              questions: {
                [Question.CAPITAL_FULLY_REPAID]: {
                  ...createQuestionByName(Question.CAPITAL_FULLY_REPAID, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.CAPITAL_FULLY_REPAID,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.YES)?.id,
                },
                [Question.CAPITAL_FULLY_PAID_UP]: {
                  ...createQuestionByName(Question.CAPITAL_FULLY_PAID_UP, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.CAPITAL_FULLY_PAID_UP,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.YES)?.id,
                },
              },
            },
            {
              id: 3,
              mainStep: 2,
              mainTitle: "Réintégrations",
              title: "Réintégrations extra-comptables (1/4)",
              questions: {
                [Question.NON_DEDUCTIBLE_PERSONAL_PAY_AND_BENEFITS]: {
                  ...createQuestionByName(
                    Question.NON_DEDUCTIBLE_PERSONAL_PAY_AND_BENEFITS,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.NON_DEDUCTIBLE_DEPRECIATIONS]: {
                  ...createQuestionByName(Question.NON_DEDUCTIBLE_DEPRECIATIONS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.NON_DEDUCTIBLE_PROVISION]: {
                  ...createQuestionByName(Question.NON_DEDUCTIBLE_PROVISION, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.NON_DEDUCTIBLE_TAXES]: {
                  ...createQuestionByName(Question.NON_DEDUCTIBLE_TAXES, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TONNAGE_TAX_EXPENSES_FOR_SEA_TRANSPORT_COMPANY]: {
                  ...createQuestionByName(
                    Question.TONNAGE_TAX_EXPENSES_FOR_SEA_TRANSPORT_COMPANY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TONNAGE_TAX_RESULT_FOR_SEA_TRANSPORT_COMPANY]: {
                  ...createQuestionByName(
                    Question.TONNAGE_TAX_RESULT_FOR_SEA_TRANSPORT_COMPANY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 4,
              mainStep: 2,
              title: "Réintégrations extra-comptables (2/4)",
              questions: {
                [Question["2033_LONG_RUN_NET_CAPITAL_LOSS_AT_15_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_NET_CAPITAL_LOSS_AT_15_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_LONG_RUN_NET_CAPITAL_LOSS_AT_0_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_NET_CAPITAL_LOSS_AT_0_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_SHORT_RUN_TAXABLE_GAIN"]]: {
                  ...createQuestionByName(Question["2033_SHORT_RUN_TAXABLE_GAIN"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_MERGE_REGIME_TAXABLE_GAIN"]]: {
                  ...createQuestionByName(
                    Question["2033_MERGE_REGIME_TAXABLE_GAIN"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 5,
              mainStep: 2,
              title: "Réintégrations extra-comptables (3/4)",
              questions: {
                [Question.REAL_ESTATE_LEASING_AND_FUNDRAISING]: {
                  ...createQuestionByName(
                    Question.REAL_ESTATE_LEASING_AND_FUNDRAISING,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.REAL_ESTATE_EXEMPT_RENTS]: {
                  ...createQuestionByName(Question.REAL_ESTATE_EXEMPT_RENTS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.EXCESS_INTERESTS_OF_PARTNER_CURRENT_ACCOUNTS]: {
                  ...createQuestionByName(
                    Question.EXCESS_INTERESTS_OF_PARTNER_CURRENT_ACCOUNTS,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.DIFFERENCE_IN_NET_ASSET_VALUE_OF_MUTUAL_FUNDS]: {
                  ...createQuestionByName(
                    Question.DIFFERENCE_IN_NET_ASSET_VALUE_OF_MUTUAL_FUNDS,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 6,
              mainStep: 2,
              title: "Réintégrations extra-comptables (4/4)",
              questions: {
                [Question.OTHER_ADDITION_LABEL_1]: createQuestionByName(
                  Question.OTHER_ADDITION_LABEL_1,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_LABEL_2]: createQuestionByName(
                  Question.OTHER_ADDITION_LABEL_2,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_LABEL_3]: createQuestionByName(
                  Question.OTHER_ADDITION_LABEL_3,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_LABEL_4]: createQuestionByName(
                  Question.OTHER_ADDITION_LABEL_4,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_LABEL_5]: createQuestionByName(
                  Question.OTHER_ADDITION_LABEL_5,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_AMOUNT_1]: createQuestionByName(
                  Question.OTHER_ADDITION_AMOUNT_1,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_AMOUNT_2]: createQuestionByName(
                  Question.OTHER_ADDITION_AMOUNT_2,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_AMOUNT_3]: createQuestionByName(
                  Question.OTHER_ADDITION_AMOUNT_3,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_AMOUNT_4]: createQuestionByName(
                  Question.OTHER_ADDITION_AMOUNT_4,
                  this.questions,
                ),
                [Question.OTHER_ADDITION_AMOUNT_5]: createQuestionByName(
                  Question.OTHER_ADDITION_AMOUNT_5,
                  this.questions,
                ),
              },
            },
            {
              id: 7,
              mainStep: 3,
              mainTitle: "Déductions",
              title: "Déductions extra-comptables (1/6)",
              questions: {
                [Question.PATRONAGE_FRANCE_TAX_CREDIT]: {
                  ...createQuestionByName(Question.PATRONAGE_FRANCE_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.PATRONAGE_EU_TAX_CREDIT]: {
                  ...createQuestionByName(Question.PATRONAGE_EU_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["N-X_PATRONAGE_TAX_CREDIT"]]: {
                  ...createQuestionByName(Question["N-X_PATRONAGE_TAX_CREDIT"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.RESEARCH_TAX_CREDIT]: {
                  ...createQuestionByName(Question.RESEARCH_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["N-X_RESEARCH_TAX_CREDIT"]]: {
                  ...createQuestionByName(Question["N-X_RESEARCH_TAX_CREDIT"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.REPORT_DEFICIT_TAX_CREDIT]: {
                  ...createQuestionByName(Question.REPORT_DEFICIT_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["N-X_REPORT_DEFICIT_TAX_CREDIT"]]: {
                  ...createQuestionByName(
                    Question["N-X_REPORT_DEFICIT_TAX_CREDIT"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.COURSE_MANAGER_TAX_CREDIT]: {
                  ...createQuestionByName(Question.COURSE_MANAGER_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.FAMILY_TAX_CREDIT]: {
                  ...createQuestionByName(Question.FAMILY_TAX_CREDIT, this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 8,
              mainStep: 3,
              title: "Déductions extra-comptables (2/6)",
              questions: {
                [Question.RELATED_PRODUCTS_OF_SEA_TRANSPORT_COMPANY]: {
                  ...createQuestionByName(
                    Question.RELATED_PRODUCTS_OF_SEA_TRANSPORT_COMPANY,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question.DEBT_OBLIGATION_OF_DEFICI_CARRY_BACK]: {
                  ...createQuestionByName(
                    Question.DEBT_OBLIGATION_OF_DEFICI_CARRY_BACK,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 9,
              mainStep: 3,
              title: "Déductions extra-comptables (3/6)",
              questions: {
                [Question["2033_LONG_RUN_TAXABLE_GAIN_AT_15_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_TAXABLE_GAIN_AT_15_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_LONG_RUN_TAXABLE_GAIN_AT_0_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_TAXABLE_GAIN_AT_0_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_LONG_RUN_TAXABLE_GAIN_AT_19_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_TAXABLE_GAIN_AT_19_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_EXONERATE_GAIN"]]: {
                  ...createQuestionByName(Question["2033_EXONERATE_GAIN"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_LONG_RUN_TAXABLE_GAIN_ON_PREVIOUS_NET_CAPITAL_LOSS"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_TAXABLE_GAIN_ON_PREVIOUS_NET_CAPITAL_LOSS"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_LONG_RUN_TAXATBLE_GAIN_ON_PREVIOUS_DEFICITS"]]: {
                  ...createQuestionByName(
                    Question["2033_LONG_RUN_TAXATBLE_GAIN_ON_PREVIOUS_DEFICITS"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_TRANSFER_TAXABLE_GAIN_AT_10_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_TRANSFER_TAXABLE_GAIN_AT_10_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_OTHER_TAXABLE_GAIN_AT_19_RATE"]]: {
                  ...createQuestionByName(
                    Question["2033_OTHER_TAXABLE_GAIN_AT_19_RATE"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_SHORT_RUN_TAXABLE_GAIN_PROPORTION"]]: {
                  ...createQuestionByName(
                    Question["2033_SHORT_RUN_TAXABLE_GAIN_PROPORTION"],
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_NET_GAIN_ON_SHARES"]]: {
                  ...createQuestionByName(Question["2033_NET_GAIN_ON_SHARES"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_INCLUDED_EXPENSE_SHARE"]]: {
                  ...createQuestionByName(Question["2033_INCLUDED_EXPENSE_SHARE"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["2033_INELIGIBLE_SHARES"]]: {
                  ...createQuestionByName(Question["2033_INELIGIBLE_SHARES"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 10,
              mainStep: 3,
              title: "Déductions extra-comptables (4/6)",
              questions: {
                [Question.START_UPS]: {
                  ...createQuestionByName(Question.START_UPS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.URBAN_FREE_ZONE]: {
                  ...createQuestionByName(Question.URBAN_FREE_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.TAKEOVER_OF_AILING_FIRMS]: {
                  ...createQuestionByName(Question.TAKEOVER_OF_AILING_FIRMS, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.INNOVATIVE_YOUNG_COMPANY]: {
                  ...createQuestionByName(Question.INNOVATIVE_YOUNG_COMPANY, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.DEFENSE_RESTRUCTURING_ZONE]: {
                  ...createQuestionByName(Question.DEFENSE_RESTRUCTURING_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.RURAL_DEVELOPMENT_ZONE]: {
                  ...createQuestionByName(Question.RURAL_DEVELOPMENT_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.EMPLOYMENT_AREA_TO_BE_REVITALIZED]: {
                  ...createQuestionByName(
                    Question.EMPLOYMENT_AREA_TO_BE_REVITALIZED,
                    this.questions,
                  ),
                  answer: AnswerTypeValue.NA,
                },
                ...(endYear < 2024
                  ? {
                      [Question.OVERSEAS_INVESTMENTS]: {
                        ...createQuestionByName(Question.OVERSEAS_INVESTMENTS, this.questions),
                        answer: AnswerTypeValue.NA,
                      },
                    }
                  : {
                      [Question.OVERSEAS_INVESTMENTS_2024]: {
                        ...createQuestionByName(Question.OVERSEAS_INVESTMENTS_2024, this.questions),
                        answer: AnswerTypeValue.NA,
                      },
                    }),
                [Question.NEW_GENERATION_ACTIVITY_ZONE]: {
                  ...createQuestionByName(Question.NEW_GENERATION_ACTIVITY_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.URBAN_ZONE_TO_BE_REVITALIZED]: {
                  ...createQuestionByName(Question.URBAN_ZONE_TO_BE_REVITALIZED, this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question.PRIORITY_DEVELOPMENT_ZONE]: {
                  ...createQuestionByName(Question.PRIORITY_DEVELOPMENT_ZONE, this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 11,
              mainStep: 3,
              title: "Déductions extra-comptables (5/6)",
              questions: {
                [Question["39_DECIES"]]: {
                  ...createQuestionByName(Question["39_DECIES"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_A"]]: {
                  ...createQuestionByName(Question["39_DECIES_A"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_B"]]: {
                  ...createQuestionByName(Question["39_DECIES_B"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_C"]]: {
                  ...createQuestionByName(Question["39_DECIES_C"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_D"]]: {
                  ...createQuestionByName(Question["39_DECIES_D"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_E"]]: {
                  ...createQuestionByName(Question["39_DECIES_E"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_F"]]: {
                  ...createQuestionByName(Question["39_DECIES_F"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
                [Question["39_DECIES_G"]]: {
                  ...createQuestionByName(Question["39_DECIES_G"], this.questions),
                  answer: AnswerTypeValue.NA,
                },
              },
            },
            {
              id: 12,
              mainStep: 3,
              title: "Déductions extra-comptables (6/6)",
              questions: {
                [Question.OTHER_TAX_DEDUCTION_LABEL_1]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_LABEL_1,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_LABEL_2]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_LABEL_2,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_LABEL_3]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_LABEL_3,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_LABEL_4]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_LABEL_4,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_LABEL_5]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_LABEL_5,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_AMOUNT_1]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_AMOUNT_1,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_AMOUNT_2]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_AMOUNT_2,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_AMOUNT_3]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_AMOUNT_3,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_AMOUNT_4]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_AMOUNT_4,
                  this.questions,
                ),
                [Question.OTHER_TAX_DEDUCTION_AMOUNT_5]: createQuestionByName(
                  Question.OTHER_TAX_DEDUCTION_AMOUNT_5,
                  this.questions,
                ),
              },
            },
            {
              id: 13,
              mainStep: 4,
              mainTitle: "Finalisation",
              title: "",
              questions: {
                [Question.DEFERRED_CARRY_BACK_DEFICITS]: {
                  ...createQuestionByName(Question.DEFERRED_CARRY_BACK_DEFICITS, this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question.DEFERRED_CARRY_BACK_DEFICITS,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
                [Question.CARRIED_FORWARD_PREVIOUS_DEFICIT]: {
                  ...createQuestionByName(
                    Question.CARRIED_FORWARD_PREVIOUS_DEFICIT,
                    this.questions,
                  ),
                  idAnswerTypeValue: createQuestionByName(
                    Question.CARRIED_FORWARD_PREVIOUS_DEFICIT,
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
              },
            },
            {
              id: 14,
              mainStep: 4,
              title: "",
              questions: {
                [Question["N-1_PAYMENT_OR_DEPOSIT"]]: {
                  ...createQuestionByName(Question["N-1_PAYMENT_OR_DEPOSIT"], this.questions),
                  idAnswerTypeValue: createQuestionByName(
                    Question["N-1_PAYMENT_OR_DEPOSIT"],
                    this.questions,
                  ).answerType.answerTypeValues?.find((a) => a.value === AnswerTypeValue.NO)?.id,
                },
              },
            },
            {
              id: 15,
              mainStep: 4,
              title: "",
              questions: {},
            },
            {
              id: 16,
              mainStep: 4,
              title: "",
              questions: {},
            },
          ],
        };
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
