import type {
  CreateUserToSocietyWithPermission,
  ReadSocietyUser,
  UpdateUserToSocietyWithPermission} from "@silexpert/core";
import {
  Role
} from "@silexpert/core";

export type Permission = {
  name?: string;
  key?: string;
};

type AccessPageState = {
  users: ReadSocietyUser[];
  permissions: Permission[];
  currentUser: ReadSocietyUser | null;
};

export const useAccessPageStore = defineStore("accessPage", {
  state: () =>
    ref<AccessPageState>({
      users: [],
      currentUser: null,
      permissions: [
        {
          name: "Performances",
          key: "stats",
        },
        {
          name: "Bibliothèque",
          key: "docTreso",
        },
        {
          name: "Paramètres",
          key: "setting",
        },
        {
          name: "Ventes",
          key: "gescom",
        },
        {
          name: "Bilan",
          key: "balanceSheet",
        },
        {
          name: "Banques",
          key: "compta",
        },
        {
          name: "Achats",
          key: "purchase",
        },
        {
          name: "TVA",
          key: "tva",
        },
      ],
    }),

  actions: {
    reset() {
      this.users = [];
      this.currentUser = null;
    },
    async fetchUsers(): Promise<void> {
      const usersStore = useUsersStore();
      const societyId = usersStore.currentSocietyId;

      try {
        if (societyId) {
          const users = await $silex().society.listUserSociety(societyId, { roles: [Role.CLIENT] });
          this.users = users;
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async createUser(payload: { data: CreateUserToSocietyWithPermission }) {
      const usersStore = useUsersStore();
      const societyId = usersStore.currentSocietyId;
      try {
        if (societyId) {
          await $silex().society.createUserToSocietyWithPermission(societyId, payload.data);
          $notifier().open({ type: "success", content: "Accès créé avec succès" });
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async updateUser(payload: { data: UpdateUserToSocietyWithPermission; id: number }) {
      const usersStore = useUsersStore();
      const societyId = usersStore.currentSocietyId;

      try {
        if (societyId) {
          await $silex().society.updateUserToSocietyWithPermission(
            societyId,
            payload.id,
            payload.data,
          );

          $notifier().open({ type: "success", content: "Accès modifié avec succès" });
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async deleteUser(payload: { id: number }) {
      const usersStore = useUsersStore();
      const societyId = usersStore.currentSocietyId;
      try {
        if (societyId) {
          await $silex().society.deleteUser(societyId, payload.id);
          $notifier().open({ type: "success", content: "Accès supprimé avec succès" });
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
