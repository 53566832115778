import { io } from "socket.io-client";

export default defineNuxtPlugin(() => {
  window.$socket = io(apiHost, {
    transports: ["websocket"],
  });
});

declare global {
  interface Window {
    $socket: any;
  }
}
