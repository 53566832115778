import { JSONStringifyObjectValues } from "~/utils/json";

type ClientQueries =
  | ProductsQueryProperties
  | BanksQueryProperties
  | DashboardQueryProperties
  | ThirdPartiesQueryProperties
  | ZTicketsQueryProperties
  | LibraryQueryProperties
  | SalesQueryProperties
  | AuxiliaryLedgerQueryProperties
  | GeneralLedgerQueryProperties
  | BalanceQueryProperties
  | EmployeesQueryProperties
  | VatQueryProperties
  | Das2QueryProperties
  | PaymentQueryProperties
  | CategoryQueryProperties
  | PurchasesQueryProperties
  | CompanyTaxAdvanceQueryProperties
  | BalanceSheetQueryProperties
  | RecoveryQueryProperties;
type AdminQueries =
  | RevisionQueryProperties
  | PreComptaQueryProperties
  | AdminSocietyQueryPropertie
  | PendingPointsQueryProperties
  | RevisionGuideQueryProperties
  | AdminSocietiesQueryProperties
  | BalanceSheetDocumentsQueryProperties
  | SpecifiqueAccountingPlanQueryProperties
  | PersoAccountingPlanQueryProperties
  | SocietyUsersQueryProperties
  | GenericAccountQueryProperties
  | RevisionTPQueryProperties
  | GenericAccountQueryProperties;
type ExpertQueries = ExpertAccountantQueryProperties;

export function useBuildRouteFromQueryPropertiesComposable(
  defaultQueryProperties: ClientQueries | AdminQueries | ExpertQueries,
  queryProperties: ClientQueries | AdminQueries | ExpertQueries,
  route: string,
) {
  // some query properties should not be in the query if their value is identical to the default value
  const filteredQueryProperties = {};

  for (const property in queryProperties) {
    // @ts-expect-error
    if (toRaw(queryProperties[property]) !== toRaw(defaultQueryProperties[property])) {
      // @ts-expect-error
      filteredQueryProperties[property] = queryProperties[property];
    }
  }

  const query = JSONStringifyObjectValues(filteredQueryProperties);
  let queryString = "";
  for (const property in query) {
    const newPart = `${property}=${query[property]}`;
    const partSymbol = queryString === "" ? "?" : "&";
    queryString += `${partSymbol}${newPart}`;
  }

  return `${route}${queryString}`;
}
