import { Role } from "@silexpert/core";

const isAllowedPath = (path: string): boolean => {
  return (
    path.startsWith("/sales") ||
    path.startsWith("/products") ||
    path.startsWith("/billing") ||
    path.startsWith("/tiers") ||
    path.startsWith("/settings") ||
    path.startsWith("/creation") ||
    path.startsWith("/loadingUserAndSociety")
  );
};

export default defineNuxtRouteMiddleware((to) => {
  const roleComposable = useRolesComposable();
  const societyStore = useSocietyStore();
  const prestationStore = usePrestationStore();
  const dayjs = useDayjs();

  const brandComposable = useBrandsComposable();
  if (
    !brandComposable.isClementine() &&
    !brandComposable.isPourcentage() &&
    !brandComposable.isComptaStart()
  )
    return;

  // Solution temporaire : système d'impayé pour les sub brands pourcentage
  // la date de prélèvement est le X du mois, et le 1 du mois on considère actuellement comme impayé
  if (
    brandComposable.isPourcentage() &&
    [5, 7].includes(societyStore.society?.idTypeBrand ?? 0) &&
    dayjs().isBefore("2024-06-30")
  )
    return;

  if (brandComposable.isComptaStart()) {
    // Clients Comptastart : on gère les impayés seulement si une prestation comptable est signée / payée
    if (
      !prestationStore.prestations.find(
        (prestation) =>
          prestation.signed &&
          prestation.paid &&
          prestation?.prestationsList?.isComptable === true &&
          prestation?.prestationsList?.isCatalog === false,
      )
    ) {
      return;
    }
  }

  const userStore = useUsersStore();
  if (isNotDefined(userStore?.user)) return;

  if (isAllowedPath(to.fullPath)) return;
  if (!roleComposable.hasOneOfTheseRoles([Role.CLIENT])) return;

  const periods = societyStore.unpaidPeriod;
  if (!periods.length) return;

  const urlDateString: string[] = [];

  const regex = /(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g;

  ["startDate", "startDate1", "endDate", "endDate1", "startDate2", "endDate2"].forEach(
    (parameter) => {
      const data = to.query?.[parameter];
      if (typeof data === "string" && data.match(regex)?.length) {
        urlDateString.push(data.match(regex)?.[0] as string);
      }
    },
  );

  let isValid = true;
  urlDateString.forEach((urlDate) => {
    if (isValid) {
      const hasUnpaidDate = !!periods.find((p) => {
        const isBetween = dayjs(p.startDate).isBefore(urlDate) && dayjs(p.endDate).isAfter(urlDate);

        return isBetween || p.startDate === urlDate || p.endDate === urlDate;
      });

      if (hasUnpaidDate) {
        isValid = false;
      }
    }
  });

  // Vérification de la période, dans le cas où la période impayée est ENTRE le filtre
  if (isValid) {
    periods.forEach((unpaidPeriod) => {
      const startDate =
        typeof to.query?.startDate === "string"
          ? (to.query?.startDate?.match(regex)?.[0] ?? null)
          : null;

      const endDate =
        typeof to.query?.endDate === "string"
          ? (to.query?.endDate?.match(regex)?.[0] ?? null)
          : null;

      const startDate1 =
        typeof to.query?.startDate1 === "string"
          ? (to.query?.startDate1?.match(regex)?.[0] ?? null)
          : null;
      const endDate1 =
        typeof to.query?.endDate1 === "string"
          ? (to.query?.endDate1?.match(regex)?.[0] ?? null)
          : null;

      const startDate2 =
        typeof to.query?.startDate2 === "string"
          ? (to.query?.startDate2?.match(regex)?.[0] ?? null)
          : null;
      const endDate2 =
        typeof to.query?.endDate2 === "string"
          ? (to.query?.endDate2?.match(regex)?.[0] ?? null)
          : null;

      const isInvalidPeriod =
        startDate && endDate
          ? dayjs(startDate).isBefore(unpaidPeriod.startDate) &&
            dayjs(endDate).isAfter(unpaidPeriod.endDate)
          : false;
      const isInvalidPeriod1 =
        startDate1 && endDate1
          ? dayjs(startDate1).isBefore(unpaidPeriod.startDate) &&
            dayjs(endDate1).isAfter(unpaidPeriod.endDate)
          : false;
      const isInvalidPeriod2 =
        startDate2 && endDate2
          ? dayjs(startDate2).isBefore(unpaidPeriod.startDate) &&
            dayjs(endDate2).isAfter(unpaidPeriod.endDate)
          : false;

      if (isInvalidPeriod || isInvalidPeriod1 || isInvalidPeriod2) {
        isValid = false;
      }
    });
  }

  if (!isValid) {
    const minDate = new Date(Math.min(...periods.map((p) => new Date(p.startDate).getTime())));
    let newRoute = to.fullPath;

    const dateToSet = dayjs(minDate).subtract(1, "day").format("YYYY-MM-DD");

    urlDateString.forEach((urlDate) => {
      newRoute = newRoute.replace(urlDate, dateToSet);
    });

    const re = new RegExp(dateToSet);
    const link: string = newRoute.replace(
      re,
      dayjs(dateToSet).startOf("year").format("YYYY-MM-DD"),
    );
    societyStore.shouldDisplayUnpaidDialog = true;
    return navigateTo(link);
  }
});
