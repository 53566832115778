import type { ReadAccountingIncome, ReadCompanyTax } from "@silexpert/core";

type CompanyTaxState = {
  companyTax: ReadCompanyTax | null;
  accountingIncome: ReadAccountingIncome | null;
  isLoading: boolean;
  isSaving: boolean;
  creatingCompanyTax: ReadCompanyTax | null;
};

export const useCompanyTaxStore = defineStore("companyTax", {
  state: () =>
    ref<CompanyTaxState>({
      companyTax: null,
      isLoading: false,
      accountingIncome: null,
      creatingCompanyTax: null,
      isSaving: false,
    }),
  actions: {
    reset() {
      this.companyTax = null;
      this.isLoading = false;
      this.accountingIncome = null;
    },
    async fetch(idExercice?: number) {
      try {
        const exerciceStore = useExerciceStore();
        this.isLoading = true;
        this.companyTax = await $silex().tax.getCompanyTax(
          idExercice ?? exerciceStore.current!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async create(idExercice?: number) {
      try {
        this.isSaving = true;
        const exerciceStore = useExerciceStore();
        this.creatingCompanyTax = await $silex().tax.createCompanyTax(
          idExercice ?? exerciceStore.current!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isSaving = false;
    },
    async fetchAccountingIncome() {
      try {
        const exerciceStore = useExerciceStore();
        this.accountingIncome = await $silex().accountingIncome.get(exerciceStore.current!.id!);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
});
