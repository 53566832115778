export const JSONStringifyObjectValues = (object: {
  [key: string]: any;
}): { [key: string]: string } => {
  const newObject: { [key: string]: string } = {};

  for (const property in object) {
    newObject[property] = JSON.stringify(object[property]);
  }

  return newObject;
};

export const JSONParseObjectValues = (object: {
  [key: string]: string;
}): { [key: string]: any } => {
  const newObject: { [key: string]: any } = {};

  for (const property in object) {
    newObject[property] = JSON.parse(object[property]);
  }

  return newObject;
};
