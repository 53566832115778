import { getBrand as getBrandConfig, type Brand as BrandBack } from "@silexpert/core";
import type { Brand } from "~/@types/localTypes/utils";

export enum EBrandNames {
  CLEMENTINE = "Clementine",
  COMPTALIB = "Comptalib",
  POURCENTAGE = "Pourcentage",
  COMPTASTART = "Comptastart",
  ANYTIME = "Anytime",
}

export function useBrandsComposable() {
  const { $brand } = useNuxtApp();

  const brand: (() => Brand) | any = $brand;

  function isClementine(): boolean {
    return getBrandName() === EBrandNames.CLEMENTINE;
  }
  function isComptalib(): boolean {
    return getBrandName() === EBrandNames.COMPTALIB;
  }
  function isPourcentage(): boolean {
    return getBrandName() === EBrandNames.POURCENTAGE;
  }

  function isComptaStart(): boolean {
    return getBrandName() === EBrandNames.COMPTASTART;
  }

  function isAnytime(): boolean {
    return getBrandName() === EBrandNames.ANYTIME;
  }

  function getBrandName(): string {
    return checkBrand().name;
  }

  function getBrand() {
    return brand();
  }

  function getStripeKey(): string | null {
    if (["development", "staging"].includes(environment))
      return brand().stripe?.development.key ?? null;
    return brand().stripe?.production.key ?? null;
  }

  function hasOneOfTheseBrands(brands: EBrandNames[]): boolean {
    const brandName = brand().name as EBrandNames;
    return brands.includes(brandName);
  }

  function getClientBrandConfig(idTypeBrand?: number): BrandBack {
    return getBrandConfig({
      idTypeBrand: idTypeBrand ?? 1,
      nodeEnv: getBrandName(),
    });
  }

  return {
    isClementine,
    isComptalib,
    isPourcentage,
    hasOneOfTheseBrands,
    isComptaStart,
    getBrandName,
    getStripeKey,
    getBrand,
    getClientBrandConfig,
    isAnytime,
  };
}
