import type { PrestationType } from "@silexpert/core";

export function usePrestationsComposable() {
  function hasOneOfThesePrestations(prestations: PrestationType[]): boolean {
    const prestaStore = usePrestationStore();
    const hasPrestation = prestaStore?.prestations?.some((presta) =>
      prestations.includes(presta.idPrestationList),
    );
    return hasPrestation;
  }

  function hasOneOfThesePrestationsSignedPaid(prestations: PrestationType[]): boolean {
    const prestaStore = usePrestationStore();
    const hasPrestation = prestaStore?.prestations?.some(
      (presta) => prestations.includes(presta.idPrestationList) && presta.signed && presta.paid,
    );
    return hasPrestation;
  }

  function hasSignedPaidSocialPrestation() {
    const prestaStore = usePrestationStore();
    return prestaStore.socialPrestations.some((prestation) => prestation.signed && prestation.paid);
  }

  function hasSignedPaidAccountingPrestation() {
    const prestaStore = usePrestationStore();
    return prestaStore.prestations.some(
      (prestation) =>
        prestation.signed &&
        prestation.paid &&
        prestation.prestationsList?.isComptable === true &&
        prestation.prestationsList?.isCatalog === false,
    );
  }

  return {
    hasOneOfThesePrestations,
    hasSignedPaidSocialPrestation,
    hasSignedPaidAccountingPrestation,
    hasOneOfThesePrestationsSignedPaid,
  };
}
