import type { IFile, StockEntries } from "@silexpert/core";

type InventoryStockState = {
  isLoading: boolean;
  entries: StockEntries | null;
  files: IFile[];
};

export const useInventoryStockStore = defineStore("inventoryStock", {
  state: () =>
    ref<InventoryStockState>({
      isLoading: false,
      entries: null,
      files: [],
    }),
  actions: {
    reset() {
      this.isLoading = false;
      this.entries = null;
      this.files = [];
    },
    async fetchEntries(idExercice?: number) {
      try {
        this.isLoading = true;
        const exerciceStore = useExerciceStore();
        this.entries = await $silex().stocks.getStocks(idExercice || exerciceStore.current!.id!);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchFiles() {
      try {
        this.isLoading = true;
        this.files = await $silex().stocks.getFiles();
        this.isLoading = false;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
