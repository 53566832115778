/**
 * This generic type represents a type guard function
 *
 * @category TypeGuard
 */
export type TypeGuard<T> = (value: unknown) => value is T;

/**
 * @category TypeGuard
 */
export function is<T>(value: unknown, identity: T): value is T {
  return Object.is(value, identity);
}

/**
 * @category TypeGuard
 */
export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

/**
 * @category TypeGuard
 */
export function isNull(value: unknown): value is null {
  return value === null;
}

/**
 * @category TypeGuard
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

/**
 * @category TypeGuard
 */
export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

/**
 * @category TypeGuard
 */
export function isString(value: unknown): value is string {
  return typeof value === "string";
}

/**
 * @category TypeGuard
 */
export function isEmailString(value: string | null): boolean {
  return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(value ?? "");
}

/**
 * @category TypeGuard
 */
export function isNTvaString(value: string | null): boolean {
  return /^[A-Z]{2}[0-9A-Z]{8,12}$/.test(value ?? "");
}

/**
 * @category TypeGuard
 */
export function isNSiretString(value: string | null): boolean {
  return /^\d+$/.test(value ?? "") && (value ?? "").length === 14;
}

/**
 * @category TypeGuard
 */

export function isObject(value: unknown): value is object {
  return typeof value === "object" && !isNull(value);
}

/**
 * @category TypeGuard
 */
export function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

/**
 * This type represents the not defined possibilities of a MaybeNotDefined value
 *
 * @category MaybeNotDefined
 */
export type NotDefined = null | undefined;

/**
 * The maybe not defined type represent a value which is potentially null or undefined.
 * It should generaly be prefered above optionnal values, since these ones can lead to
 * accidental omissions and errors.
 *
 * @category MaybeNotDefined
 */
export type MaybeNotDefined<T> = T | NotDefined;

/**
 * This helper type give the type corresponding to the defined possibilities of a MaybeNotDefined type
 *
 * @category MaybeNotDefined
 */
export type Defined<T> = Exclude<T, NotDefined>;

/**
 * A type guard to know if a value is not defined
 *
 * @category MaybeNotDefined
 */
export function isNotDefined<T>(maybe: MaybeNotDefined<T>): maybe is NotDefined {
  return isNull(maybe) || isUndefined(maybe);
}

/**
 * A type guard to know if a value is defined.
 *
 * @category MaybeNotDefined
 */
export function isDefined<T>(maybe: MaybeNotDefined<T>): maybe is Defined<T> {
  return !isNotDefined(maybe);
}

/**
 * @category Utility
 */
export function unknown<T>(t: T): unknown {
  return t;
}
