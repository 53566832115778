import type { IAccount, IAccountType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type CategoryQueryProperties = {
  page: number;
  limit: number;
  search: string | undefined;
  descending: boolean;
  accountClass: number | null;
};

type CategoryState = {
  appGenerics: IAccount[]; // Global for app
  types: IAccountType[];
  categories: IAccount[];
  loanCategories: IAccount[];
  immobilizationCategories: IAccount[];
  queryProperties: CategoryQueryProperties;
  chartAccounts: IAccount[];
  isLoading: boolean;
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  hasAnyItem: null;
};

export const defaultCategoryQueryProperties = ref<CategoryQueryProperties>({
  search: undefined,
  page: 1,
  limit: 25,
  accountClass: 1,
  descending: false,
});

export const useCategoryStore = defineStore("category", {
  state: () =>
    ref<CategoryState>({
      types: [],
      categories: [],
      loanCategories: [],
      immobilizationCategories: [],
      appGenerics: [],
      // Gestion des chart accounts (plan comptable)
      queryProperties: cloneDeep(defaultCategoryQueryProperties.value),
      chartAccounts: [],
      isLoading: false,
      maxPages: null,
      currentPage: null,
      totalItems: null,
      hasAnyItem: null,
    }),
  getters: {},
  actions: {
    reset() {
      this.categories = [];
      this.loanCategories = [];
      this.immobilizationCategories = [];
      this.types = [];
      this.queryProperties = cloneDeep(defaultCategoryQueryProperties.value);
      this.chartAccounts = [];
      this.isLoading = false;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
    },
    getMatchingCategory(idAccount: number) {
      return this.categories.find((c) => c.id === idAccount);
    },
    async fetchCategoryTypes() {
      try {
        this.types = await $silex().account.getAccountTypes();
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchImmobilizationCategories() {
      try {
        this.immobilizationCategories = await $silex().account.getImmobilizationAccounts();
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchSocietyCategories(id: number) {
      try {
        const societyStore = useSocietyStore();
        const [categories, resLoansAccounts] = await Promise.all([
          $silex().account.getAll({
            id,
            includeGeneric: true,
            includeDeletedAccount: true,
            onlySpecific: true,
          }),
          $silex().loan.getAllAccounts(societyStore.society!.id!),
        ]);

        this.loanCategories = resLoansAccounts;
        this.categories = categories;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchAppGeneric() {
      try {
        const result = await $silex().account.getGenericAccounts();
        this.appGenerics = result.data;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async fetchChartAccounts(limitBypassed?: number, withoutAccountClass?: boolean) {
      this.isLoading = true;
      try {
        const societyStore = useSocietyStore();
        const { search, page, limit, descending, accountClass } = this.queryProperties;
        const response = await $silex().account.getAccountsSocieties(societyStore.society!.id!, {
          ...(search ? { search } : {}),
          ...(withoutAccountClass ? {} : accountClass ? { accountClass } : {}),
          page,
          limit: limitBypassed || limit,
          descending,
        });
        const { data, maxPages, currentPage, totalItems } = response;
        this.chartAccounts = data;
        this.maxPages = maxPages;
        this.currentPage = currentPage;
        this.totalItems = totalItems;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
