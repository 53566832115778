import { LegalFormTypeEnum, TypeIncomeAllocation, TaxSystem, ProfitsType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import { BalanceSheetModuleSection } from "~/@types/localTypes/balanceSheetModules";

type ModuleState = { lastExerciceFetched: number | null; isOpen: boolean };
type BalanceSheetResume = {
  percentOfTurnover: number;
  turnoverAmount: number;
  accountingResult: number;
  lastExerciceFetched: number | null;
  resumeOpened: boolean;
};
type BalanceSheetModulesState = {
  lastExerciceFetched: number | null;
  moduleState: Record<number, ModuleState>;
  balanceSheetResume: BalanceSheetResume;
};

type BalanceSheetModuleInfos = {
  show: boolean;
  state: "done" | "todo";
};

const initialModuleState: Record<number, ModuleState> = {
  [BalanceSheetModuleSection.IMMOBILIZATION]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.LOAN]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.SALARY_FILE]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.INCOME_ALLOCATION]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.MANUAL_ENTRY]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.DUBIOUS_DEBT]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.SOCIAL_CAPITAL]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.INVENTORY_STOCK]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.VAT_CONTROL]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.MEAL_EXPENSE]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.CUSTOMER_OPERATIONS]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS]: {
    lastExerciceFetched: null,
    isOpen: false,
  },
  [BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS]: {
    lastExerciceFetched: null,
    isOpen: false,
  },
  [BalanceSheetModuleSection.PROVIDER_OPERATIONS]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.COMPANY_TAX]: { lastExerciceFetched: null, isOpen: false },
  [BalanceSheetModuleSection.KILOMETRIC_ALLOWANCE]: {
    lastExerciceFetched: null,
    isOpen: false,
  },
};

export const useBalanceSheetModulesStore = defineStore("balanceSheetModules", {
  state: (): BalanceSheetModulesState => ({
    moduleState: cloneDeep(initialModuleState),
    lastExerciceFetched: null,
    balanceSheetResume: {
      resumeOpened: false,
      percentOfTurnover: 0,
      turnoverAmount: 0,
      accountingResult: 0,
      lastExerciceFetched: null,
    },
  }),
  getters: {
    currentModule: () => {
      const exerciceStore = useExerciceStore();
      const balanceSheetStore = useBalanceSheetStore();
      const exercice = exerciceStore.current!;

      return balanceSheetStore.modules.find((module) => module.idExercice === exercice.id) ?? null;
    },
    // Modules pré-clôture
    immobilizationModule(): BalanceSheetModuleInfos {
      return { show: true, state: "done" };
    },
    loanModule(): BalanceSheetModuleInfos {
      const state = isDefined(this.currentModule?.loanToDeclare) ? "done" : "todo";
      return { show: true, state };
    },
    salaryFileModule(): BalanceSheetModuleInfos {
      const state = isDefined(this.currentModule?.salariesFile) ? "done" : "todo";
      return { show: true, state };
    },
    incomeAllocationModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();
      const incomeAllocationStore = useIncomeAllocationStore();

      const isTypeZero = incomeAllocationStore.typeAndAmount?.type === TypeIncomeAllocation.ZERO;

      const shouldDisplayIncomeAllocationModule = [
        LegalFormTypeEnum.EI,
        LegalFormTypeEnum.EIRL,
        LegalFormTypeEnum.SA,
        LegalFormTypeEnum.SAS,
        LegalFormTypeEnum.SASU,
        LegalFormTypeEnum.SARL,
        LegalFormTypeEnum.SNC,
        LegalFormTypeEnum.SOCIETE_CIVILE,
        LegalFormTypeEnum.EURL,
      ].includes(societyStore.society?.idLegalForm ?? 0);

      const state =
        (isDefined(this.currentModule?.incomeAllocation) &&
          this.currentModule?.incomeAllocation === true) ||
        isTypeZero
          ? "done"
          : "todo";
      return { show: shouldDisplayIncomeAllocationModule, state };
    },
    manualEntryModule(): BalanceSheetModuleInfos {
      const state = isDefined(this.currentModule?.hasManualEntryToDeclare) ? "done" : "todo";
      return { show: true, state };
    },
    dubiousDebtModule(): BalanceSheetModuleInfos {
      const { isClementine } = useBrandsComposable();

      const state = isDefined(this.currentModule?.hasDubiousCustomers) ? "done" : "todo";
      return { show: isClementine(), state };
    },
    // Modules post-clôture
    socialCapitalModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();

      const shouldDisplaySocialCapitalModule = ![
        LegalFormTypeEnum.EI,
        LegalFormTypeEnum.EIRL,
        LegalFormTypeEnum.ASSOCIATION,
      ].includes(societyStore.society?.idLegalForm ?? 0);

      const state = isDefined(this.currentModule?.hasShareCapital) ? "done" : "todo";
      return { show: shouldDisplaySocialCapitalModule, state };
    },
    inventoryStocksModule(): BalanceSheetModuleInfos {
      const state = isDefined(this.currentModule?.inventory) ? "done" : "todo";
      return { show: true, state };
    },
    vatControlModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();

      const state = isDefined(this.currentModule?.hasCheckedVAT) ? "done" : "todo";
      return { show: !societyStore.isNotSubjectToVat, state };
    },
    mealExpenseModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();

      const show = societyStore.society?.idTaxSystem !== TaxSystem.IS;

      const state = isDefined(this.currentModule?.hasMealExpenses) ? "done" : "todo";
      return { show, state };
    },
    customerProviderOperationModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();

      const shouldDisplayCustomerProviderOperations =
        ![LegalFormTypeEnum.EI, LegalFormTypeEnum.EIRL].includes(
          societyStore.society?.idLegalForm ?? 0,
        ) ||
        (LegalFormTypeEnum.EI === societyStore.society?.idLegalForm &&
          societyStore.society?.profitsType === ProfitsType.BIC);

      // state is not relevant here because is calc on each sub module of this module (customerProviderModules)
      return { show: shouldDisplayCustomerProviderOperations, state: "todo" };
    },
    companyTaxModule(): BalanceSheetModuleInfos {
      const societyStore = useSocietyStore();

      const shouldDisplayCompanyTax = societyStore.society?.idTaxSystem === TaxSystem.IS;

      const state = isDefined(this.currentModule?.hasCheckedCompanyTax) ? "done" : "todo";
      return { show: shouldDisplayCompanyTax, state };
    },
    kilometricAllowanceModule(): BalanceSheetModuleInfos {
      const state = isDefined(this.currentModule?.hasValidateKilometricAllowance) ? "done" : "todo";
      return { show: true, state };
    },
  },
  actions: {
    reset() {
      this.moduleState = cloneDeep(initialModuleState);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBalanceSheetModulesStore, import.meta.hot));
}
