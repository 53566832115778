import type { IGeneralLedgerAccount } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type BalanceQueryProperties = {
  page: number;
  limit: number;
  startDate: string | null;
  endDate: string | null;
  search: string | null;
  descending: boolean;
};

type BalanceState = {
  queryProperties: BalanceQueryProperties;
  data: IGeneralLedgerAccount[];
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: null;
};

export const defaultBalanceQueryProperties = ref<BalanceQueryProperties>({
  page: 1,
  limit: 25,
  startDate: null,
  endDate: null,
  search: null,
  descending: false,
});

export const useBalanceStore = defineStore("balance", {
  state: () =>
    ref<BalanceState>({
      queryProperties: cloneDeep(defaultBalanceQueryProperties.value),
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      data: [],
      hasAnyItem: null,
    }),
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultBalanceQueryProperties.value);
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.data = [];
    },
    async fetch(): Promise<void> {
      this.isLoading = true;
      try {
        const dayjs = useDayjs();
        const exerciceStore = useExerciceStore();

        const result = await $silex().generalLedger.getBalanceBySociety({
          dateStart:
            this.queryProperties.startDate ??
            dayjs(exerciceStore.defaultExercice.startDate ?? "1970-01-01").format("YYYY-MM-DD"),
          dateEnd:
            this.queryProperties.endDate ??
            dayjs(exerciceStore.defaultExercice.endDate ?? "1970-01-01").format("YYYY-MM-DD"),
          page: this.queryProperties.page,
          limit: this.queryProperties.limit,
          search: this.queryProperties.search ?? undefined,
          descending: this.queryProperties.descending,
        });

        this.currentPage = result.currentPage;
        this.maxPages = result.maxPages;
        this.totalItems = result.totalItems;
        this.data = result.data;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
