export function $notifier() {
  const notifierStore = useNotifierStore();

  const notifier = {
    /**
     * @param { NotifierParams }  [notifierParams] - A NotifierParams with content, type and duration
     */
    open: (notifierParams: NotifierParams) => {
      if (notifierParams.type !== "success") console.info("👀 Notifier :", notifierParams);
      notifierStore.activeNotifier(notifierParams);
    },
  };

  return notifier;
}
