import type {
  QueryLibrarySociety,
  BasePaginate,
  IGedFile,
  ReadSocietyLibrary} from "@silexpert/core";
import {
  GedCategory,
  GedFileType,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type LibraryQueryProperties = {
  tab:
    | null
    | "Généralités"
    | "Entreprise"
    | "Comptabilité"
    | "Social"
    | "Juridique"
    | "Facturation"
    | "Courriers"
    | "RelevésBancaires";
  search: string | null;
  page: number;
  limit: number;
  // the SP is opened if documentId is defined
  documentId: number | null;
  startDate: string | null;
  endDate: string | null;
};

type LibraryState = {
  queryProperties: LibraryQueryProperties;
  documents: (IGedFile & Loadable)[] | null;
  legalDocuments: IGedFile[];
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: boolean | null;
  controllers: {
    societies: AbortController | null;
    hasAny: AbortController | null;
  };
};

export const defaultLibraryQueryProperties = ref<LibraryQueryProperties>({
  tab: null,
  search: null,
  page: 1,
  limit: 25,
  documentId: null,
  startDate: null,
  endDate: null,
});

export const useLibraryStore = defineStore("library", {
  state: () =>
    ref<LibraryState>({
      queryProperties: cloneDeep(defaultLibraryQueryProperties.value),
      documents: null,
      legalDocuments: [],
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: true,
      hasAnyItem: null,
      controllers: { societies: null, hasAny: null },
    }),
  getters: {
    gedCategoryId: (state) => {
      switch (state.queryProperties.tab) {
        case "Généralités":
          return GedCategory.GENERALITY;
        case "Entreprise":
          return GedCategory.COMPANY;
        case "Comptabilité":
          return GedCategory.ACCOUNTING_AND_TAX;
        case "Social":
          return GedCategory.SOCIAL;
        case "Juridique":
          return GedCategory.JURIDIC;
        case "Facturation":
          return GedCategory.BILLING;
        case "Courriers":
          return GedCategory.MAIL;
        case "RelevésBancaires":
          return GedCategory.BANK_STATEMENT;
        default:
          return -1;
      }
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultLibraryQueryProperties.value);
      this.documents = null;
      this.legalDocuments = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
    },
    async fetchDocuments() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      if (this.gedCategoryId === -1) return;
      this.isLoading = true;

      if (this.controllers.societies && this.controllers.societies?.signal) {
        this.controllers.societies.abort();
      }
      this.controllers.societies = new AbortController();

      const { page, limit, search, startDate, endDate } = this.queryProperties;

      const params: QueryLibrarySociety = {
        idGedCategories: [this.gedCategoryId],
        page,
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        limit,
        search: search ?? undefined,
      };
      let response: ReadSocietyLibrary[] = [];
      await $silex()
        .library.getPaginatedForSociety(idSociety, params, this.controllers.societies.signal)
        .then((res: BasePaginate<ReadSocietyLibrary>) => {
          this.maxPages = res?.maxPages ?? 0;
          this.currentPage = res?.currentPage ?? 0;
          this.totalItems = res?.totalItems ?? 0;
          response = res.data ?? [];
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          this.maxPages = 0;
          this.currentPage = 0;
          this.totalItems = 0;
          response = [];
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });

      const documents = response.map((item) => {
        return {
          ...item,
          isLoading: false,
        };
      });

      this.documents = documents;
      this.isLoading = false;
    },
    async fetchHasAnyItem() {
      this.hasAnyItem = null;

      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      if (this.controllers.hasAny && this.controllers.hasAny?.signal) {
        this.controllers.hasAny.abort();
      }
      this.controllers.hasAny = new AbortController();

      if (this.gedCategoryId === -1) return;

      await $silex()
        .library.getPaginatedForSociety(
          idSociety,
          {
            idGedCategories: [this.gedCategoryId],
            page: 1,
            limit: 1,
          },
          this.controllers.hasAny.signal,
        )
        .then((res: BasePaginate<ReadSocietyLibrary>) => {
          this.hasAnyItem = res?.totalItems > 0;
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          this.hasAnyItem = false;
        });
    },
    async fetchLegalDocuments() {
      const societyStore = useSocietyStore();
      if (!societyStore.society) return;

      const idSociety = societyStore.society.id;

      try {
        this.legalDocuments = await $silex().library.getAll({
          idSociety: idSociety ?? -1,
          idTypeGedFile: [
            GedFileType.KBIS,
            GedFileType.STATUTES,
            GedFileType.IDENTITY_CARD,
            GedFileType.RIB,
          ],
          include: ["fileFromGed"],
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
