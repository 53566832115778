import type { IAccountingTransaction, IDubiousCustomer } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

type DubiousDebtStep = {
  title: string;
};

export type FakeDubiousCustomer = {
  id?: number;
  idThirdParty: number | null;
  debtAmount: number | null;
  idVat: number | null;
  nonRecoveryPercentage: number | null;
  nonRecoveryPercentagePrevious: number | null;
  unRecoverable: boolean;
  transactionPrevious?: IAccountingTransaction;
};

type DubiousDebtState = {
  isLoading: boolean;
  data: IDubiousCustomer[];
  steps: DubiousDebtStep[];
  newDubiousCustomers: FakeDubiousCustomer[];
};

export const defaultNewDubiousCustomer = {
  idThirdParty: null,
  debtAmount: null,
  idVat: null,
  nonRecoveryPercentage: null,
  nonRecoveryPercentagePrevious: null,
  unRecoverable: false,
};

export const useDubiousDebtStore = defineStore("dubiousDebt", {
  state: () =>
    ref<DubiousDebtState>({
      isLoading: false,
      data: [],
      steps: [],
      newDubiousCustomers: [cloneDeep(defaultNewDubiousCustomer)],
    }),
  getters: {
    previousDubiousDebts(state): IDubiousCustomer[] {
      const exerciceStore = useExerciceStore();
      const dayjs = useDayjs();

      const previousExercicesIds = exerciceStore.exercices
        .filter((exercice) => dayjs(exercice.startDate).isBefore(exerciceStore.current?.startDate))
        .map((e) => e.id);
      return state.data.filter((d) => previousExercicesIds.includes(d.idExercice));
    },
    currentExerciceDubiousDebts(state): IDubiousCustomer[] {
      const exerciceStore = useExerciceStore();
      return state.data.filter((d) => exerciceStore.current!.id === d.idExercice);
    },
  },
  actions: {
    reset() {
      this.isLoading = false;
      this.data = [];
      this.newDubiousCustomers = [cloneDeep(defaultNewDubiousCustomer)];
    },
    getErrors(id: number, isNewValue?: boolean) {
      const entity: IDubiousCustomer | FakeDubiousCustomer = isNewValue
        ? this.newDubiousCustomers.find((_, index) => index === id)!
        : this.data.find((d) => d.id === id)!;

      const {
        idThirdParty,
        debtAmount,
        nonRecoveryPercentagePrevious,
        nonRecoveryPercentage,
        idVat,
      } = entity!;

      return {
        idThirdParty: !isDefined(idThirdParty) ? "Le champ est requis" : null,
        debtAmount: isDefined(debtAmount)
          ? debtAmount === 0
            ? "Le montant doit être supérieur a 0"
            : null
          : "Le champ est requis",
        idVat: !isDefined(idVat) ? "Le champ est requis" : null,
        nonRecoveryPercentagePrevious: isDefined(nonRecoveryPercentagePrevious)
          ? nonRecoveryPercentagePrevious > 100
            ? "Le pourcentage ne peut pas dépasser 100 %"
            : nonRecoveryPercentagePrevious < 0
              ? "Le pourcentage ne peut pas être négatif"
              : null
          : null,
        nonRecoveryPercentage: isDefined(nonRecoveryPercentage)
          ? nonRecoveryPercentage > 100
            ? "Le pourcentage ne peut pas dépasser 100 %"
            : nonRecoveryPercentage < 0
              ? "Le pourcentage ne peut pas être négatif"
              : null
          : "Le champ est requis",
      };
    },
    async fetch() {
      try {
        this.isLoading = true;
        const societyStore = useSocietyStore();

        this.data = await $silex().dubiousCustomer.getAll(societyStore.society!.id!);
        this.isLoading = false;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
