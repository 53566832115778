import type {
  CreateSocietyPaymentExpectationCustomer,
  IPaymentExpectationCustomer,
  ReadPaymentExpectationCustomerStat,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type PaymentQueryProperties = {
  search: string | undefined;
  page: number;
  limit: number;
  startDate: string | null;
  endDate: string | null;
  status: "paid" | "refund" | "waiting" | null;
  descending: boolean;
};

export const defaultPaymentQueryProperties = ref<PaymentQueryProperties>({
  page: 1,
  limit: 25,
  search: undefined,
  startDate: null,
  endDate: null,
  status: null,
  descending: true,
});

type PaymentState = {
  isLoading: boolean;
  hasAnyItem: boolean | null;
  currentPage: number | null;
  totalItems: number | null;
  maxPages: number | null;
  data: IPaymentExpectationCustomer[];
  insights: ReadPaymentExpectationCustomerStat | null;
  queryProperties: PaymentQueryProperties;
  controllers: {
    payments: AbortController | null;
    hasAny: AbortController | null;
  };
};

export const usePaymentStore = defineStore("payment", {
  state: () =>
    ref<PaymentState>({
      queryProperties: cloneDeep(defaultPaymentQueryProperties.value),
      isLoading: false,
      hasAnyItem: null,
      data: [],
      currentPage: null,
      totalItems: null,
      maxPages: null,
      insights: null,
      controllers: { payments: null, hasAny: null },
    }),
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultPaymentQueryProperties.value);
      this.isLoading = false;
      this.hasAnyItem = null;
      this.data = [];
      this.currentPage = null;
      this.totalItems = null;
      this.maxPages = null;
      this.controllers = { payments: null, hasAny: null };
      this.insights = null;
    },
    async fetch() {
      const societyStore = useSocietyStore();

      if (this.controllers.payments && this.controllers.payments?.signal) {
        this.controllers.payments.abort();
      }
      this.controllers.payments = new AbortController();
      this.isLoading = true;
      try {
        const { page, limit, startDate, endDate, status, search, descending } =
          this.queryProperties;
        const response = await $silex().paymentExpectationCustomer.getBySociety(
          {
            idSociety: societyStore.society!.id!,
            page,
            limit,
            status,
            dateStart: startDate,
            dateEnd: endDate,
            search,
            descending,
          },
          undefined,
          this.controllers.payments.signal,
        );

        this.maxPages = response.maxPages;
        this.currentPage = response.currentPage;
        this.totalItems = response.totalItems;
        this.data = response.data;
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchHasAnyItem() {
      const societyStore = useSocietyStore();
      const response = await $silex().paymentExpectationCustomer.getBySociety({
        idSociety: societyStore.society!.id!,
        page: 1,
        limit: 1,
      });
      this.hasAnyItem = response.totalItems > 0;
    },
    async fetchInsights() {
      const societyStore = useSocietyStore();
      try {
        this.insights = await $silex().paymentExpectationCustomer.stats({
          idSociety: societyStore.society!.id!,
        });
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async refund(id: number) {
      try {
        const societyStore = useSocietyStore();
        await $silex().paymentExpectationCustomer.refund({
          id,
          idSociety: societyStore.society!.id!,
        });
        await Promise.all([this.fetch(), this.fetchInsights()]);
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async delete(id: number) {
      try {
        const societyStore = useSocietyStore();
        await $silex().paymentExpectationCustomer.delete({
          id,
          idSociety: societyStore.society!.id!,
        });
        await Promise.all([this.fetch(), this.fetchHasAnyItem(), this.fetchInsights()]);
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async create(
      payload: Omit<Omit<CreateSocietyPaymentExpectationCustomer, "paid">, "refund">,
    ): Promise<IPaymentExpectationCustomer | null> {
      try {
        const { idThirdParty, amount, idSociety } = payload;

        return await $silex().paymentExpectationCustomer.create({
          idSociety,
          idThirdParty,
          amount,
          paid: false,
          refund: false,
        });
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
        return null;
      }
    },
  },
});
