<template>
  <div class="error-page-container">
    <img src="~/assets/img/error-oups.png" >
    <div class="ep-text-oups" :class="{ 'uds-comptalib-gradient': isComptalib() }">Oups !</div>
    <div class="ep-text-error">Désolé, la page que vous cherchez est introuvable.</div>
    <div class="ep-text-help">
      Nous vous invitons
      <template v-if="isComptalib()">
        à consulter
        <a href="https://help.comptalib.com/fr/" target="_blank" class="ep-link"
          >le centre d’aide
        </a>
        ou
      </template>
      à contacter <a target="_blank" class="ep-link" @click="openCrisp">le service client</a>.
    </div>
    <div class="buttons-wrapper">
      <uds-main-button
        size="small"
        @click="navigateTo(userStore.user ? userStore.homePage : '/login')"
      >
        Retourner à la page d'accueil
      </uds-main-button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isComptalib } = useBrandsComposable();
const userStore = useUsersStore();

function openCrisp() {
  if (window?.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  }
}
</script>

<style lang="scss" scoped>
.error-page-container {
  width: 100%;
  height: 100%;
  margin-top: 15%;
  display: grid;
  place-items: center;
  gap: $uds-spacing-1;
  .ep-link {
    color: $uds-primary-500;
    cursor: pointer;
  }
  .ep-text-oups {
    font-weight: $uds-weight-500;
    color: $uds-primary-500;
    font-size: 70px;
    line-height: 100px;
  }
  .ep-text-error {
    font-weight: $uds-weight-500;
    color: $uds-primary-700;
    font-size: 24px;
  }
  .ep-text-help {
    color: $uds-neutral-800;
    font-size: 16px;
    line-height: 25px;
  }
}
</style>
