import type { IAccount } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type GenericAccountQueryProperties = {
  page: number | null;
  limit: number | null;
  idVisible: number | null;
};

type GenericAccountState = {
  queryProperties: GenericAccountQueryProperties;
  items: IAccount[];
  usableItems: IAccount[];
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: boolean | null;
};

export const defaultGenericAccountQueryProperties = ref<GenericAccountQueryProperties>({
  page: null,
  limit: null,
  idVisible: null,
});

export const useGenericAccountStore = defineStore("genericAccount", {
  state: () =>
    ref<GenericAccountState>({
      queryProperties: cloneDeep(defaultGenericAccountQueryProperties.value),
      items: [],
      usableItems: [],
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      hasAnyItem: null,
    }),
  getters: {
    genericAccounts(state) {
      const offset = state.queryProperties.limit! * (state.queryProperties.page! - 1);
      if (isArray(state.items) && state.items.length) {
        const items = state.items;
        return items.slice(offset, offset + state.queryProperties.limit!);
      }
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultGenericAccountQueryProperties.value);
      this.items = [];
      this.usableItems = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
    },
    async fetchItems(forceRefresh?: boolean) {
      const categoryStore = useCategoryStore();
      if (this.items.length === 0 || !forceRefresh) {
        await categoryStore.fetchAppGeneric();
      }

      const genericAccounts = categoryStore.appGenerics;
      this.items = genericAccounts;

      switch (this.queryProperties.idVisible) {
        case 1:
          break;
        case 2:
          this.items = this.items.filter((it) => !it.isHidden);
          break;
        case 3:
          this.items = this.items.filter((it) => it.isHidden);
          break;
        default:
          break;
      }

      this.totalItems = this.items.length;
      this.maxPages = Math.ceil(this.items.length / this.queryProperties.limit!);
      this.currentPage = this.queryProperties.page;
    },
  },
});
