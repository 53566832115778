import type {
  IBankAccount,
  ReadFormattedAccountingTransaction,
  ReadBankAccountSum,
  ReadConnectionState,
  IRule,
  IBankList,
  ReadUserConnection} from "@silexpert/core";
import {
  AccountingJournal,
  BankAccountType,
  StateSynchro,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { AnnotationError } from "~/@types/localTypes/transaction";
import type { Loadable } from "~/@types/localTypes/utils";

// Shine ids.
export const ID_BANK_BUDGET_MYCLE_SHINE = 377;
export const ID_BANK_BUDGET_COMPTALIB_SHINE = 467;
export const ID_BANK_BUDGET_LEGALSTART_SHINE = 196;
export const ID_BANK_BUDGET_STAGING_COMPTALIB = 610;
export const ID_BUDGET_CIC = 10;
export const ID_BUDGET_CREDIT_MUT = 1;

export type BanksQueryProperties = {
  tab: "bankStatements" | "cash" | "expenseNotes" | null;
  idBankAccount: number[];
  toAnnotate: boolean;
  transactionId: number | null;
  checkedIds: number[];
  startDate: string | null;
  endDate: string | null;
  search: string | null;
  page: number;
  limit: number;
  orderBy: string;
  descending: boolean;
  withoutReceipt: boolean;
  idAccountType: number | null;
  onlyCredit: boolean;
  onlyDebit: boolean;
  fromAnnotateGL: boolean;
};

type BankState = {
  queryProperties: BanksQueryProperties;
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  isLoading: boolean;
  errors: AnnotationError[];
  bankAccounts: IBankAccount[];
  transactions: (ReadFormattedAccountingTransaction & Loadable)[];
  hasAnyItem: boolean | null;
  cashdeskSum: number;
  totalAmountForPeriod: ReadBankAccountSum;
  connections: ReadConnectionState[] | null;
  rules: IRule[];
  list: IBankList[];
  budgetInsightConnections: ReadUserConnection[];
  pulser: {
    isActive: boolean;
    index: number;
  };
};

export type ConnectedBankAccount = {
  idBankAccount: number;
  synchronized: boolean;
  error: string | null;
};

export const defaultBankQueryProperties = ref<BanksQueryProperties>({
  tab: null,
  idBankAccount: [],
  toAnnotate: false,
  transactionId: null,
  checkedIds: [],
  startDate: null,
  endDate: null,
  search: null,
  page: 1,
  limit: 25,
  orderBy: "dateValue",
  descending: true,
  withoutReceipt: false,
  idAccountType: null,
  onlyCredit: false,
  onlyDebit: false,
  fromAnnotateGL: false,
});

export const useBankStore = defineStore("bank", {
  state: () =>
    ref<BankState>({
      queryProperties: cloneDeep(defaultBankQueryProperties.value),
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      errors: [],
      transactions: [],
      bankAccounts: [],
      hasAnyItem: null,
      cashdeskSum: 0,
      totalAmountForPeriod: { credit: 0, debit: 0 },
      connections: null,
      rules: [],
      list: [],
      budgetInsightConnections: [],
      pulser: {
        isActive: false,
        index: 0,
      },
    }),
  getters: {
    activatedBankAccountsWithBankType: (state) =>
      state.bankAccounts.filter(
        (bankAccount: IBankAccount) =>
          bankAccount.activated && bankAccount.typeBankAccount === BankAccountType.BANK,
      ),
    activatedBankAccounts: (state) =>
      state.bankAccounts.filter((bankAccount: IBankAccount) => bankAccount.activated),
    desactivatedBankAccounts: (state) =>
      state.bankAccounts.filter((bankAccount: IBankAccount) => !bankAccount.activated),
    expiredBankAccounts: (state) =>
      state.bankAccounts.filter(
        (bankAccount: IBankAccount) =>
          bankAccount.synchronizationState === StateSynchro.DESYNCHRONIZED,
      ),
    isBankTab: (state) => state.queryProperties.tab === "bankStatements",
    isCashTab: (state) => state.queryProperties.tab === "cash",
    connectedBankAccounts: (state): ConnectedBankAccount[] => {
      const accounts = [];

      for (const connection of state.connections ?? []) {
        for (const bankAccount of connection.bankAccounts ?? []) {
          if (connection.bankName === "Shine" && typeof bankAccount.id === "number") {
            accounts.push({
              idBankAccount: bankAccount.id,
              synchronized: true,
              error: connection.error ?? bankAccount.error,
            });
          } else if (typeof bankAccount.id === "number") {
            const synchronized =
              !connection.error &&
              !bankAccount.deleted &&
              !bankAccount.disabled &&
              !bankAccount.error;
            accounts.push({
              idBankAccount: bankAccount.id,
              synchronized,
              error: connection.error ?? bankAccount.error,
            });
          }
        }
      }

      return accounts;
    },
    expenseNoteBankAccount: (state): IBankAccount[] => {
      const bankAccounts = state.bankAccounts ?? [];
      const bankAccountsForTheTab = bankAccounts.filter(
        (bA: IBankAccount) => bA.typeBankAccount === BankAccountType.EXPENSE,
      );

      return bankAccountsForTheTab;
    },
  },
  actions: {
    reset() {
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.errors = [];
      this.bankAccounts = [];
      this.queryProperties = cloneDeep(defaultBankQueryProperties.value);
      this.transactions = [];

      this.hasAnyItem = null;
      this.cashdeskSum = 0;
      this.totalAmountForPeriod = { credit: 0, debit: 0 };
      this.connections = null;
      this.rules = [];
      this.budgetInsightConnections = [];
      this.pulser = {
        isActive: false,
        index: 0,
      };
    },
    async updateDefaultBankAccount(value: number | null) {
      try {
        const societyStore = useSocietyStore();

        await $silex().societyConfig.update(societyStore.society!.id!, societyStore.config!.id!, {
          defaultBa: value,
        });
        await societyStore.fetchConfig(societyStore.society!.id!);
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async fetchTransactions(idSociety: number) {
      const {
        tab,
        idBankAccount,
        startDate,
        endDate,
        search,
        idAccountType,
        page,
        limit,
        orderBy,
        toAnnotate,
        withoutReceipt,
        descending,
        onlyCredit,
        onlyDebit,
      } = this.queryProperties;

      this.isLoading = true;
      this.transactions = [];

      const tabBeforeRequest = this.queryProperties.tab;

      try {
        //
        const response = await $silex().transaction.getFormattedPaginate(idSociety, {
          id: idSociety,
          page,
          limit,
          idAccountingJournal: [
            ...(tab === "bankStatements" ? [AccountingJournal.BQ] : []),
            ...(tab === "cash" ? [AccountingJournal.CAI] : []),
            ...(tab === "expenseNotes" ? [AccountingJournal.PER] : []),
          ],
          ...(tab === "bankStatements" ? { idBankAccount } : {}),
          ...(idAccountType ? { idAccountType } : {}),
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
          ...(search ? { search } : {}),
          orderBy,
          toAnnotate,
          withoutReceipt,
          descending,
          ...(onlyCredit ? { onlyCredit } : {}),
          ...(onlyDebit ? { onlyDebit } : {}),
        });

        // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
        // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
        const tabAfterResponse = this.queryProperties.tab;

        if (tabAfterResponse !== tabBeforeRequest) {
          return;
        }

        const { data, maxPages, currentPage, totalItems } = response;

        this.isLoading = false;
        const transactions = data.map((item) => {
          return {
            ...item,
            isLoading: false,
          };
        });
        this.transactions = transactions;
        this.maxPages = maxPages;
        this.currentPage = currentPage;
        this.totalItems = totalItems;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchHasAnyItem(idSociety: number) {
      const { tab } = this.queryProperties;

      this.isLoading = true;
      this.transactions = [];

      const tabBeforeRequest = this.queryProperties.tab;

      await $silex()
        .transaction.getFormattedPaginate(idSociety, {
          id: idSociety,
          page: 1,
          limit: 1,
          idAccountingJournal: [
            ...(tab === "bankStatements" ? [AccountingJournal.BQ] : []),
            ...(tab === "cash" ? [AccountingJournal.CAI] : []),
            ...(tab === "expenseNotes" ? [AccountingJournal.PER] : []),
          ],
        })
        .then((res) => {
          // if the tab has been changed before we have the response, don't use the response, because it can be for the wrong tab!
          // the response for the tab 1 can come after the response for the tab 2, when we go quick to tab 1 then to tab 2
          const tabAfterResponse = this.queryProperties.tab;

          if (tabAfterResponse !== tabBeforeRequest) {
            return;
          }

          const totalItems = res?.totalItems ?? 0;
          const hasAnyItem = totalItems > 0;

          this.hasAnyItem = hasAnyItem;
        })
        .catch((error) => {
          this.hasAnyItem = false;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchBankAccounts(idSociety: number) {
      try {
        this.bankAccounts = await $silex().bankAccount.getAll(idSociety);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchTotalDebitCreditForPeriod() {
      const societyStore = useSocietyStore();
      const {
        startDate,
        endDate,
        idBankAccount,
        withoutReceipt,
        idAccountType,
        toAnnotate,
        onlyCredit,
        onlyDebit,
        tab,
      } = this.queryProperties;

      const id: number[] =
        tab === "cash"
          ? this.bankAccounts
              ?.filter(
                (bA: IBankAccount) => bA.typeBankAccount === BankAccountType.CASHDESK && bA.id,
              )
              .map((bA: IBankAccount) => bA.id!)
          : idBankAccount;

      const idTypeCompta = societyStore.society?.idComptaType;
      if (!idTypeCompta) {
        return;
      }
      const totalAmounts = await $silex().transaction.getBankAccountSum({
        idBankAccount: id,
        endDate: endDate ? new Date(endDate) : undefined,
        startDate: startDate ? new Date(startDate) : undefined,
        idComptaType: idTypeCompta,
        withoutReceipt: withoutReceipt ?? undefined,
        idAccountType: idAccountType ?? undefined,
        toAnnotate: toAnnotate ?? undefined,
        onlyCredit: onlyCredit ?? undefined,
        onlyDebit: onlyDebit ?? undefined,
      });

      this.totalAmountForPeriod = totalAmounts;
    },
    async fetchCashdeskSum() {
      const cashdeskSum = await $silex().transaction.getCashdeskSum();
      this.cashdeskSum = cashdeskSum;
    },
    async fetchConnections(idSociety: number) {
      try {
        const connections = await $silex().connection.getConnectionState({ idSociety });
        this.connections = connections;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async fetchBudgetInsightConnections() {
      try {
        this.budgetInsightConnections = await $silex().budgetInsight.getAllConnections();
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async fetchRules(search?: string | undefined) {
      const rules = await $silex().rule.getAll({
        limit: 200,
        page: 1,
        search,
      });
      this.rules = rules.data;
    },
    async removeTransaction(data: { transactionId: number; idSociety: number }) {
      const unlockedStore = useUnlockedStore();

      await $silex()
        .transaction.delete(data.idSociety, data.transactionId)
        .then(() => {
          $notifier().open({ type: "success", content: "Transaction supprimée avec succès" });
          unlockedStore.fetchBankTransactions(data.idSociety);
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchList() {
      try {
        this.list = await $silex().bankList.getAll();
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
