import type { IUser} from "@silexpert/core";
import { Role } from "@silexpert/core";

export function useRolesComposable() {
  const { data } = useAuth();
  const user = data.value as IUser;

  function hasOneOfTheseRoles(roles: Role[]): boolean {
    return !!user?.roles?.some((role) => roles.includes(role.id!));
  }

  function isAdminOrCustomerSucessUser(): boolean {
    const eRolesToSuccess = [
      Role.ADMIN,
      Role.SUPER_ADMIN,
      Role.CUSTOMER_SUCCESS,
      Role.DATA_OFFICER,
    ];
    return !!user?.roles?.some((role) => eRolesToSuccess.includes(role.id!));
  }

  function isPartnerAccountantUser(): boolean {
    return !!user?.roles?.some((role) => [Role.PARTNERED_ACCOUNTANT].includes(role.id!));
  }

  return { hasOneOfTheseRoles, isAdminOrCustomerSucessUser, isPartnerAccountantUser };
}
