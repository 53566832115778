import type { IUser } from "@silexpert/core";
import { SubscriptionType } from "@silexpert/core";

export enum EPermissions {
  BALANCESHEET = "balanceSheet",
  COMPTA = "compta",
  DOC_TRESO = "docTreso",
  GESCOM = "gescom",
  PARTNER_CURRENT_ACCOUNT = "partnerCurrentAccount",
  PURCHASE = "purchase",
  SERVICE = "services",
  SETTING = "setting",
  SIMULATION = "simulation",
  SIMULATION_MULTIPLE = "simulationMultiple",
  SOCIAL = "social",
  STATS = "stats",
  TVA = "tva",
}

export function usePermissionsComposable() {
  const { data } = useAuth();
  const societyStore = useSocietyStore();

  const society = computed(() => societyStore.society);
  const user = data.value as IUser;

  function hasOneOfThesePermissions(permissions: EPermissions[]): boolean {
    const hasPermission = permissions.some((permission) => {
      // @ts-expect-error
      const r = user?.userPermissions?.find((p) => p.idSociety === society.value?.id)?.permission?.[
        permission
      ];
      return r === true;
    });
    return hasPermission;
  }

  function isFreemium(): boolean {
    const societyStore = useSocietyStore();
    return societyStore.society?.idSubscriptionType === SubscriptionType.FREEMIUM;
  }

  return { hasOneOfThesePermissions, isFreemium };
}
