import type { resources } from "@silexpert/core";
import { Silex } from "@silexpert/core";

type SilexMethods = {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  setToken: (token: string) => {};
  loginWith(): Promise<void>;
} & {
  [K in keyof typeof resources]: InstanceType<(typeof resources)[K]>;
};

export function $silex() {
  const { token } = useAuth();

  const silexInstance = Silex({
    url: apiHost,
    setToken: () => {},
  }) as SilexMethods;

  silexInstance.setToken(token.value ?? "");
  return silexInstance;
}
