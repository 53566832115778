import type {
  ISocietyConfig,
  IVisualizedInformation,
  ISociety,
  ILog,
  BasePaginate,
  IMailSociety,
  IFirstStep,
  IUser,
  UpdateSocietyConfig,
  ReadSocietyUser,
  IBusinessPartner,
  ISocietyDetail,
} from "@silexpert/core";
import { ComptaType, VatRegime, Role } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import { apiErrorToString } from "~/utils/error";
export type SocietyUsersQueryProperties = {
  search: string | null;
};

export const defaultSocietyUsersQueryProperties = ref<SocietyUsersQueryProperties>({
  search: null,
});

type SocietyState = {
  usersQueryProperties: SocietyUsersQueryProperties;
  society: ISociety | null;
  mailSociety: IMailSociety | null;
  visualizedInformations: IVisualizedInformation[];
  config: ISocietyConfig | null;
  detail: ISocietyDetail | null;
  logs: BasePaginate<ILog> | null;
  firstSteps: IFirstStep | null;
  shouldDisplayUnpaidDialog: boolean;
  accountant: IUser | null;
  users: ReadSocietyUser[];
  partners: IBusinessPartner[];
};

export const useSocietyStore = defineStore("society", {
  state: () =>
    ref<SocietyState>({
      usersQueryProperties: cloneDeep(defaultSocietyUsersQueryProperties.value),
      society: null,
      mailSociety: null,
      visualizedInformations: [],
      config: null,
      detail: null,
      logs: null,
      firstSteps: null,
      shouldDisplayUnpaidDialog: false,
      accountant: null,
      users: [],
      partners: [],
    }),
  getters: {
    isCashBased: (state) =>
      [
        ComptaType.CASH_BASED,
        ComptaType.CASH_BASED_ASSISTANCE,
        ComptaType.CASH_BASED_SEIZURE,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      ].includes(state.society?.idComptaType!),
    isNotSubjectToVat: (state) =>
      state.config?.noVat || state.society?.idVatRegime === VatRegime.NOT_ATTRIBUTED,
    isAccruals: (state) => {
      return [
        ComptaType.ACCRUALS,
        ComptaType.ACCRUALS_ASSISTANCE,
        ComptaType.ACCRUALS_SEIZURE,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      ].includes(state.society?.idComptaType!);
    },
    accountancy: (state) => {
      switch (state.society?.idComptaType) {
        case ComptaType.ACCRUALS:
          return "Engagement";
        case ComptaType.ACCRUALS_ASSISTANCE:
          return "Engagement - assistance";
        case ComptaType.ACCRUALS_SEIZURE:
          return "Engagement - saisie";
        case ComptaType.CASH_BASED:
          return "Trésorerie";
        case ComptaType.CASH_BASED_ASSISTANCE:
          return "Trésorerie - assistance";
        case ComptaType.CASH_BASED_SEIZURE:
          return "Trésorerie - saisie";
        default:
          break;
      }
    },
    getOwner: (state) => {
      return state.society?.owner;
    },
    unpaidPeriod: (state) => {
      const dayjs = useDayjs();
      return (
        state.society?.unpaidDetails?.map((u) => ({
          startDate: dayjs(u.dateStart).format("YYYY-MM-DD"),
          endDate: dayjs(u.dateEnd).format("YYYY-MM-DD"),
          amount: u.amount,
          file: u.file,
        })) ?? []
      );
    },
  },
  actions: {
    reset() {
      this.society = null;
      this.visualizedInformations = [];
      this.config = null;
      this.shouldDisplayUnpaidDialog = false;
      this.logs = null;
      this.mailSociety = null;
      this.firstSteps = null;
      this.accountant = null;
      this.users = [];
      this.partners = [];
    },
    lightReset() {
      this.visualizedInformations = [];
      this.shouldDisplayUnpaidDialog = false;
      this.logs = null;
      this.mailSociety = null;
      this.firstSteps = null;
      this.accountant = null;
      this.partners = [];
    },
    async fetchSociety(id?: number) {
      const userStore = useUsersStore();
      try {
        this.society = await $silex().society.get({ id: id ?? userStore.currentSocietyId! });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchAccountant() {
      try {
        const result = await $silex().society.getAccountant();
        // FIX ME (i don't know why, api return empty string instead of null if no dedicated accountant)
        // try to change the return type to application/json but it did not work.
        // try to force response status tà 204 and response to null via res.send but did not work
        // @ts-expect-error
        this.accountant = !isDefined(result) || result.length === 0 ? null : result;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchMailSociety() {
      try {
        const mailSociety = await $silex().society.getInvoiceEmail();
        this.mailSociety = mailSociety as IMailSociety;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchLog() {
      const userStore = useUsersStore();
      try {
        this.logs = await $silex().log.getLastIpsPaginated(userStore.user!.id!);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchConfig(id: number) {
      try {
        this.config = await $silex().societyConfig.get(id);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async updateCurrentSocietyConfig(payload: UpdateSocietyConfig) {
      const societyConfigPayload = {
        defaultBa: undefined,
        ...payload,
      };

      if (this.config?.id) {
        await $silex()
          .societyConfig.update(this.society!.id!, this.config!.id!, societyConfigPayload)
          .catch((error) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
          });
      } else {
        try {
          await $silex().societyConfig.create(this.society!.id!, payload);
        } catch (error) {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        }
      }

      await this.fetchConfig(this.society!.id!);
    },
    async fetchVisualizedInformations(idSociety: number) {
      try {
        this.visualizedInformations = await $silex().visualizedInformation.getAll(idSociety);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchOnboardingFirstStep() {
      try {
        const steps = await $silex().firstStep.get();
        this.firstSteps = steps;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchUsers() {
      try {
        this.users = await $silex().society.listUserSociety(this.society!.id!, {
          roles: [Role.CLIENT],
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchPartners() {
      try {
        this.partners = await $silex().businessPartner.getAllFromSociety(this.society!.id!, {
          include: ["user", "society"],
          attributes: ["sharesHeld", "idQuality", "id"],
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchSocietyDetail() {
      try {
        this.detail = await $silex().societyDetail.get(this.society!.id!, {
          attributes: ["acceptation"],
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
