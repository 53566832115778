import type {
  IAccountingEntry,
  ReadGeneralLedgerSummary,
  ReadIGeneralLedgerThirdPartyPaginated,
  ThirdPartyType,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type AuxiliaryLedgerQueryProperties = {
  page: number;
  limit: number;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  search: string | undefined;
  selectedEntry: number | null;
  selectedEntryThirdParty: number | null;
  checkedEntryIds: number[];
  idThirdParty: number | null;
};

type AuxiliaryLedgerState = {
  queryProperties: AuxiliaryLedgerQueryProperties;
  data: ReadGeneralLedgerSummary[];
  currentPage: number | null;
  maxPages: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: null;
  loadedItems: ReadIGeneralLedgerThirdPartyPaginated | null;
  loadedItemsNoFiltered: ReadIGeneralLedgerThirdPartyPaginated | null;
};

export const defaultAuxiliaryLedgerQueryProperties = ref<AuxiliaryLedgerQueryProperties>({
  search: undefined,
  page: 1,
  limit: 25,
  startDate: undefined,
  endDate: undefined,
  selectedEntry: null,
  selectedEntryThirdParty: null,
  checkedEntryIds: [],
  idThirdParty: null,
});

export const useAuxiliaryLedgerStore = defineStore("auxiliaryLedger", {
  state: () =>
    ref<AuxiliaryLedgerState>({
      queryProperties: cloneDeep(defaultAuxiliaryLedgerQueryProperties.value),
      maxPages: null,
      currentPage: null,
      totalItems: null,
      isLoading: false,
      hasAnyItem: null,
      data: [],
      loadedItems: null,
      loadedItemsNoFiltered: null,
    }),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultAuxiliaryLedgerQueryProperties.value);
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.data = [];
      this.hasAnyItem = null;
      this.loadedItems = null;
      this.loadedItemsNoFiltered = null;
    },
    async fetch(idThirdPartyType: ThirdPartyType): Promise<void> {
      this.isLoading = true;
      try {
        const result = await $silex().generalLedger.getAuxiliarySummaries({
          dateStart: this.queryProperties.startDate ?? "1970-01-01",
          dateEnd: this.queryProperties.endDate ?? "2170-01-01",
          page: this.queryProperties.page,
          limit: this.queryProperties.limit,
          search: this.queryProperties.search || undefined,
          descending: true,
          idThirdPartyType,
          ...(this.queryProperties.idThirdParty
            ? { idThirdParty: this.queryProperties.idThirdParty }
            : {}),
        });

        this.data = result.data;
        this.maxPages = result.maxPages;
        this.currentPage = result.currentPage;
        this.totalItems = result.totalItems;
        if (this.loadedItems?.idThirdParty) {
          this.fetchThirdParty(this.loadedItems.idThirdParty);
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
    async fetchThirdParty(idThirdParty: number, page?: number): Promise<void> {
      try {
        const { startDate, endDate } = this.queryProperties;

        const currentEntriesPage = page ?? 1;
        const societyStore = useSocietyStore();

        const result = await $silex().generalLedger.getThirdParty(idThirdParty, {
          startDate: startDate ?? "1970-01-01",
          endDate: endDate ?? "2170-01-01",
          idSociety: societyStore.society!.id!,
          page: currentEntriesPage,
          limit: 30,
        });

        if (page && page > 1 && this.loadedItems?.idThirdParty === idThirdParty) {
          this.loadedItems = {
            ...this.loadedItems,
            entries: {
              ...result.entries,
              data: [...this.loadedItems.entries.data, ...result.entries.data],
            },
          };
        } else {
          this.loadedItems = result;
        }
      } catch (error) {
        this.loadedItems = null;
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async fetchThirdPartyNoFiltered(idThirdParty: number): Promise<void> {
      try {
        const societyStore = useSocietyStore();

        this.loadedItemsNoFiltered = await $silex().generalLedger.getThirdParty(idThirdParty, {
          startDate: "1970-01-01",
          endDate: "2170-01-01",
          idSociety: societyStore.society!.id!,
          page: 1,
          limit: 10000,
        });
      } catch (error) {
        this.loadedItemsNoFiltered = null;
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async removeItem(payload: { idAccountingTransaction: number; idAccountingEntry: number }) {
      try {
        const { idAccountingTransaction, idAccountingEntry } = payload;
        await $silex()
          .lettering.delete(idAccountingTransaction, idAccountingEntry)
          .then(() => {
            this.setItemEntry({
              idAccountingEntry,
              property: "lettering",
              value: null,
            });
          });
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    async regroupItems(payload: { idEntries: number[] }): Promise<void> {
      try {
        await $silex()
          .lettering.regroup({ idEntries: payload.idEntries })
          .then((res) => {
            const idLettering = res[0].lettering;
            for (const entryId of payload.idEntries) {
              this.setItemEntry({
                idAccountingEntry: entryId,
                property: "lettering",
                value: idLettering,
              });
            }
            $notifier().open({ type: "success", content: "Rapprochement effectué avec succès" });
          });
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
    setItemEntry(payload: {
      idAccountingEntry: number;
      property: keyof IAccountingEntry;
      value: any;
    }) {
      const { idAccountingEntry, property, value } = payload;

      // Main list
      const entryIndexToChange =
        this.loadedItems?.entries?.data?.findIndex((entry) => entry.id === idAccountingEntry) ?? -1;
      if (entryIndexToChange > -1) {
        // @ts-expect-error
        this.loadedItems.entries.data[entryIndexToChange][property] = value;
      }

      // NoFiltersList
      const nfEntryIndexToChange =
        this.loadedItemsNoFiltered?.entries?.data?.findIndex(
          (entry) => entry.id === idAccountingEntry,
        ) ?? -1;
      if (nfEntryIndexToChange > -1) {
        // @ts-expect-error
        this.loadedItemsNoFiltered.entries.data[nfEntryIndexToChange][property] = value;
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
