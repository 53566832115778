import type {
  BasePaginate,
  IPartneredAccountant,
  ReadPartneredAccountantInvoice,
  ReadPartneredAccountantSociety,
  ReadPartneredAccountantSoldService,
  ReadPartneredAccountantSoldServiceStatistics,
  ReadPartneredAccountantSoldServicesPaginated,
} from "@silexpert/core";

export type ExpertAccountantQueryProperties = {
  search: string | null;
  limit: number;
  page: number;
};

export type ExperAccountantState = {
  queryProperties: ExpertAccountantQueryProperties;
  items: ReadPartneredAccountantSoldService[];
  stats: ReadPartneredAccountantSoldServiceStatistics[];
  profile: IPartneredAccountant | null;
  invoices: ReadPartneredAccountantInvoice[];
  activePaginate: BasePaginate<ReadPartneredAccountantSociety> | null;
  pendingPaginate: BasePaginate<ReadPartneredAccountantSociety> | null;
  currentPage: number | null;
  maxPages: number | null;
  totalItems: number | null;
  isLoading: boolean;
  hasAnyItem: boolean | null;
  hasStatBeenFetch: boolean | null;
  hasInvoicesBeenFetch: boolean | null;
};

export const defaultExpertAccountantQueryProperties = ref<ExpertAccountantQueryProperties>({
  search: null,
  page: 1,
  limit: 25,
});

export const useExpertAccountantStore = defineStore("expertAccountant", {
  state: () =>
    ref<ExperAccountantState>({
      queryProperties: structuredClone(toRaw(defaultExpertAccountantQueryProperties.value)),
      items: [],
      stats: [],
      profile: null,
      invoices: [],
      activePaginate: null,
      pendingPaginate: null,
      maxPages: null,
      currentPage: 1,
      totalItems: null,
      isLoading: false,
      hasAnyItem: null,
      hasStatBeenFetch: null,
      hasInvoicesBeenFetch: null,
    }),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = structuredClone(toRaw(defaultExpertAccountantQueryProperties.value));
      this.items = [];
      this.stats = [];
      this.profile = null;
      this.invoices = [];
      this.activePaginate = null;
      this.pendingPaginate = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.hasStatBeenFetch = null;
      this.hasInvoicesBeenFetch = null;
    },
    async fetchAccountantData() {
      try {
        const data: ReadPartneredAccountantSoldServicesPaginated =
          await $silex().partneredAccountant.getPaginatedSoldServices({
            limit: this.queryProperties.limit,
            page: this.queryProperties.page,
          });
        this.totalItems = data.totalItems;
        this.maxPages = data.maxPages;
        this.currentPage = data.currentPage;
        this.hasAnyItem = data.totalItems > 0;
        this.items = data.data;
      } catch (e) {
        $notifier().open({ type: "error", content: apiErrorToString(e) });
      }
    },
    async fetchStats() {
      try {
        this.isLoading = true;
        const stats = await $silex().partneredAccountant.getSoldServiceStatistics();
        this.stats = stats;
        this.hasStatBeenFetch = true;
      } catch (e) {
        $notifier().open({ type: "error", content: apiErrorToString(e) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProfile() {
      try {
        const profile = await $silex().partneredAccountant.getProfile();
        this.profile = profile;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchInvoices() {
      try {
        const invoices = await $silex().partneredAccountant.getAllStripeInvoices();
        this.invoices = invoices;
        this.hasInvoicesBeenFetch = true;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async fetchClients(payload: { active: boolean; limit: number; page: number }) {
      const { active, limit, page } = payload;
      try {
        const client = await $silex().partneredAccountant.getPaginatedSocieties({
          limit,
          page,
          isActive: active,
        });

        if (active) {
          this.activePaginate = client;
        } else {
          this.pendingPaginate = client;
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
});
