import { revisionGuideQuestionsTab } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type RevisionGuideItem = {
  id: number;
  label: string;
  value: number | null;
  comment: string | null;
  isCommentRequired: boolean;
};

export type RevisionGuideTab = {
  label: string;
  id: number;
  items: RevisionGuideItem[];
};

export type RevisionGuideQueryProperties = {
  startDate: string | null;
  endDate: string | null;
  limit: number | null;
  idExercice: number | null;
};

type RevisionGuideState = {
  queryProperties: RevisionGuideQueryProperties;
  revisionGuideList: RevisionGuideTab[];
  hasAnyItem: boolean;
  isLoading: boolean;
};

export const defaultRevisionGuideQueryProperties = ref<RevisionGuideQueryProperties>({
  startDate: null,
  endDate: null,
  idExercice: null,
  limit: null,
});

export const useRevisionGuideStore = defineStore("revisionGuide", {
  state: () =>
    ref<RevisionGuideState>({
      queryProperties: cloneDeep(defaultRevisionGuideQueryProperties.value),
      revisionGuideList: [],
      hasAnyItem: false,
      isLoading: false,
    }),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultRevisionGuideQueryProperties.value);
      this.revisionGuideList = [];
      this.hasAnyItem = false;
      this.isLoading = false;
    },
    async fetchRevisionGuide() {
      try {
        const exerciceStore = useExerciceStore();
        const revisionGuideListEnum = Object.values(revisionGuideQuestionsTab);
        const idExercice = this.queryProperties.idExercice ?? exerciceStore.current?.id;
        const balanceSheet = await $silex().balanceSheet.getByExercice(idExercice!);
        const exerciceRevisionGuideList = balanceSheet
          ? await $silex().revisionGuide.getAll(balanceSheet.id!)
          : [];
        const revisionGuideList: RevisionGuideTab[] = revisionGuideListEnum.map((rgl) => ({
          id: rgl.id,
          label: rgl.title,
          items: rgl.items.map((i) => ({
            id: i.id,
            label: i.label,
            value:
              exerciceRevisionGuideList.find((ergl) => ergl.idQuestion === i.id)?.state ?? null,
            comment:
              exerciceRevisionGuideList.find((ergl) => ergl.idQuestion === i.id)?.comment ?? null,
            isCommentRequired: rgl.forceComment,
          })),
        }));

        this.revisionGuideList = revisionGuideList;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
});
