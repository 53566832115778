import type { ReadKilometricAllowanceByVehicle } from "@silexpert/core";

type KilometricAllowanceState = {
  kilometricAllowances: ReadKilometricAllowanceByVehicle[];
  isLoading: boolean;
};

export const useKilometricAllowanceStore = defineStore("kilometricAllowance", {
  state: () =>
    ref<KilometricAllowanceState>({
      kilometricAllowances: [],
      isLoading: false,
    }),
  actions: {
    reset() {
      this.kilometricAllowances = [];
      this.isLoading = false;
    },
    async fetch(idExercice?: number) {
      try {
        const exerciceStore = useExerciceStore();
        this.isLoading = true;
        this.kilometricAllowances = await $silex().kilometricAllowance.getResume(
          idExercice ?? exerciceStore.current!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
