import {
  ActionButton,
  MainButton,
  MenuButton,
  Loader,
  TagCell,

  //   // INPUTS COMPONENTS
  Amount,
  AmountOrPercent,
  AmountCurrencie,
  Password,
  String,
  //   Search,
  SelectPopper,
  SelectCustom, // Fake select
  SelectAutocompletePopper,
  SelectSuggestion,
  SelectSearch,
  //   Phone,
  //   DateTime,
  TextArea,
  ToggleSwitch,
  Checkbox,
  SimpleCategoryIcon,
  Chip,
  Radio,
  Calendar,
  CalendarTime,
  Time,
  Combobox,

  //   // Others
  SimpleDropdown,
  RadioSquare,
  Toast,
  ActionCard,
  AmountCard,
  Tooltip,
  Banner,
  ExpansionCard,

  // Icons
  MaterialIcon,
  SquircleItem,
  // @ts-expect-error
} from "@silexpert/universal-design-system-3";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("UdsMainButton", MainButton);
  nuxtApp.vueApp.component("UdsActionButton", ActionButton);
  nuxtApp.vueApp.component("UdsMenuButton", MenuButton);

  nuxtApp.vueApp.component("UdsIcon", MaterialIcon);
  nuxtApp.vueApp.component("UdsTag", TagCell);
  nuxtApp.vueApp.component("UdsAmountCurrencie", AmountCurrencie);
  nuxtApp.vueApp.component("UdsSquircleIcon", SquircleItem);
  nuxtApp.vueApp.component("UdsTooltip", Tooltip);
  nuxtApp.vueApp.component("UdsExpansionCard", ExpansionCard);
  nuxtApp.vueApp.component("UdsSimpleDropdown", SimpleDropdown);
  // Inputs
  nuxtApp.vueApp.component("UdsInputString", String);
  nuxtApp.vueApp.component("UdsInputAmount", Amount);
  nuxtApp.vueApp.component("UdsInputAmountOrPercent", AmountOrPercent);
  nuxtApp.vueApp.component("UdsInputPassword", Password);
  nuxtApp.vueApp.component("UdsSimpleCategoryIcon", SimpleCategoryIcon);
  nuxtApp.vueApp.component("UdsChip", Chip);
  nuxtApp.vueApp.component("UdsInputCheckbox", Checkbox);
  nuxtApp.vueApp.component("UdsInputCalendar", Calendar);
  nuxtApp.vueApp.component("UdsInputCalendarTime", CalendarTime);
  nuxtApp.vueApp.component("UdsInputTime", Time);
  nuxtApp.vueApp.component("UdsInputCombobox", Combobox);

  nuxtApp.vueApp.component("UdsInputSelectAutoPopper", SelectAutocompletePopper);
  nuxtApp.vueApp.component("UdsInputSelectPopper", SelectPopper);
  nuxtApp.vueApp.component("UdsInputSelectCustom", SelectCustom);
  nuxtApp.vueApp.component("UdsInputSelectSuggestion", SelectSuggestion);
  nuxtApp.vueApp.component("UdsInputSelectSearch", SelectSearch);

  nuxtApp.vueApp.component("UdsRadioSquare", RadioSquare);
  nuxtApp.vueApp.component("UdsToast", Toast);
  nuxtApp.vueApp.component("UdsActionCard", ActionCard);
  nuxtApp.vueApp.component("UdsAmountCard", AmountCard);
  nuxtApp.vueApp.component("UdsInputTextarea", TextArea);
  nuxtApp.vueApp.component("UdsSwitch", ToggleSwitch);
  nuxtApp.vueApp.component("UdsInputRadio", Radio);
  nuxtApp.vueApp.component("UdsBanner", Banner);
  nuxtApp.vueApp.component("UdsLoader", Loader);
});
