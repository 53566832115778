import type { ReadHydratedImmobilization } from "@silexpert/core";

type ImmobilizationState = {
  immobilizations: ReadHydratedImmobilization[];
};

export const useImmobilizationStore = defineStore("immobilization", {
  state: () =>
    ref<ImmobilizationState>({
      immobilizations: [],
    }),
  actions: {
    reset() {
      this.immobilizations = [];
    },
    async fetchImmobilizations(idSociety: number) {
      try {
        this.immobilizations = await $silex().immobilization.getImmobilizations({
          idSociety,
        });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async deleteImmobilization(id: number) {
      try {
        const immobilization = this.immobilizations.find((i) => i.id === id)!;

        await $silex().immobilization.deleteImmobilization({
          idSociety: immobilization.idSociety,
          id: immobilization.id!,
        });

        await this.fetchImmobilizations(immobilization.idSociety);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
