type NotifierState = {
  isOpen: boolean;
  type: string;
  content: string;
  duration: number;
};

export type NotifierParams = {
  content: string;
  type?: string;
  duration?: number;
};

export const useNotifierStore = defineStore("notifier", {
  state: () =>
    ref<NotifierState>({
      isOpen: false,
      type: "",
      content: "",
      duration: 0,
    }),
  getters: {},
  actions: {
    activeNotifier(notifierParams: NotifierParams) {
      const { content, duration, type } = notifierParams;

      this.isOpen = true;
      this.content = content;
      this.duration = duration ?? 3000;
      this.type = type ?? "error";

      setTimeout(() => {
        this.resetNotifier();
      }, this.duration + 500);
    },
    resetNotifier() {
      this.isOpen = false;
      this.content = "";
      this.duration = 0;
    },
  },
});
