/* We should use the auth module instead of this middleware
 * with "globalAppMiddleware: true"
 */
const nonAuthenticadedRouteNames = [
  "login",
  "forgot",
  "autoLogin",
  "payment",
  "reset-password",
  "forgot-password",
  "user-confirmed",
  "acceptance",
  "appointment",
  "app-auth-login",
  "registration-company",
  "previsional-balance",
  /* "societies-id-payments-payment",
  "balancesheet-action",
  "vat-action",
  "webview-manage",
  "playground", // for faster testing in local (only accessible for admin in prod env, see page file)
  "app-auth-autoLogin",
  "clementineVoice" */
];

export async function isAuthenticated() {
  const auth = useAuth();
  return isDefined(await auth.getSession());
}

export default defineNuxtRouteMiddleware((to) => {
  const isANonAuthenticatedRoute = to
    ? nonAuthenticadedRouteNames.includes(to.name as string)
    : true;

  const auth = useAuth();
  const token = auth.token.value;

  const isAuthenticated = isDefined(token);

  if (!isANonAuthenticatedRoute && !isAuthenticated) {
    const authStore = useAuthentificationStore();
    authStore.logOut();
    return navigateTo("/login");
  }
});
