import type {
  ReadBalanceBankAccount,
  ReadCountDocumentByStatus,
  ReadDeadline,
  ReadPartnerSolde,
  ReadPartnersCurrentAccounts,
  ReadPartnersCurrentAccountsTransaction,
  ReadTax,
  ReadTopBuys,
  ReadTopProviders,
  ReadTurnoverProfitAndLoss,
  ReadVat,
  ReadVatDisbursed,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

export type DashboardQueryProperties = {
  startDate1: string | null;
  endDate1: string | null;
  startDate2: string | null;
  endDate2: string | null;
  idExercice: number | null;
};

export type ImportantDate = {
  type: string;
  ISODate: string;
  label: string;
};

type DashboardState = {
  queryProperties: DashboardQueryProperties;
  oldQueryProperties: DashboardQueryProperties;
  isLoading: boolean;
  isLoadingPartners: boolean;
  isLoadingCurrentAccounts: boolean;
  // don't forget to import the type from the call file
  // used by KeyIndicator
  turnoverProfitAndLoss1: ReadTurnoverProfitAndLoss | null;
  turnoverProfitAndLoss2: ReadTurnoverProfitAndLoss | null;
  // used by Treasury
  treasury1: ReadBalanceBankAccount | null;
  treasury2: ReadBalanceBankAccount | null;
  // used by TopPurchases
  topPurchases1: ReadTopBuys[] | null;
  topPurchases2: ReadTopBuys[] | null;
  // used by TopProviders
  topProviders1: ReadTopProviders[] | null;
  topProviders2: ReadTopProviders[] | null;
  // used by Sales
  sales1: ReadCountDocumentByStatus | null;
  sales2: ReadCountDocumentByStatus | null;
  // used by OngoingVat
  ongoingVat: ReadVat | null;
  lastDispatchOfFetchPeriod1Data: string;
  // used in "Déclaration de TVA" (Clementine)
  vatDisbursed: ReadVatDisbursed | null;
  // used in "Echeances"
  importantDates: ReadDeadline | null; // change type when core's type ReadDeadline is available
  // used in "Montant de l'IS" (Clementine)
  corporateTax: ReadTax | null;

  // used in "Suivi de notes de frais"
  partners: ReadPartnerSolde[] | null;
  selectCurrentPartnerSolde: ReadPartnersCurrentAccounts | null;
  // ideally we should have a store property that works for all pages, but that's not possible as long as not all pages are using 'layout: "connected"'
  width: number;
};

export const defaultDashboardQueryProperties = ref<DashboardQueryProperties>({
  startDate1: null,
  endDate1: null,
  startDate2: null,
  endDate2: null,
  idExercice: null,
});

export const datesType = [
  "vat",
  "is",
  "cfe",
  "tvs",
  "balanceSheetClosing",
  "submittingBalanceSheetTaxPurpose",
  "PVAG",
  "incomeTax",
  "cvae",
];

const defaultState = {
  queryProperties: cloneDeep(defaultDashboardQueryProperties.value),
  oldQueryProperties: cloneDeep(defaultDashboardQueryProperties.value),
  isLoading: true,
  isLoadingPartners: false,
  isLoadingCurrentAccounts: false,
  // treasury
  treasury1: null,
  treasury2: null,
  // turnoverProfit and Loss
  turnoverProfitAndLoss1: null,
  turnoverProfitAndLoss2: null,
  // topPurchases
  topPurchases1: null,
  topPurchases2: null,
  // topProviders
  topProviders1: null,
  topProviders2: null,
  // sales
  sales1: null,
  sales2: null,
  // vat
  ongoingVat: null,
  lastDispatchOfFetchPeriod1Data: "",
  vatDisbursed: null,
  // deadline
  importantDates: null,
  corporateTax: null,
  partners: null,
  selectCurrentPartnerSolde: null,
  width: 1000,
};

export const useDashboardStore = defineStore("dashboard", {
  state: () => ref<DashboardState>(defaultState),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultDashboardQueryProperties.value);
      this.oldQueryProperties = cloneDeep(defaultDashboardQueryProperties.value);
      this.isLoading = true;
      this.isLoadingPartners = false;
      this.isLoadingCurrentAccounts = false;
      this.treasury1 = null;
      this.treasury2 = null;
      this.turnoverProfitAndLoss1 = null;
      this.turnoverProfitAndLoss2 = null;
      this.topPurchases1 = null;
      this.topPurchases2 = null;
      this.topProviders1 = null;
      this.topProviders2 = null;
      this.sales1 = null;
      this.sales2 = null;
      this.ongoingVat = null;
      this.lastDispatchOfFetchPeriod1Data = "";
      this.vatDisbursed = null;
      this.importantDates = null;
      this.corporateTax = null;
      this.partners = null;
      this.selectCurrentPartnerSolde = null;
      this.width = 1000;
    },
    setFilter(transactionsFiltered: ReadPartnersCurrentAccountsTransaction[]) {
      if (this.selectCurrentPartnerSolde?.transactions) {
        this.selectCurrentPartnerSolde.transactions = transactionsFiltered;
      }
    },
    async fetchTreasure1() {
      try {
        this.isLoading = true;

        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const treasury1Response = await $silex().dashboard.getBalanceBankAccount({
            startDate: startDate1,
            endDate: endDate1,
          });

          this.treasury1 = treasury1Response;
        } else {
          this.treasury1 = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTurnoverProfitAndLossAndVats() {
      try {
        this.isLoading = true;

        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const { vats, turnOverProfitAndloss } =
            await $silex().dashboard.getTurnoverProfitAndLossAndVats({
              startDate: startDate1,
              endDate: endDate1,
            });
          this.turnoverProfitAndLoss1 = turnOverProfitAndloss;
          this.ongoingVat = vats;
        } else {
          this.turnoverProfitAndLoss1 = null;
          this.ongoingVat = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTopPurchases1() {
      try {
        this.isLoading = true;

        const { startDate1, endDate1 } = this.queryProperties;
        if (isDefined(startDate1) && isDefined(endDate1)) {
          const topPurchases1 = await $silex().dashboard.getTopBuys({
            startDate: startDate1,
            endDate: endDate1,
          });
          this.topPurchases1 = topPurchases1;
        } else {
          this.topPurchases1 = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTopProviders1() {
      try {
        this.isLoading = true;

        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const topProviders1 = await $silex().dashboard.getTopProviders({
            startDate: startDate1,
            endDate: endDate1,
          });
          this.topProviders1 = topProviders1;
        } else {
          this.topProviders1 = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSales1() {
      try {
        const usersStore = useUsersStore();

        this.isLoading = true;
        const { startDate1, endDate1 } = this.queryProperties;

        const societyId = usersStore.currentSocietyId;
        let sales1: ReadCountDocumentByStatus | null = null;

        if (isDefined(startDate1) && isDefined(endDate1) && societyId) {
          sales1 = await $silex().commercialDocument.getStatistics(societyId, {
            ...(isDefined(startDate1) ? { minDate: startDate1 } : {}),
            ...(isDefined(endDate1) ? { maxDate: endDate1 } : {}),
            isDraft: false,
          });

          this.sales1 = sales1;
        } else {
          this.sales1 = null;
        }
      } catch {
        $notifier().open({
          content: "Une erreur est survenue, veuillez réessayer plus tard.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchImportantDates() {
      try {
        this.isLoading = true;
        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const importantDates = await $silex().dashboard.getDeadlines({
            datesType,
            startDate: startDate1,
            endDate: endDate1,
          });

          this.importantDates = importantDates;
        } else {
          this.importantDates = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchVatDisbursed() {
      try {
        this.isLoading = true;
        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const vatDisbursed = await $silex().dashboard.getVatDisbursed({
            startDate: startDate1,
            endDate: endDate1,
          });
          this.vatDisbursed = vatDisbursed;
        } else {
          this.vatDisbursed = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCorporateTax() {
      try {
        this.isLoading = true;
        let { idExercice } = this.queryProperties;
        if (!idExercice) {
          const exerciceStore = useExerciceStore();
          idExercice = exerciceStore.current?.id as number;
        }

        if (isDefined(idExercice)) {
          const corporateTax = await $silex().tax.getAll(idExercice);

          this.corporateTax = corporateTax;
        } else {
          this.corporateTax = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCurrentAccounts(idPartner: number) {
      try {
        this.isLoadingCurrentAccounts = true;

        const { startDate1, endDate1 } = this.queryProperties;

        if (isDefined(startDate1) && isDefined(endDate1)) {
          const currentAccounts = await $silex().partner.getCurrentAccounts(idPartner, {
            startDate: startDate1,
            endDate: endDate1,
          });
          this.selectCurrentPartnerSolde = currentAccounts;
        } else {
          this.selectCurrentPartnerSolde = null;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoadingCurrentAccounts = false;
      }
    },
    async fetchAssociate() {
      try {
        this.isLoadingPartners = true;

        const { endDate1 } = this.queryProperties;

        if (isDefined(endDate1)) {
          const partners = await $silex().partner.getSolde({
            endDate: endDate1,
            limit: 500,
            page: 1,
          });
          this.partners = partners.data;
        }
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      } finally {
        this.isLoadingPartners = false;
      }
    },
    // fetch data for the second period
    async fetchPeriod2Data() {
      const societyStore = useSocietyStore();
      const { startDate2, endDate2 } = this.queryProperties;

      const fetchTreasury2 = async () => {
        if (isDefined(startDate2) && isDefined(endDate2)) {
          const treasury2 = await $silex().dashboard.getBalanceBankAccount({
            startDate: startDate2,
            endDate: endDate2,
          });

          this.treasury2 = treasury2;
        } else {
          this.treasury2 = null;
        }
      };

      const fetchTurnoverProfitAndLoss2 = async () => {
        if (isDefined(startDate2) && isDefined(endDate2)) {
          const { turnOverProfitAndloss } =
            await $silex().dashboard.getTurnoverProfitAndLossAndVats({
              startDate: startDate2,
              endDate: endDate2,
            });
          this.turnoverProfitAndLoss2 = turnOverProfitAndloss;
        } else {
          this.turnoverProfitAndLoss2 = null;
        }
      };

      const fetchTopPurchases2 = async () => {
        if (isDefined(startDate2) && isDefined(endDate2)) {
          const topPurchases2 = await $silex().dashboard.getTopBuys({
            startDate: startDate2,
            endDate: endDate2,
          });
          this.topPurchases2 = topPurchases2;
        } else {
          this.topPurchases2 = null;
        }
      };

      const fetchSales2 = async () => {
        const usersStore = useUsersStore();
        const societyId = usersStore.currentSocietyId;

        if (isDefined(startDate2) && isDefined(endDate2) && societyId) {
          const stats = await $silex().commercialDocument.getStatistics(societyId, {
            minDate: startDate2,
            maxDate: endDate2,
            isDraft: false,
          });
          this.sales2 = stats;
        } else {
          this.sales2 = null;
        }
      };

      const fetchTopProviders2 = async () => {
        if (isDefined(startDate2) && isDefined(endDate2)) {
          const topProviders2 = await $silex().dashboard.getTopProviders({
            startDate: startDate2,
            endDate: endDate2,
          });
          this.topProviders2 = topProviders2;
        } else {
          this.topProviders2 = null;
        }
      };

      this.isLoading = true;

      const promises: Promise<any>[] = [
        fetchTreasury2(),
        fetchTurnoverProfitAndLoss2(),
        fetchSales2(),
      ];

      if (!societyStore.isAccruals) {
        promises.push(fetchTopPurchases2());
      }
      if (societyStore.isAccruals) {
        promises.push(fetchTopProviders2());
      }

      await Promise.all(promises);

      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
