import { RoleAllowedToLogInAsClient } from "@silexpert/core";
import type { EPermissions } from "#imports";

export default defineNuxtRouteMiddleware((to) => {
  const permissionComposable = usePermissionsComposable();
  const roleComposable = useRolesComposable();

  const userStore = useUsersStore();
  if (isNotDefined(userStore?.user)) return;
  if (roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)) return;

  const freemium = to.meta.freemium as boolean | undefined;
  if (typeof freemium === "boolean" && freemium) {
    if (permissionComposable.isFreemium()) {
      // redirect to freemiumPath
      return navigateTo("/errors/notAllowed");
    }
  }
  const permissions = to.meta.permissions as EPermissions[] | undefined;
  if (typeof permissions?.length === "number" && permissions.length > 0) {
    if (!permissionComposable.hasOneOfThesePermissions(permissions)) {
      return navigateTo("/errors/notAllowed");
    }
  }

  const drawerComposable = useDrawersComposable();
  const drawerIndex = drawerComposable.drawers.findIndex((d) => d.to === to.path);
  if (drawerIndex !== -1) {
    if (!drawerComposable.drawers[drawerIndex].isVisible) {
      return navigateTo("/errors/notAllowed");
    }
  }
  const drawerAdminIndex = drawerComposable.adminDrawers.findIndex((d) => d.to === to.path);
  if (drawerAdminIndex !== -1) {
    if (!drawerComposable.adminDrawers[drawerAdminIndex].isVisible) {
      return navigateTo("/errors/notAllowed");
    }
  }
});
