import type { CreateRule, IRule, ReadMostFrequentOccurence } from "@silexpert/core";
import type { Step } from "~/@types/localTypes/stepper";

export type Stepper = {
  id: number;
  name: string;
  stepName: string;
  steps: Step[];
  // active: boolean;
};

type SettingsState = {
  rulesToCreate: ReadMostFrequentOccurence[];
  rulesAlreadyCreated: IRule[];
  // e-commerce tab
  stepper: Stepper | null;
};

export const useSettingStore = defineStore("settings", {
  state: () =>
    ref<SettingsState>({
      rulesToCreate: [],
      rulesAlreadyCreated: [],
      stepper: null,
    }),
  getters: {},
  actions: {
    reset() {
      this.rulesToCreate = [];
      this.rulesAlreadyCreated = [];
      this.stepper = null;
    },
    async fetchRulesToCreate(search: string | undefined = undefined) {
      try {
        const rulesToCreate = await $silex().rule.getMostFrequent({ search });
        this.rulesToCreate = rulesToCreate ?? null;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async createRule(rule: CreateRule) {
      try {
        const newRule = await $silex().rule.create(rule);
        this.rulesAlreadyCreated = [newRule, ...this.rulesAlreadyCreated];
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async deleteRule(id: number) {
      try {
        await $silex().rule.delete(id);
        $notifier().open({ content: "Annotation supprimée avec succès" });
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
