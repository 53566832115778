export enum EDateFormat {
  YEAR_MONTH_DAY = "YYYY-MM-DD",
  DAY_MONTH_YEAR = "DD-MM-YYYY",

  YEAR_MONTH_DAY_SLASH = "YYYY/MM/DD",
  DAY_MONTH_YEAR_SLASH = "DD/MM/YYYY",
  MONTH_YEAR_SLASH = "MM/YYYY",

  FULL_MONTH = "MMMM",
  FULL_YEAR = "YYYY",
  FULL_DAY = "dddd",

  SHORT_MONTH = "MMM",
  SHORT_YEAR = "YY",
  SHORT_DAY = "ddd",

  DIGIT_MONTH = "MM",
  DIGIT_DAY = "DD",

  HOUR = "HH",
  MINUTE = "mm",
}

export enum EMonthShortFormat {
  FULL_UP = "fullUp",
  FIRST_UP = "firstUp",
}

export function useDateFormat() {
  const dayjs = useDayjs();
  /**
   * @param {string |  Date}  [date] - string | Date param
   * @param {EDateFormat | string}  [formatType] - EDateFormat | string param
   * @returns return a formatted date
   */
  function formatDate(date: Date | string, formatType: EDateFormat | string) {
    return dayjs(date).format(formatType);
  }

  /**
   * @param {string}  [date] - string param
   * @returns return a formatted date
   */
  function formatStringToDate(date: Date) {
    return dayjs(date);
  }

  /**
   * @param {string}  [type] - string param
   * @returns return months short list
   */
  function monthsShort(type?: EMonthShortFormat): string[] {
    if (type === EMonthShortFormat.FULL_UP) {
      return dayjs.monthsShort().map((month: string) => month.toUpperCase());
    }
    if (type === EMonthShortFormat.FIRST_UP) {
      return dayjs.monthsShort().map((month: string) => month[0].toUpperCase() + month.slice(1));
    }
    return dayjs.monthsShort();
  }

  /**
   * @returns return a date now
   */
  function dayNow() {
    return dayjs();
  }
  return { formatDate, monthsShort, dayNow, formatStringToDate };
}
