import type { BasePaginate, IZTicket } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
export type ZTicketsQueryProperties = {
  tab: "ztickets";
  zTicketId: number | null;
  startDate: string | null;
  endDate: string | null;
  search: string | undefined;
  page: number;
  limit: number;
  descending: boolean;
};

export const defaultZTicketsQueryProperties = ref<ZTicketsQueryProperties>({
  tab: "ztickets",
  zTicketId: null,
  startDate: null,
  endDate: null,
  search: undefined,
  page: 1,
  limit: 25,
  descending: true,
});

export const useZTicketsStore = defineStore("zTickets", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultZTicketsQueryProperties.value) as ZTicketsQueryProperties,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      zTickets: null as (IZTicket & Loadable)[] | null,
      isLoading: true as boolean,
      hasAnyItem: null as boolean | null,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultZTicketsQueryProperties.value);
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.zTickets = null;
      this.isLoading = false;
      this.hasAnyItem = null;
    },
    async fetchZTickets() {
      this.isLoading = true;

      const { startDate, endDate, search, page, limit } = this.queryProperties;
      let response: any = null;

      await $silex()
        .zTicket.getZTicket(
          { page, limit, search, startDate: startDate ?? undefined, endDate: endDate ?? undefined },
          // cancelToken,
        )
        .then((res: BasePaginate<IZTicket> | Error) => {
          response = res as BasePaginate<IZTicket>;
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      this.maxPages = response?.maxPages ?? 0;
      this.currentPage = response?.currentPage ?? 0;
      this.totalItems = response?.totalItems ?? 0;
      const data: IZTicket[] = response?.data ?? [];

      const zTickets = data.map((zt) => {
        return {
          ...zt,
          isLoading: false,
        };
      });

      this.zTickets = zTickets;
      this.isLoading = false;
    },

    async fetchHasAnyItem() {
      this.hasAnyItem = null;

      const response = await $silex().zTicket.getZTicket({ page: 1, limit: 1 });

      const { totalItems } = response as BasePaginate<IZTicket>;
      const hasAnyItem = totalItems > 0;

      this.hasAnyItem = hasAnyItem;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
