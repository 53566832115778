import type {
  BasePaginate,
  IFile,
  IRegistrationDetail,
  // rename for camelCase error
  PayloadCerfa3310_2023 as PayloadCerfa33102023,
  PayloadCerfa3514_2022 as PayloadCerfa35142022,
  PayloadCerfa3517_2023 as PayloadCerfa35172023,
  ReadRegistration,
  IAccountingEntry,
  CerfaFormValue,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";
const dayjs = useDayjs();

export type VatCreationData = {
  stepOne: {
    accEntries: IAccountingEntry[];
    accountingTransactionIds: number[];
    updatedEntries: {
      old: IAccountingEntry[];
      new: IAccountingEntry[];
    }[];
  };
  stepTwo: {
    accEntries: IAccountingEntry[];
    accountingTransactionIds: number[];
    updatedEntries: {
      old: IAccountingEntry[];
      new: IAccountingEntry[];
    }[];
  };
  stepThree: {
    inputs: CerfaFormValue[];
  };
  stepFour: {
    inputs: {
      key: string;
      value: any;
    }[];
  };
};

export const defaultVatCreationData = (): VatCreationData => {
  return {
    stepOne: {
      accEntries: [],
      accountingTransactionIds: [],
      updatedEntries: [],
    },
    stepTwo: {
      accEntries: [],
      accountingTransactionIds: [],
      updatedEntries: [],
    },
    stepThree: {
      inputs: [],
    },
    stepFour: {
      inputs: [],
    },
  };
};

export type VatDeclaration = {
  startDate: string;
  endDate: string;
  isHolidayDeposit: boolean;
  isNil: boolean;
  idCerfaType: number;
  file?: IFile | undefined;
};

export type VatQueryProperties = {
  // the SP is opened if itemId is defined
  itemId: number | null | undefined;
  // format YYYY-MM-DD
  startDate: string | null;
  endDate: string | null;
  page: number;
  limit: number;
  idExercice: number | null;
};

export const defaultVatQueryProperties = ref<VatQueryProperties>({
  itemId: null,
  startDate: null,
  endDate: null,
  page: 1,
  limit: 25,
  idExercice: null,
});

const defaultDataForm = {
  mode: "read" as "read" | "creation" | "edition" | "details",
  declaration: {
    startDate: "",
    endDate: "",
    isHolidayDeposit: false,
    isNil: false,
    idCerfaType: 0,
    file: undefined,
  } as VatDeclaration,
  parameters: null as PayloadCerfa33102023 | PayloadCerfa35142022 | PayloadCerfa35172023 | null,
  vatData: defaultVatCreationData() as VatCreationData,
};

export const useVatStore = defineStore("vat", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultVatQueryProperties.value) as VatQueryProperties,
      items: null as (ReadRegistration & Loadable)[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      hasAnyItem: null as boolean | null,
      itemDetails: null as IRegistrationDetail | null,
      dataForm: cloneDeep(defaultDataForm),
      controllers: { vats: null, hasItem: null } as {
        vats: AbortController | null;
        hasItem: AbortController | null;
      },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = defaultVatQueryProperties.value;
      this.items = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.hasAnyItem = null;
      this.itemDetails = null;
      this.dataForm = cloneDeep(defaultDataForm);
    },
    async fetchDeclarationItems() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      this.isLoading = true;

      const { startDate, endDate, page, limit } = this.queryProperties;

      if (this.controllers.vats && this.controllers.vats.signal) {
        await this.controllers.vats.abort();
        this.controllers.vats = null;
      }
      this.controllers.vats = new AbortController();

      let response: any = null;
      const startDateStartOfMonth = dayjs(startDate).startOf("month").format("YYYY-MM-DD");
      const endDateEndOfMonth = dayjs(endDate).endOf("month").format("YYYY-MM-DD");

      await $silex()
        .vat.getPaginated(
          idSociety,
          {
            page,
            limit,
            startDate: startDateStartOfMonth ?? undefined,
            endDate: endDateEndOfMonth ?? undefined,
          },
          this.controllers.vats.signal,
        )
        .then((res: BasePaginate<ReadRegistration>) => (response = res))
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      this.isLoading = false;

      const data = response?.data ?? [];

      this.items = data.map((item: ReadRegistration) => {
        return {
          ...item,
          isLoading: false,
        };
      });

      this.maxPages = response?.maxPages ?? 0;

      this.currentPage = response?.currentPage ?? 0;

      this.totalItems = response?.totalItems ?? 0;
    },
    async fetchHasAnyItem() {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      this.hasAnyItem = null;

      if (this.controllers.hasItem && this.controllers.hasItem.signal)
        this.controllers.hasItem.abort();
      this.controllers.hasItem = new AbortController();

      let response: any = null;

      await $silex()
        .vat.getPaginated(
          idSociety,
          {
            page: 1,
            limit: 1,
          },
          this.controllers.hasItem.signal,
        )
        .then((res: BasePaginate<ReadRegistration>) => (response = res));

      const totalItems = response?.totalItems ?? 0;

      this.hasAnyItem = totalItems > 0;
    },
    async fetchDetailsItem(idRegistration: number) {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;
      this.itemDetails = null;

      let response: IRegistrationDetail | null = null;

      if (isDefined(idSociety)) {
        await $silex()
          .registration.getDetails(idSociety, idRegistration)
          .then((res: IRegistrationDetail | null) => {
            response = res;
          })
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = null;
            throw error;
          });
      }

      this.itemDetails = response ?? null;
    },
    resetCreation() {
      this.dataForm = cloneDeep(defaultDataForm);
    },
    setVatItem(vatItemId: number, vatItem: ReadRegistration & Loadable) {
      const item = this.items ?? [];
      const itemIndexToChange = item.findIndex((a) => a.id === vatItemId);

      if (itemIndexToChange > -1 && isDefined(this.items)) {
        this.items[itemIndexToChange] = vatItem;
      }
    },
    async teledeclareVat(payload: {
      idRegistration: number;
      amountToPay: number;
      hasOga: boolean;
    }) {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      const vatDeclaration = this.items?.find((i) => i.id === payload.idRegistration);

      if (!vatDeclaration) {
        $notifier().open({
          type: "error",
          content: "Aucune déclaration trouvée. Veuillez réessayer plus tard.",
        });
        return;
      }

      // Send
      await $silex()
        // @ts-expect-error
        .vat.declare(idSociety, vatDeclaration.id!, {
          amountToPay: payload.amountToPay,
          withOga: payload.hasOga,
        })
        .then((res: ReadRegistration) => {
          // set declaration
          this.setVatItem(payload.idRegistration, { ...res, isLoading: false });
          $notifier().open({ type: "success", content: "La télédaclaration a été envoyée" });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          throw error;
        });
    },
    async saveVatDeclarationSettings(payload: {
      dateUploadTva2: number;
      siret: string;
      idVatRegime: number;
    }) {
      const societyStore = useSocietyStore();
      const idSociety = societyStore.society!.id!;

      await $silex()
        .society.updateSociety(idSociety, {
          vatUploadDay: payload.dateUploadTva2,
          siret: payload.siret,
          idVatRegime: payload.idVatRegime,
        })
        .then(() => {
          $notifier().open({ type: "success", content: "Configuration enregistrée" });
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
