import type { IAccountingTransaction, TypeCustomerProviderOperation } from "@silexpert/core";
import { BalanceSheetModuleSection } from "~/@types/localTypes/balanceSheetModules";

export type CustomerProviderOperationSection =
  | BalanceSheetModuleSection.CUSTOMER_OPERATIONS
  | BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS
  | BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS
  | BalanceSheetModuleSection.PROVIDER_OPERATIONS;

type CustomerProviderState = {
  transactions: {
    [BalanceSheetModuleSection.CUSTOMER_OPERATIONS]: Record<string, IAccountingTransaction[]>;
    [BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS]: Record<
      string,
      IAccountingTransaction[]
    >;
    [BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS]: Record<
      string,
      IAccountingTransaction[]
    >;
    [BalanceSheetModuleSection.PROVIDER_OPERATIONS]: Record<string, IAccountingTransaction[]>;
  };
};

export const useCustomerProviderStore = defineStore("customerProvider", {
  state: (): CustomerProviderState => {
    return {
      transactions: {
        [BalanceSheetModuleSection.CUSTOMER_OPERATIONS]: {},
        [BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS]: {},
        [BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS]: {},
        [BalanceSheetModuleSection.PROVIDER_OPERATIONS]: {},
      },
    };
  },
  actions: {
    reset() {
      this.transactions = {
        [BalanceSheetModuleSection.CUSTOMER_OPERATIONS]: {},
        [BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS]: {},
        [BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS]: {},
        [BalanceSheetModuleSection.PROVIDER_OPERATIONS]: {},
      };
    },
    async getAll(
      section: CustomerProviderOperationSection,
      idSociety: number,
      types: TypeCustomerProviderOperation[],
      dateValue: Date,
    ) {
      try {
        const dayjs = useDayjs();
        const results: Record<string, IAccountingTransaction[]> = {};

        for await (const type of types) {
          const transactions = await $silex().transaction.getAll(idSociety, {
            typeOperation: type,
            dateValue: dayjs(dateValue).format("YYYY-MM-DD"),
          });
          results[type] = transactions;
        }

        this.transactions[section] = results;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
});
