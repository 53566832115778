import type { ISocietyVehicle } from "@silexpert/core";

type VehicleState = {
  vehicles: ISocietyVehicle[];
  missingPropertiesVehiclesIds: number[];
};

export const useVehicleStore = defineStore("vehicle", {
  state: () =>
    ref<VehicleState>({
      vehicles: [],
      missingPropertiesVehiclesIds: [],
    }),
  actions: {
    reset() {
      this.vehicles = [];
      this.missingPropertiesVehiclesIds = [];
    },
    async fetchSocietyVehicles() {
      const userStore = useUsersStore();
      const idSociety = userStore.currentSocietyId;
      await $silex()
        .vehicle.getAllFromSocietyId(idSociety!)
        .then((res) => (this.vehicles = res))
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
    async fetchIfVehiclesAreFullyFilled() {
      const userStore = useUsersStore();
      await $silex()
        .vehicle.getAllIdsWhithMissingInformation(userStore.currentSocietyId!)
        .then((res) => (this.missingPropertiesVehiclesIds = res))
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
    async removeVehicle(vehicleId: number) {
      const userStore = useUsersStore();
      const idSociety = userStore.currentSocietyId;

      try {
        if (idSociety) {
          await $silex().vehicle.delete(idSociety, vehicleId);
          await this.fetchSocietyVehicles();
        }
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
