import validate from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import common_45query_45global from "/app/middleware/commonQuery.global.ts";
import permission_45global from "/app/middleware/permission.global.ts";
import redirect_45if_45restricted_45global from "/app/middleware/redirectIfRestricted.global.ts";
import role_45global from "/app/middleware/role.global.ts";
import unpaid_45global from "/app/middleware/unpaid.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  common_45query_45global,
  permission_45global,
  redirect_45if_45restricted_45global,
  role_45global,
  unpaid_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_2mmzt5cyzfyp5k3yzg5oahiao4/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}