import type { IAccount } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type SpecifiqueAccountingPlanQueryProperties = {
  limit: number | null;
  page: number | null;
  search: string | null;
};

export const defaultSpecificAccountingPlanQueryProperties =
  ref<SpecifiqueAccountingPlanQueryProperties>({
    search: null,
    page: 1,
    limit: 25,
  });

export type SpecificAccountingPlanState = {
  controllers: { fetch: AbortController | null };
  queryProperties: SpecifiqueAccountingPlanQueryProperties;
  items: IAccount[];
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  hasAnyItem: boolean;
  isLoading: boolean;
  hasBeenAlreadyFetch: boolean;
};

export const useSpecificAccountingPlanStore = defineStore("specificAccountingPlan", {
  state: () =>
    ref<SpecificAccountingPlanState>({
      controllers: { fetch: null },
      queryProperties: cloneDeep(defaultSpecificAccountingPlanQueryProperties.value),
      items: [],
      maxPages: null,
      currentPage: null,
      totalItems: null,
      hasAnyItem: false,
      isLoading: false,
      hasBeenAlreadyFetch: false,
    }),
  getters: {
    specificAccounts(state) {
      const offset = state.queryProperties.limit! * (state.queryProperties.page! - 1);
      if (isArray(state.items) && state.items.length) {
        const items = state.items;
        return items.slice(offset, offset + state.queryProperties.limit!);
      }
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultSpecificAccountingPlanQueryProperties.value);
      this.items = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.hasAnyItem = false;
      this.isLoading = false;
      this.hasBeenAlreadyFetch = false;
      this.controllers = { fetch: null };
    },
    async fetchSpecificAccounts() {
      this.isLoading = true;
      if (this.controllers.fetch && this.controllers.fetch.signal) {
        await this.controllers.fetch.abort();
        this.controllers.fetch = null;
      }
      this.controllers.fetch = new AbortController();

      const route = useRoute();
      const idSociety = +route.params.id!;
      await $silex()
        .account.getAll({
          id: idSociety,
          includeGeneric: true,
          includeDeletedAccount: false,
          filteredByPersonilizedBusiness: false,
          onlySpecific: true,
          onlyGeneric: false,
        })
        .then((categories: IAccount[]) => {
          const sortedCategories = categories
            .filter((ac) => !ac.isGeneric)
            .sort((a, b) => (a.number > b.number ? 1 : a.number < b.number ? -1 : 0));

          this.items = sortedCategories;

          if (this.queryProperties.search) {
            this.items = this.items.filter((acc) =>
              acc.name
                .toLocaleLowerCase()
                .includes(this.queryProperties.search!.toLocaleLowerCase()),
            );
          }

          this.totalItems = this.items.length;
          this.maxPages = Math.ceil(this.items.length / this.queryProperties.limit!);
          this.currentPage = this.queryProperties.page;
          this.hasBeenAlreadyFetch = true;
        })
        .catch((error) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
