export default defineNuxtPlugin(() => {
  if (
    apiHost.includes("comptalib") ||
    apiHost.includes("clementine") ||
    apiHost.includes("legalstart")
  ) {
    const token = apiHost.includes("comptalib")
      ? "0b19b363-9c11-4935-b06c-d02d04f48cd8"
      : apiHost.includes("legalstart")
        ? "7056e870-6834-4107-9d46-a97790eb05a7"
        : apiHost.includes("clementine")
          ? "60ff5126-6036-417b-bc9f-d3264a683ec9"
          : null;
    window.$crisp = [];
    // @ts-expect-error
    window.CRISP_WEBSITE_ID = token;

    (function () {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }
});

declare global {
  interface Window {
    $crisp: any;
  }
}
