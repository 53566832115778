import type { BusinessSectorTypeContent, IAccount} from "@silexpert/core";
import { businessSectors } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type PersoAccountingPlanQueryProperties = {
  limit: number | null;
  page: number | null;
  search: string | null;
  filteredTag: number[];
};

export const defaultPersoAccountingPlanQueryProperties = ref<PersoAccountingPlanQueryProperties>({
  search: null,
  page: 1,
  limit: 25,
  filteredTag: [],
});

export type PersoAccountingPlanState = {
  controllers: { fetch: AbortController | null };
  queryProperties: PersoAccountingPlanQueryProperties;
  allItems: IAccount[];
  usableIitems: IAccount[];
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  hasAnyItem: boolean;
  isLoading: boolean;
  hasBeenAlreadyFetch: boolean;
};

const businessSectorsEnum = Object.values(businessSectors);

export const usePersoAccountingPlanStore = defineStore("persoAccountingPlan", {
  state: () =>
    ref<PersoAccountingPlanState>({
      controllers: { fetch: null },
      queryProperties: cloneDeep(defaultPersoAccountingPlanQueryProperties.value),
      allItems: [],
      usableIitems: [],
      maxPages: null,
      currentPage: null,
      totalItems: null,
      hasAnyItem: false,
      isLoading: false,
      hasBeenAlreadyFetch: false,
    }),
  getters: {
    selectedSector(): BusinessSectorTypeContent {
      const settingsAccountingPlanStore = useSettingAccountingPlanStore();
      const societyBusinessSectors = settingsAccountingPlanStore.businessSectorAccountingPlan;
      const sectorModules = businessSectorsEnum.filter((bse) => !bse.module);
      return (
        sectorModules.find((sm) =>
          societyBusinessSectors.map((sbs) => sbs.idSector).includes(sm.id ?? 0),
        ) ?? sectorModules[0]
      );
    },
    selectedModules(): BusinessSectorTypeContent[] {
      const settingsAccountingPlanStore = useSettingAccountingPlanStore();
      const societyBusinessSectors = settingsAccountingPlanStore.businessSectorAccountingPlan;
      const businessModules = businessSectorsEnum.filter((bse) => bse.module);
      return businessModules.filter((sm) =>
        societyBusinessSectors.map((sbs) => sbs.idSector).includes(sm.id ?? 0),
      );
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultPersoAccountingPlanQueryProperties.value);
      this.allItems = [];
      this.usableIitems = [];
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.hasAnyItem = false;
      this.isLoading = false;
      this.hasBeenAlreadyFetch = false;
      this.controllers = { fetch: null };
    },
    async fetchPersoAccounts(bypassCall: boolean) {
      this.isLoading = true;
      if (!bypassCall) {
        const route = useRoute();
        const idSociety = +route.params.id!;
        if (this.controllers.fetch && this.controllers.fetch.signal) {
          await this.controllers.fetch.abort();
          this.controllers.fetch = null;
        }
        this.controllers.fetch = new AbortController();

        await $silex()
          .account.getAll({
            id: idSociety,
            includeGeneric: true,
            includeDeletedAccount: false,
            filteredByPersonilizedBusiness: false,
            onlySpecific: true,
            onlyGeneric: false,
          })
          .then((categories: IAccount[]) => {
            this.allItems = categories;
            let societyModulesAndSector: number[] = this.selectedModules
              .map((sm) => sm.id)
              .concat([this.selectedSector.id]);

            if (this.queryProperties.filteredTag.length) {
              societyModulesAndSector = this.queryProperties.filteredTag;
            }
            let cat: IAccount[] = [];
            categories.forEach((acc) => {
              const accountsSectorsId = (acc.accountsSectors ?? []).map((acs) => acs.idSector);

              if (accountsSectorsId.length) {
                const match = accountsSectorsId.filter((val) =>
                  societyModulesAndSector.includes(val),
                );
                if (match.length > 0) {
                  cat = cat.concat([acc]);
                }
              }
            });
            cat = cat.filter(
              (acc) =>
                !!(acc.accountsSectors ?? []).find((acs) =>
                  societyModulesAndSector.includes(acs.idSector),
                ),
            );

            const sortedCategories = cat.sort((a, b) =>
              a.number > b.number ? 1 : a.number < b.number ? -1 : 0,
            );

            this.usableIitems = sortedCategories;

            if (this.queryProperties.search) {
              this.usableIitems = this.usableIitems.filter((acc) =>
                acc.name
                  .toLocaleLowerCase()
                  .includes(this.queryProperties.search!.toLocaleLowerCase()),
              );
            }

            this.totalItems = this.usableIitems.length;
            this.maxPages = Math.ceil(this.usableIitems.length / this.queryProperties.limit!);
            this.currentPage = this.queryProperties.page;
            this.hasBeenAlreadyFetch = true;
          })
          .catch((error) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
});
