export enum BalanceSheetModuleSection {
  IMMOBILIZATION = 0,
  LOAN = 1,
  SALARY_FILE = 2,
  INCOME_ALLOCATION = 3,
  MANUAL_ENTRY = 4,
  DUBIOUS_DEBT = 5,
  SOCIAL_CAPITAL = 6,
  INVENTORY_STOCK = 7,
  VAT_CONTROL = 8,
  CUSTOMER_PROVIDER_OPERATIONS = 9,
  COMPANY_TAX = 10,
  KILOMETRIC_ALLOWANCE = 11,
  CUSTOMER_OPERATIONS = 12,
  CUSTOMER_PREPAID_OPERATIONS = 13,
  PROVIDER_PREPAID_OPERATIONS = 14,
  PROVIDER_OPERATIONS = 15,
  MEAL_EXPENSE = 16,
}
