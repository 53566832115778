/**
 * @param {String} name Brand name
 * @param {String} urlFragment Fragment URL
 * @param {Object} designColors Primary Colors
 * @param {String} favicon Favicon local url
 * @param {String} logo Logo, hostS3, img name


 * @param {Boolean} byClementine Display "Par clementine" in the navbar
 * @param {String} budgetBaseUrlProduction Url Budget production
 * @param {String} budgetBaseUrlStaging Url budget staging
 * @param {Object} stripe Stripe configuration
 * @param {string} headwayId Id account for headway app
 */

export const brands = [
  {
    name: "Clementine",
    urlFragment: "default",
    designColors: {
      primary50: "#FFF3ED",
      primary100: "#FFE5D8",
      primary300: "#FFB28A",
      primary500: "#FF7E3C",
      primary700: "#D75817",
    },
    favicon: "/favicon-16x16.png",
    logo: "72030539-45a0-57fb-a7bb-e151e7d2721b",
    production: "app.clementine.fr",
    staging: "app-staging.clementine.fr",
    stripe: {
      development: {
        idClient: "ca_ANlZHRKPYoKlAaI8x39GsjxCXOIkhKfK",
        key: "pk_test_vLr2x8pUdGZBefo2VzGqyx0x",
      },
      production: {
        idClient: "ca_ANlZ1qNn4cPovKPKY3NPhVgIBHTTkIOZ",
        key: "pk_live_XIxAWWNChlwKBDOQVgW6vYqP",
      },
    },
    budgetInsight: {
      production: {
        idClient: 727287,
        domain: "myclementine",
      },
      staging: {
        idClient: 30700800,
        domain: "silexpert-sandbox",
      },
    },
    shine: {
      production: {
        idClient: "4ab0512d-0795-45b3-963c-06e3e19d5fb3",
      },
      staging: {
        idClient: "3b63c53b-21d0-4d35-b8c8-ea6bc88a8de6",
      },
    },
  },
  {
    name: "Comptastart",
    urlFragment: "legalstart",
    designColors: {
      primary50: "#ECF1FF",
      primary100: "#DFE7FF",
      primary300: "#C0D1FA",
      primary500: "#5363EC",
      primary700: "#3B4DE8",
    },
    production: "gestion.legalstart.fr",
    staging: "",
    favicon: "/favicon-legalstart-16x16.svg",
    logo: "0e4e28df-f317-41bd-ba6a-7bc89ed110e91.svg",
    stripe: {
      development: {
        idClient: "ca_COuQ8M7v82hpwosEbj8x0H8MCEK4mxsq",
        key: "pk_test_fHJLkytvTQcpnKS49Ytfh4Kk",
      },
      production: {
        idClient: "ca_COuQDtT0i86udw63lFlx2VGaA57SJ8na",
        key: "pk_live_egC1r5zg68j5N5zgihmtZcRV",
      },
    },
    budgetInsight: {
      production: {
        idClient: 642778,
        domain: "cscc",
      },
      staging: {
        idClient: 30700800,
        domain: "silexpert-sandbox",
      },
    },
    shine: {
      production: {
        idClient: "587c1898-39f4-4b21-b607-f7ceb1cdd6c6",
      },
      staging: {
        idClient: "3b63c53b-21d0-4d35-b8c8-ea6bc88a8de6",
      },
    },
  },
  {
    name: "Comptalib",
    urlFragment: "comptalib",
    designColors: {
      primary50: "#EDF2FF",
      primary100: "#D0DDFF",
      primary300: "#739AFF",
      primary500: "#1656FF",
      primary700: "#0B2B80",
    },
    production: "app.comptalib.com",
    staging: "app-staging.comptalib.com",
    favicon: "/favicon-comptalib.png",
    logo: "72030539-45a0-57fb-a7bb-e151e7d2721b.svg",
    stripe: {
      development: {
        idClient: "ca_IOz0PsSrVJ72YVFy5pH2f2Am7fbbfKBx",
        key: "pk_test_51HoB1SIHo1jEedppdnKBoHRhMidXZSdGyexUxcXJ2iW6ONaapVkucxvQBQzMhwE5RbfRrzvfCwHvoAtfwZavr2HH00qf8tZsdt",
      },
      production: {
        idClient: "ca_IOz09I5yErMpYVQlR53onBk1wfaRAnlR",
        key: "pk_live_51HoB1SIHo1jEedppjIDzqIwC1ObD5LsztKvwkmeSsPMaT5cc5w6rY9YFQwglf4sDGdpDVOQROY8pStvtVPXPme4E00tdX5uMbb",
      },
    },
    budgetInsight: {
      production: {
        idClient: 61910074,
        domain: "silexpert",
      },
      staging: {
        idClient: 30700800,
        domain: "silexpert-sandbox",
      },
    },
    shine: {
      production: {
        idClient: "1c3ca10a-8d1c-4546-b82b-b086fee02d04",
      },
      staging: {
        idClient: "3b63c53b-21d0-4d35-b8c8-ea6bc88a8de6",
      },
    },
  },
  {
    name: "Anytime",
    urlFragment: "anytime",
    designColors: {
      primary50: "#F4FBFF",
      primary100: "#CFF4FF",
      primary300: "#7FE7FF",
      primary500: "#31DAFE",
      primary700: "#439CB5",
    },
    favicon: "/favicon-anytime-16x16.png",
    logo: "e5abf44b-106d-5c1f-9e38-718eb0482929",
    production: "app-anytime.compta-clementine.fr",
    staging: "",
    stripe: {
      development: {
        key: "pk_test_51HoB1SIHo1jEedppdnKBoHRhMidXZSdGyexUxcXJ2iW6ONaapVkucxvQBQzMhwE5RbfRrzvfCwHvoAtfwZavr2HH00qf8tZsdt",
      },
      production: {
        key: "pk_live_51HoB1SIHo1jEedppjIDzqIwC1ObD5LsztKvwkmeSsPMaT5cc5w6rY9YFQwglf4sDGdpDVOQROY8pStvtVPXPme4E00tdX5uMbb",
      },
    },
    budgetInsight: {
      production: {
        idClient: 727287,
        domain: "myclementine",
      },
      staging: {
        idClient: 30700800,
        domain: "silexpert-sandbox",
      },
    },
    shine: {
      production: {
        idClient: "4ab0512d-0795-45b3-963c-06e3e19d5fb3",
      },
      staging: {
        idClient: "3b63c53b-21d0-4d35-b8c8-ea6bc88a8de6",
      },
    },
  },
  {
    name: "Pourcentage",
    urlFragment: "pourcentage",
    designColors: {
      primary50: "#DFF9F1",
      primary100: "#8ACAB7",
      primary300: "#589E89",
      primary500: "#386D5D",
      primary700: "#1F493D",
    },
    favicon: "/favicon-pourcentage.png",
    logo: "pourcentage-logo.png",
    production: "app.pourcentage.fr",
    staging: "",
    stripe: {
      development: {
        idClient: "ca_NemWFAz25Js24uv9kYLpd9I51NlXfqT3",
        key: "pk_test_51MrGBrKA2NJ8eb1G4GK8mPFTZ5y8vE1YnIgdZF6cJqM5hExPZmuuV7PoIWwVM2PmBxXtm0VN4KEgo5WYPzmLoMD400rJd6sDKg",
      },
      production: {
        idClient: "ca_NemWWbB8Z9oq93ssZxsCiTQJFmUor7GL",
        key: "pk_live_51MrGBrKA2NJ8eb1GjOiiWpIuu0oggopbQvEVxl8oC6ZTwl5oHdcMu7ewO34JCWZmwnVOp4Ok8FUhSQEAgIU0JHh900lQJUyPMt",
      },
    },
    budgetInsight: {
      production: {
        idClient: 31077958,
        domain: "comptalib",
      },
      staging: {
        idClient: 30700800,
        domain: "silexpert-sandbox",
      },
    },
    shine: {
      production: {
        idClient: "e074c0e7-656b-40ec-8469-5c639e56146c",
      },
      staging: {
        idClient: "e074c0e7-656b-40ec-8469-5c639e56146c",
      },
    },
  },
];

export const defaultBrand = brands[0];
