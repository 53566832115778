import type { IBalanceSheetModule, ReadInterchange } from "@silexpert/core";
import {
  CerfaType,
  LegalFormTypeEnum,
  RegistrationState,
  TaxSystem,
  TypeCustomerProviderOperation,
  TypeIncomeAllocation,
  retrieveCerfaFromSocietyInformations,
} from "@silexpert/core";
import { BalanceSheetModuleSection } from "~/@types/localTypes/balanceSheetModules";

type Error = {
  label: string | null;
  details: (string | null | undefined)[];
};
type Errors = { cerfa: string | null; errors: Error[] };

type CustomerProviderOperationModule = {
  isCustomer: boolean;
  types: TypeCustomerProviderOperation[];
  title: string;
  helpText: string;
  emptyText: string;
  newText: string;
  icon: string;
  tooltip?: string;
  keyToState: (keyof IBalanceSheetModule)[];
  moduleSection: CustomerProviderOperationSection;
};

export function useBalanceSheetComposable() {
  const { isComptalib } = useBrandsComposable();
  const balanceSheetStore = useBalanceSheetStore();
  const balanceSheetModulesStore = useBalanceSheetModulesStore();
  const incomeAllocationStore = useIncomeAllocationStore();
  const exerciceStore = useExerciceStore();
  const societyStore = useSocietyStore();

  const cerfaToFill = computed<{ declaration: number; annex: number }>(() => {
    const idLegalForm = societyStore.society?.idLegalForm ?? null;
    const idTaxSystem = societyStore.society?.idTaxSystem ?? null;
    const idFiscalRegime = societyStore.society?.idFiscalRegime ?? null;
    const idProfitsType = societyStore.society?.profitsType ?? null;

    if (!idLegalForm || !idTaxSystem || !idFiscalRegime || !idProfitsType)
      return { declaration: -1, annex: -1 };
    try {
      const cerfa = retrieveCerfaFromSocietyInformations(
        idLegalForm,
        idTaxSystem,
        idFiscalRegime,
        idProfitsType,
      );
      return { declaration: cerfa.declaration ?? -1, annex: cerfa.annex ?? -1 };
    } catch {
      return { declaration: -1, annex: -1 };
    }
  });

  function idCerfaTypeToString(id: CerfaType): string | null {
    if ([CerfaType.CERFA_2572_2022, CerfaType.CERFA_2572_2023].includes(id)) {
      return "CERFA 2572";
    }
    if (id === CerfaType.CERFA_2031) {
      return "CERFA 2031";
    }
    if (id === CerfaType.CERFA_2065) {
      return "CERFA 2065";
    }
    if (id === CerfaType.CERFA_2035) {
      return "CERFA 2035";
    }
    return null;
  }

  function idCerfaTypeToKey(id: CerfaType): string {
    switch (id) {
      case CerfaType.CERFA_2031:
        return "cerfa2031";
      case CerfaType.CERFA_2035:
        return "cerfa2035";
      case CerfaType.CERFA_2065:
        return "cerfa2065";
      default:
        return "";
    }
  }

  function errors(): Errors[] {
    const declarationErrors: Errors[] = [];
    balanceSheetStore.currentRegistrations.forEach((registration) => {
      const response = registration.response as ReadInterchange;
      const errors =
        response?.declarations
          .filter((d) => d.states.find((s) => s.isError))
          .map((d) => d.states)
          .flat()
          .filter((s) => s.isError) ?? [];

      const formattedErrors: Errors = {
        cerfa: idCerfaTypeToString(registration.idCerfaType),
        errors: [],
      };
      formattedErrors.errors = errors.reduce((acc, error) => {
        acc.push({
          label: error.label,
          details: error.stateDetailsHistory.map((d) => d.detailledLabel),
        });
        return acc;
      }, [] as Error[]);

      if (response.declarations.length === 0) {
        const historyErrors = response.history.filter((h) => h.isError === true);
        if (historyErrors.length > 0) {
          formattedErrors.errors = historyErrors.reduce((acc, error) => {
            acc.push({
              label: error.name,
              details: (error?.stateDetailsHistory ?? []).map((s) => s.detailledLabel),
            });
            return acc;
          }, [] as Error[]);
        }
      } else if (response.declarations.find((d) => d.payment?.isError)) {
        response.declarations
          .find((d) => d.payment.isError)
          ?.payment.details.forEach((d: string) => {
            formattedErrors.errors.push({ label: "Paiement :", details: [d] });
          });
      }
      declarationErrors.push(formattedErrors);
    });
    return declarationErrors.filter((de) => de.errors.length > 0);
  }

  function state(): RegistrationState {
    const list = balanceSheetStore.currentRegistrations;
    if (list.find((l) => l.state === RegistrationState.DEFAULT)) {
      return RegistrationState.DEFAULT;
    }
    if (list.find((l) => l.state === RegistrationState.IN_PROGRESS)) {
      return RegistrationState.IN_PROGRESS;
    }
    if (list.find((l) => l.state === RegistrationState.REFUSED)) {
      return RegistrationState.REFUSED;
    }
    if (list.find((l) => l.state === RegistrationState.ACCEPTED)) {
      return RegistrationState.ACCEPTED;
    }
    return RegistrationState.DEFAULT;
  }

  const preClosingModules = computed<Array<keyof IBalanceSheetModule>>(() => {
    const modules: Array<keyof IBalanceSheetModule> = [
      "loanToDeclare",
      "salariesFile",
      "hasManualEntryToDeclare",
      "recoveryImmobilization",
    ];

    if (!isComptalib()) {
      modules.push("hasDubiousCustomers");
    }

    if (!exerciceStore.previousExerciceFromCurrent) {
      modules.push("hasPreviousExercice");
    }

    if (incomeAllocationStore.typeAndAmount?.type !== TypeIncomeAllocation.ZERO) {
      modules.push("incomeAllocation");
    }

    return modules;
  });

  const postClosingModules = computed<Array<keyof IBalanceSheetModule>>(() => {
    const modules: Array<keyof IBalanceSheetModule> = [
      "inventory",
      "hasValidateKilometricAllowance",
    ];

    if (societyStore.society?.idTaxSystem !== TaxSystem.IS) {
      modules.push("hasMealExpenses");
    } else {
      modules.push("hasCheckedCompanyTax");
    }

    if (
      societyStore.society?.idLegalForm &&
      ![LegalFormTypeEnum.EI, LegalFormTypeEnum.EIRL].includes(societyStore.society!.idLegalForm!)
    ) {
      if (societyStore.society!.idLegalForm! !== LegalFormTypeEnum.ASSOCIATION) {
        modules.push("hasShareCapital");
      }
      modules.push("hasCustomerInvoiceToEstablish");
      modules.push("hasCustomerAssetToEstablish");
      modules.push("hasCustomerPrepaidIncome");
      modules.push("hasProviderInvoiceNotReceived");
      modules.push("hasProviderAssetToReceived");
      modules.push("hasProviderPrepaidReceived");
    }

    if (!societyStore.isNotSubjectToVat) {
      modules.push("hasCheckedVAT");
    }

    return modules;
  });

  /**
   * BalanceSheet Module Section
   */
  // Return true if the module is already fetched for the given exercice
  // else return false and set the exercice as fetched
  function isModuleAlreadyFilled(module: BalanceSheetModuleSection, idExercice: number): boolean {
    if (balanceSheetModulesStore.moduleState[module].lastExerciceFetched === idExercice)
      return true;

    balanceSheetModulesStore.moduleState[module].lastExerciceFetched = idExercice;
    return false;
  }

  function closeAllOtherModules(module: BalanceSheetModuleSection): void {
    Object.keys(balanceSheetModulesStore.moduleState).forEach((key) => {
      const name = Number(key) as BalanceSheetModuleSection;

      if (name !== module) {
        balanceSheetModulesStore.moduleState[name].isOpen = false;
      }
    });
  }

  const preClosingStats = computed<{ percent: number; total: number; filled: number }>(() => {
    const total = preClosingModules.value.length;
    let filled = 0;
    preClosingModules.value.forEach((module) => {
      // The recoveryImmobilization module is always considered as filled
      if (module === "recoveryImmobilization") filled += 1;
      else if (isDefined(balanceSheetModulesStore.currentModule?.[module])) filled += 1;
    });
    return {
      percent: Math.round((filled / total) * 100),
      total,
      filled,
    };
  });

  const postClosingStats = computed<{ percent: number; total: number; filled: number }>(() => {
    const total = postClosingModules.value.length;
    let filled = 0;
    postClosingModules.value.forEach((module) => {
      if (isDefined(balanceSheetModulesStore.currentModule?.[module])) filled += 1;
    });
    return {
      percent: Math.round((filled / total) * 100),
      total,
      filled,
    };
  });

  const globalStats = computed<{ percent: number; total: number; filled: number }>(() => {
    const total = preClosingStats.value.total + postClosingStats.value.total;
    const filled = preClosingStats.value.filled + postClosingStats.value.filled;
    return {
      percent: Math.round((filled / total) * 100),
      total,
      filled,
    };
  });

  const customerProviderModules: CustomerProviderOperationModule[] = [
    {
      types: [
        TypeCustomerProviderOperation.CUSTOMER_ASSET_TO_ESTABLISH,
        TypeCustomerProviderOperation.CUSTOMER_INVOICE_TO_ESTABLISH,
        TypeCustomerProviderOperation.CUSTOMER_DEBT,
      ],
      isCustomer: true,
      title: "Clients",
      helpText:
        "Renseignez toutes les factures et tous les avoirs qui n'ont pas été faits par l'entreprise à la clôture de l'exercice et qui concernent des livraisons de biens ou des prestations réalisées avant cette date.",
      emptyText: "Aucun produit constaté d'avance",
      newText: "Ajouter",
      icon: "description",
      keyToState: [
        "hasCustomerInvoiceToEstablish",
        "hasCustomerAssetToEstablish",
        "hasCustomerDebt",
      ],
      moduleSection: BalanceSheetModuleSection.CUSTOMER_OPERATIONS,
    },
    {
      types: [TypeCustomerProviderOperation.CUSTOMER_PREPAID_INCOME],
      isCustomer: true,
      title: "Clients: Gestion produit constaté d’avance",
      helpText:
        "Biens ou services déjà facturés et enregistrés en comptabilité mais qui n'ont pas été livrés au client ou partiellement à la date de clôture de l'exercice.",
      emptyText: "Aucun produit constaté d'avance",
      newText: "Ajouter un produit constaté d’avance",
      icon: "ballot",
      tooltip:
        "Tous les avoirs sur factures de ventes qui n'ont pas été établis par l'entreprise à la clôture de l'exercice et qui concernent des livraisons de biens ou des prestations réalisées avant cette date.",
      keyToState: ["hasCustomerPrepaidIncome"],
      moduleSection: BalanceSheetModuleSection.CUSTOMER_PREPAID_OPERATIONS,
    },
    {
      types: [TypeCustomerProviderOperation.PROVIDER_PREPAID_RECEIVED],
      isCustomer: false,
      title: "Fournisseurs: Gestion charge constatée d’avance",
      helpText:
        "Biens ou services effectués au cours d'un exercice, déjà facturés et enregistrés en comptabilité mais qui n'ont pas été livrés par le fournisseur ou partiellement à la date de clôture de l'exercice, et/ou qui n’ont pas encore été consommés à sa clôture.",
      emptyText: "Aucune charge constatée d’avance",
      newText: "Ajouter une charge constatée d’avance",
      icon: "ballot",
      tooltip:
        "Biens ou services effectués au cours d'un exercice, déjà facturés et enregistrés en comptabilité mais qui n'ont pas été livrés par le fournisseur ou partiellement à la date de clôture de l'exercice, et/ou qui n’ont pas encore été consommés à sa clôture.",
      keyToState: ["hasProviderPrepaidReceived"],
      moduleSection: BalanceSheetModuleSection.PROVIDER_PREPAID_OPERATIONS,
    },
    {
      types: [
        TypeCustomerProviderOperation.PROVIDER_INVOICE_NOT_RECEIVED,
        TypeCustomerProviderOperation.PROVIDER_ASSET_TO_RECEIVE,
        TypeCustomerProviderOperation.PROVIDER_DUE,
      ],
      isCustomer: false,
      title: "Fournisseurs",
      helpText:
        "Avoir sur bien ou service livré à une entreprise durant un exercice comptable, mais sans que la facture d'avoir du fournisseur ait été réceptionnée avant la clôture de cet exercice.",
      emptyText: "Aucune charge constatée d’avance",
      newText: "Ajouter",
      icon: "ballot",
      keyToState: ["hasProviderInvoiceNotReceived", "hasProviderAssetToReceived", "hasProviderDue"],
      moduleSection: BalanceSheetModuleSection.PROVIDER_OPERATIONS,
    },
  ];

  return {
    cerfaToFill,
    state,
    errors,
    preClosingModules,
    postClosingModules,
    idCerfaTypeToKey,
    customerProviderModules,
    closeAllOtherModules,
    isModuleAlreadyFilled,
    preClosingStats,
    postClosingStats,
    globalStats,
  };
}
