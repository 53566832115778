import type { Role} from "@silexpert/core";
import { RoleAllowedToLogInAsClient } from "@silexpert/core";

export default defineNuxtRouteMiddleware(async (to) => {
  const roleComposable = useRolesComposable();

  const { layout } = to.meta;

  if (layout === "admin") {
    if (!roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient)) {
      return await navigateTo("/errors/notAllowed");
    }
  }

  const roles = to.meta.roles as Role[] | undefined;
  if (typeof roles?.length === "number" && roles.length > 0) {
    if (!roleComposable.hasOneOfTheseRoles(roles)) {
      return navigateTo("/errors/notAllowed");
    }
  }
});
