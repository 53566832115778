export default defineNuxtRouteMiddleware((to) => {
  const accessComposable = useAccessComposable();

  if (accessComposable.isRestricted() && accessComposable.isFreemiumRestrictedPath(to.path)) {
    const userStore = useUsersStore();
    const subscriptionStore = useSubscriptionStore();
    subscriptionStore.isRestricted = true;
    return navigateTo(userStore.homePage);
  }
});
