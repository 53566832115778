import type { IAccountingEntry } from "@silexpert/core";

type ManualEntryState = {
  isLoading: boolean;
  entries: IAccountingEntry[][];
};

export const useManualEntryStore = defineStore("manualEntry", {
  state: () =>
    ref<ManualEntryState>({
      isLoading: false,
      entries: [],
    }),
  actions: {
    reset() {
      this.isLoading = false;
      this.entries = [];
    },
    async fetchEntries(idExercice?: number) {
      try {
        this.isLoading = true;
        const societyStore = useSocietyStore();
        const exerciceStore = useExerciceStore();
        this.entries = await $silex().transaction.getAllManual(
          societyStore.society!.id!,
          idExercice || exerciceStore.current!.id!,
        );
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
      this.isLoading = false;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
