import type {
  ICycle,
  ISupervisionPendingPoint,
  ISupervisionPersonalAccountantNote,
  IUser,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type PersonalNote = {
  id: number;
  note: string;
  user: IUser;
  creationDate: Date;
};

export type EditablePendingPoint = {
  idPendingPoint: number;
  label: string;
  hasLabelBeenUpdate: boolean;
  isSelected: boolean;
  isCritical: boolean;
  isVisible: boolean;
  isSelectedHasBeenChange?: boolean;
  isNotDeletable?: boolean;
  toBalanceSheetNote: boolean;
  toSummaryNote: boolean;
  isDone: boolean;
  userCreation: IUser | null;
};

export type CycleWithPendingPoint = {
  id: number;
  label: string;
  activatedPendingPoints: ISupervisionPendingPoint[];
  editablePendingPoints: EditablePendingPoint[];
  personalNote?: PersonalNote;
  isActive: boolean;
};

export type PendingPointsQueryProperties = {
  startDate: string | null;
  endDate: string | null;
  limit: number | null;
  idExercice: number | null;
  keepActivePendingPoint: boolean;
};

type PendingPointsState = {
  queryProperties: PendingPointsQueryProperties;
  cyclesWithPendingPoints: CycleWithPendingPoint[];
  hasAnyItem: boolean;
  isLoading: boolean;
  noDataReason: string | null;
};

export type EditPendingPointPayload = {
  idPendingPoint: number;
  label?: string;
  hasLabelBeenUpdate?: boolean;
  isSelected?: boolean;
  isCritical?: boolean;
  isVisible?: boolean;
  isSelectedHasBeenChange?: boolean;
  isDone?: boolean;
  toBalanceSheetNote?: boolean;
  toSummaryNote?: boolean;
};

export const defaultPendingPointsQueryProperties = ref<PendingPointsQueryProperties>({
  startDate: null,
  endDate: null,
  limit: null,
  idExercice: null,
  keepActivePendingPoint: false,
});

export const usePendingPointsStore = defineStore("pendingPoints", {
  state: () =>
    ref<PendingPointsState>({
      queryProperties: cloneDeep(defaultPendingPointsQueryProperties.value),
      cyclesWithPendingPoints: [],
      hasAnyItem: false,
      isLoading: false,
      noDataReason: null,
    }),
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultPendingPointsQueryProperties.value);
      this.cyclesWithPendingPoints = [];
      this.isLoading = false;
      this.hasAnyItem = false;
    },
    setEditablePendingPoint(payload: EditPendingPointPayload) {
      this.cyclesWithPendingPoints = this.cyclesWithPendingPoints.map(
        (cpp: CycleWithPendingPoint) => ({
          ...cpp,
          editablePendingPoints: cpp.editablePendingPoints.map((epp) => ({
            ...(epp.idPendingPoint === payload.idPendingPoint
              ? {
                  ...epp,
                  ...(isDefined(payload.isSelected)
                    ? {
                        isSelected: payload.isSelected,
                        ...(epp.isCritical && !payload.isSelected ? { isCritical: false } : {}),
                      }
                    : {}),
                  ...(isDefined(payload.label) ? { label: payload.label } : {}),
                  ...(isDefined(payload.isCritical) ? { isCritical: payload.isCritical } : {}),
                  ...(isDefined(payload.isDone) ? { isDone: payload.isDone } : {}),
                  ...(isDefined(payload.isVisible) ? { isVisible: payload.isVisible } : {}),
                  ...(isDefined(payload.hasLabelBeenUpdate)
                    ? { hasLabelBeenUpdate: payload.hasLabelBeenUpdate }
                    : {}),
                  ...(isDefined(payload.isSelectedHasBeenChange)
                    ? { isSelectedHasBeenChange: payload.isSelectedHasBeenChange }
                    : {}),
                  ...(isDefined(payload.toBalanceSheetNote)
                    ? { toBalanceSheetNote: payload.toBalanceSheetNote }
                    : {}),
                  ...(isDefined(payload.toSummaryNote)
                    ? { toSummaryNote: payload.toSummaryNote }
                    : {}),
                }
              : {
                  ...epp,
                }),
          })),
        }),
      );
    },
    async fetchPendingPoints(reArrangeOrder: boolean) {
      const dayjs = useDayjs();
      const societyStore = useSocietyStore();
      const exerciceStore = useExerciceStore();
      const societyId = societyStore.society?.id;
      const societyLegalForm = societyStore.society?.idLegalForm;

      let cycles: ICycle[] = [];
      let pendingPoints: ISupervisionPendingPoint[] = [];
      let notes: ISupervisionPersonalAccountantNote[] = [];
      const { startDate, endDate, idExercice } = this.queryProperties;
      const exerciceId = idExercice ?? exerciceStore.current?.id;
      const balanceSheet = await $silex().balanceSheet.getByExercice(exerciceId!);

      if (!balanceSheet) {
        this.noDataReason = "Pas de bilan pour cet exercice";
        return;
      }

      this.noDataReason = null;
      await Promise.all([
        $silex()
          .revision.getAllGenericPendingPoints({
            idTypeLegalForm: societyLegalForm!,
          })
          .then((res) => (cycles = res))
          .catch((error) => {
            cycles = [];
            $notifier().open({ type: "error", content: apiErrorToString(error) });
          }),

        $silex()
          .revision.getAllPendingPoints({
            idSociety: societyId!,
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(),
          })
          .then((res) => (pendingPoints = res))
          .catch((error) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
          }),

        $silex()
          .revision.getPersonalNotes({
            idSociety: societyId!,
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(),
          })
          .then((res) => (notes = res))
          .catch((error) => {
            $notifier().open({ type: "error", content: apiErrorToString(error) });
          }),
      ]);

      try {
        let cyclesWithPendingPoints: CycleWithPendingPoint[] = cycles.map((cycle) => ({
          id: cycle.id!,
          label: cycle.label,
          activatedPendingPoints: pendingPoints.filter((pp) =>
            cycle.pendingPoints.map((cpp) => cpp.id).includes(pp.idPendingPoint),
          ),
          isActive: this.queryProperties.keepActivePendingPoint
            ? (this.cyclesWithPendingPoints.find((c: CycleWithPendingPoint) => c.id === cycle.id)
                ?.isActive ?? this.cyclesWithPendingPoints[0].isActive)
            : false,
          editablePendingPoints: cycle.pendingPoints.map((pp) => {
            return {
              idPendingPoint: pp.id!,
              isSelected: !!pendingPoints.find((app) => app.idPendingPoint === pp.id!),
              label: pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.value ?? pp.label,
              isCritical:
                pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.isCritical ?? false,
              isVisible:
                (isDefined(pp.label) && pp.label.length > 0) ||
                !!pendingPoints.find((app) => app.idPendingPoint === pp.id!),
              hasLabelBeenUpdate: false,
              isSelectedHasBeenChange: false,
              isNotDeletable: !!pendingPoints.find(
                (p) => p.idPendingPoint === pp.id && p.pendingPointReplies.length > 0,
              ),
              toBalanceSheetNote:
                pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.toBalanceSheetNote ??
                false,
              toSummaryNote:
                pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.toSummaryNote ?? false,
              isDone: pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.isDone ?? false,
              userCreation:
                pendingPoints.find((app) => app.idPendingPoint === pp.id!)?.ppIsCreatedBy ?? null,
            };
          }),
        }));

        cyclesWithPendingPoints = cyclesWithPendingPoints.map((cycle) => ({
          ...cycle,
          ...(notes.find((n) => n.idCycle === cycle.id)
            ? {
                personalNote: {
                  id: notes.find((n) => n.idCycle === cycle.id)?.id ?? notes[0].id!,
                  note: notes.find((n) => n.idCycle === cycle.id)?.note ?? notes[0].note!,
                  user: notes.find((n) => n.idCycle === cycle.id)?.user ?? notes[0].user!,
                  creationDate:
                    notes.find((n) => n.idCycle === cycle.id)?.createdAt ?? notes[0].createdAt!,
                },
              }
            : {}),
        }));
        if (reArrangeOrder) {
          cyclesWithPendingPoints.sort((a, b) => {
            const aPendingPointLength = a.activatedPendingPoints.length;
            const bPendingPointLength = b.activatedPendingPoints.length;
            return aPendingPointLength < bPendingPointLength
              ? 1
              : aPendingPointLength > bPendingPointLength
                ? -1
                : 0;
          });
        } else {
          const previousCyclesId = this.cyclesWithPendingPoints.map(
            (c: CycleWithPendingPoint) => c.id,
          );
          cyclesWithPendingPoints.sort(
            (a, b) => previousCyclesId.indexOf(a.id) - previousCyclesId.indexOf(b.id),
          );
        }
        this.cyclesWithPendingPoints = cyclesWithPendingPoints;
        this.queryProperties.keepActivePendingPoint = false;
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
        this.cyclesWithPendingPoints = [];
      }
    },
  },
});
