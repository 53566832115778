import type { ReadSocietyTransactionCount } from "@silexpert/core";
import { AccountingJournal } from "@silexpert/core";

type UnlockedState = {
  bankDocument: number;
  purchaseDocument: number;
  saleDocument: number;
  cashDocument: number;
  personalDocument: number;
};

export const useUnlockedStore = defineStore("unlocked", {
  state: () =>
    ref<UnlockedState>({
      bankDocument: 0,
      purchaseDocument: 0,
      saleDocument: 0,
      cashDocument: 0,
      personalDocument: 0,
    }),
  getters: {
    allDocument: (state) =>
      state.bankDocument +
      state.purchaseDocument +
      state.saleDocument +
      state.cashDocument +
      state.personalDocument,
  },
  actions: {
    reset() {
      this.bankDocument = 0;
      this.purchaseDocument = 0;
      this.saleDocument = 0;
      this.cashDocument = 0;
      this.personalDocument = 0;
    },
    async fetchAll(idSociety: number) {
      const results = await $silex().transaction.toAnnotateCount(idSociety, {
        idAccountingJournal: [
          AccountingJournal.PER,
          AccountingJournal.BQ,
          AccountingJournal.CAI,
          AccountingJournal.AC,
          AccountingJournal.PER,
          AccountingJournal.VE,
        ],
      });

      this.purchaseDocument = results.personal ?? 0;
      this.bankDocument = results.bank ?? 0;
      this.cashDocument = results.case ?? 0;
      this.purchaseDocument = results.purchase ?? 0;
      this.personalDocument = results.personal ?? 0;
      this.saleDocument = results.sale ?? 0;
    },
    async fetchBankTransactions(idSociety: number) {
      await $silex()
        .transaction.toAnnotateCount(idSociety, {
          idAccountingJournal: [AccountingJournal.BQ, AccountingJournal.CAI],
        })
        .then((res: ReadSocietyTransactionCount) => {
          this.bankDocument = res.bank ?? 0;
          this.cashDocument = res.case ?? 0;
        })
        .catch((error: any) => {
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        });
    },
    async fetchPurchaseDocuments(idSociety: number) {
      await $silex()
        .transaction.toAnnotateCount(idSociety, {
          idAccountingJournal: [AccountingJournal.AC, AccountingJournal.PER],
        })
        .then((res: ReadSocietyTransactionCount) => {
          this.purchaseDocument = res.purchase ?? 0;
          this.personalDocument = res.personal ?? 0;
        })
        .catch((error: any) =>
          $notifier().open({ type: "error", content: apiErrorToString(error) }),
        );
    },
    async fetchSaleDocuments(idSociety: number) {
      await $silex()
        .transaction.toAnnotateCount(idSociety, {
          idAccountingJournal: [AccountingJournal.VE],
        })
        .then((res) => (this.saleDocument = res.sale ?? 0))
        .catch((error) => $notifier().open({ type: "error", content: apiErrorToString(error) }));
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
