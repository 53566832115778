import type { Brand } from "~/@types/localTypes/utils";

export default defineNuxtPlugin(() => {
  const { $brand } = useNuxtApp();
  const brand: (() => Brand) | any = $brand;
  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = `:root { --primary-50: ${brand().designColors.primary50} !important;
  --primary-100: ${brand().designColors.primary100} !important;
  --primary-300: ${brand().designColors.primary300} !important;
  --primary-500: ${brand().designColors.primary500} !important;
  --primary-700: ${brand().designColors.primary700} !important; }`;
  document.getElementsByTagName("head")[0].appendChild(style);
});
