
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/fr'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import minMax from 'dayjs/plugin/minMax'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(quarterOfYear)
dayjs.extend(minMax)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

dayjs.tz.setDefault('Europe/Paris')

// defaultLocale: "fr"


dayjs.updateLocale("fr")
dayjs.locale('fr')


export default dayjs
