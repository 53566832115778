import type { ISociety } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type AdminSocietiesQueryProperties = {
  search: string | undefined;
  page: number;
  limit: number;
  withArchived: boolean | undefined;
  withCreation: boolean | undefined;
  descending: boolean | undefined;
  idSubscriptionType: number | undefined;
  idTypeCompta: number | undefined;
};

export const defaultAdminSocietiesQueryProperties = ref<AdminSocietiesQueryProperties>({
  page: 1,
  limit: 25,
  search: undefined,
  withArchived: undefined,
  withCreation: undefined,
  descending: undefined,
  idSubscriptionType: undefined,
  idTypeCompta: undefined,
});

type AdminSocietiesState = {
  society: ISociety | null;
  queryProperties: AdminSocietiesQueryProperties;
  societies: ISociety[];
  hasAnyItem: true;
  maxPages: number | null;
  currentPage: number | null;
  totalItems: number | null;
  controllers: { societies: AbortController | null };
  isLoading: boolean;
};

export const useAdminSocietiesStore = defineStore("adminSocieties", {
  state: () =>
    ref<AdminSocietiesState>({
      society: null,
      queryProperties: cloneDeep(
        defaultAdminSocietiesQueryProperties.value,
      ) as AdminSocietiesQueryProperties,
      societies: [],
      hasAnyItem: true,
      maxPages: null,
      currentPage: null,
      totalItems: null,
      controllers: { societies: null },
      isLoading: false,
    }),
  actions: {
    async searchSocieties() {
      try {
        if (this.controllers.societies) this.controllers.societies.abort();
        this.controllers.societies = new AbortController();

        this.isLoading = true;

        const result = await $silex().society.getPaginated(
          {
            page: this.queryProperties.page,
            limit: this.queryProperties.limit,
            search: this.queryProperties.search,
            withArchived: this.queryProperties.withArchived,
            onlyOwners: false,
          },
          undefined,
          this.controllers.societies.signal,
        );
        this.maxPages = result.maxPages;
        this.currentPage = result.currentPage;
        this.societies = result.data;
        this.totalItems = result.totalItems;

        this.isLoading = false;
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
});
