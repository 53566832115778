import type { ReadPartneredAccountant } from "@silexpert/core";

type State = {
  partnerAccountants: ReadPartneredAccountant[];
};

export const usePartnerAccountantsStore = defineStore("partnerAccountants", {
  state: (): State => {
    return {
      partnerAccountants: [],
    };
  },
  actions: {
    reset() {
      this.partnerAccountants = [];
    },
    async fetchPartnerAccountants() {
      try {
        const data = await $silex().partneredAccountant.getAll();
        this.partnerAccountants = data;
      } catch (error) {
        $notifier().open({ content: apiErrorToString(error) });
      }
    },
  },
});
