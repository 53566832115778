import { validateSiren } from "@silexpert/core";

export function apiErrorToString(error: any): string {
  console.info("👀 apiErrorToString :", error);
  if (error.response?.status === 401) {
    navigateTo("/login");
  }

  const data = error?.response?._data ?? error?.response?.data;
  if (data instanceof ArrayBuffer) {
    const decodeData = JSON.parse(new TextDecoder().decode(data as ArrayBuffer));
    return decodeData?.message ?? "Une erreur est survenue";
  }
  return data?.message ?? "Une erreur est survenue";
}

export function checkPhoneNumber(phoneNb: string | null) {
  let formattedPhoneString = "";

  if (isDefined(phoneNb)) {
    formattedPhoneString = phoneNb.replace(/\s/g, "");
  } else {
    return "Le champ est requis";
  }

  if (!formattedPhoneString.match(/^(0)(1|2|3|4|5|6|7|9)\d{8}$/gm)) {
    return "Le numéro renseigné n'est pas conforme";
  }
  return null;
}

const SIRET_VALID_LENGTH = 14;
export function isSiretValid(siret: MaybeNotDefined<string>): boolean {
  if (!isDefined(siret)) {
    return false;
  }
  return typeof siret === "string" && siret.length === SIRET_VALID_LENGTH;
}

const IBAN_VALID_LENGTH = 27;
export function checkIban(iban: MaybeNotDefined<string>): string | null {
  if (!isDefined(iban)) {
    return "Le champ est requis";
  }
  if (!iban.match(/^FR/gm)) {
    return "Le format est incorrect";
  }
  if (iban.length !== IBAN_VALID_LENGTH) {
    return "L'IBAN doit faire 27 caractères";
  }
  return null;
}

export function isValidSiren(siren: string): boolean {
  try {
    validateSiren(siren);
    return true;
  } catch {
    return false;
  }
}

export function isValidCity(city: string): string | null {
  if (!isDefined(city)) {
    return "Le champ est requis";
  }
  if (/^[a-zA-Z0-9\s-]*$/.test(city)) {
    return "Le format est incorrect";
  }

  return null;
}
