import { EstimateInvoiceType, InvoiceStatus } from "@silexpert/core";
import type {
  BasePaginate,
  ReadPaymentRecoveryStatistic,
  ReadPaymentRecovery,
  IFile,
  PaymentRecoveryStatus,
  ReadAccrualInvoice,
  ReadCashBasedInvoice,
} from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

export type RecoveryQueryProperties = {
  search: string | null;
  page: number;
  limit: number;
  itemId: number | null;
  startDate: string | null;
  endDate: string | null;
  orderBy: string | null;
  descending: boolean;
  status: "recovered" | "notRecovered" | "waiting" | null;
};

export const defaultRecoveryQueryProperties = ref<RecoveryQueryProperties>({
  search: null,
  page: 1,
  limit: 25,
  itemId: null,
  startDate: null,
  endDate: null,
  orderBy: null,
  descending: true,
  status: null,
});

export const useRecoveryStore = defineStore("recoveries", {
  state: () => {
    return {
      queryProperties: cloneDeep(defaultRecoveryQueryProperties.value) as RecoveryQueryProperties,
      items: null as (ReadPaymentRecovery & Loadable)[] | null,
      maxPages: null as number | null,
      currentPage: null as number | null,
      totalItems: null as number | null,
      isLoading: false as boolean,
      isLoadingUnpaid: false as boolean,
      hasAnyItem: null as boolean | null,
      stats: null as ReadPaymentRecoveryStatistic | null,
      unpaidInvoices: [] as FacturesItem[],
      controllers: { hasAny: null, items: null } as {
        hasAny: AbortController | null;
        items: AbortController | null;
        stats: AbortController | null;
        sales: AbortController | null;
      },
    };
  },
  getters: {
    totalUnpaidInvoices(): number {
      return this.unpaidInvoices.reduce((acc, invoice) => {
        return acc + Math.abs(invoice.estimatesInvoice?.totalTaxIncluded ?? 0);
      }, 0);
    },
  },
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultRecoveryQueryProperties.value);
      this.items = null;
      this.maxPages = null;
      this.currentPage = null;
      this.totalItems = null;
      this.isLoading = false;
      this.isLoadingUnpaid = false;
      this.hasAnyItem = null;
      this.stats = null;
      this.unpaidInvoices = [];
    },
    async fetchRecoveries() {
      this.isLoading = true;

      const { descending, page, limit, status, search } = this.queryProperties;
      if (this.controllers.items && this.controllers.items?.signal) {
        await this.controllers.items.abort();
        this.controllers.items = null;
      }
      this.controllers.items = new AbortController();

      let response: ReadPaymentRecovery[] = [];
      await $silex()
        .payment.getPaginatedRecovery(
          {
            page,
            limit,
            isDescending: descending,
            ...(status ? { status } : {}),
            ...(search ? { search } : {}),
          },
          this.controllers.items.signal,
        )
        .then((res: BasePaginate<ReadPaymentRecovery>) => {
          this.maxPages = res?.maxPages ?? 0;
          this.currentPage = res?.currentPage ?? 0;
          this.totalItems = res?.totalItems ?? 0;
          response = res.data ?? [];
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          this.maxPages = 0;
          this.currentPage = 0;
          this.totalItems = 0;
          response = [];
          $notifier().open({ type: "error", content: apiErrorToString(error) });
        })
        .finally(() => {
          const items = response.map((item) => {
            return {
              ...item,
              isLoading: false,
            };
          });

          this.items = items;
          this.isLoading = false;
        });
    },
    async fetchHasAnyItem() {
      this.hasAnyItem = null;

      if (this.controllers.hasAny && this.controllers.hasAny?.signal) {
        this.controllers.hasAny.abort();
      }
      this.controllers.hasAny = new AbortController();

      await $silex()
        .payment.getPaginatedRecovery(
          {
            page: 1,
            limit: 1,
          },
          this.controllers.hasAny.signal,
        )
        .then((res: BasePaginate<ReadPaymentRecovery>) => {
          this.hasAnyItem = res?.totalItems > 0;
        })
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          this.hasAnyItem = false;
        });
    },
    async fetchStats() {
      this.stats = null;

      const { search, startDate, endDate } = this.queryProperties;
      let response: any = null;

      if (this.controllers.stats && this.controllers.stats?.signal) {
        await this.controllers.stats.abort();
        this.controllers.stats = null;
      }
      this.controllers.stats = new AbortController();

      await $silex()
        .payment.getRecoveryStatistics(
          {
            ...(isDefined(startDate) ? { minDate: startDate } : {}),
            ...(isDefined(endDate) ? { maxDate: endDate } : {}),
            ...(isDefined(search) ? { search } : {}),
          },
          this.controllers.stats.signal,
        )
        .then((res: ReadPaymentRecoveryStatistic) => (response = res))
        .catch((error: any) => {
          if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
          $notifier().open({ type: "error", content: apiErrorToString(error) });
          response = [];
        });

      this.stats = response ?? null;
    },
    async fetchUnpaidInvoices() {
      this.isLoadingUnpaid = true;
      const societyStore = useSocietyStore();
      const isAccruals = societyStore.isAccruals;

      if (this.controllers.sales && this.controllers.sales.signal) {
        await this.controllers.sales.abort();
        this.controllers.sales = null;
      }
      this.controllers.sales = new AbortController();

      let response: any = null;
      if (isAccruals) {
        await $silex()
          .invoice.getAccrualPaginated(
            {
              status: InvoiceStatus.UNPAID,
              orderBy: "dateValue",
              page: 1,
              limit: 300,
            },
            undefined,
            this.controllers.sales.signal,
          )
          .then((res: BasePaginate<ReadAccrualInvoice>) => (response = res))
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      } else {
        await $silex()
          .invoice.getCashBasedPaginated(
            {
              status: InvoiceStatus.UNPAID,
              page: 1,
              limit: 300,
            },
            undefined,
            this.controllers.sales.signal,
          )
          .then((res: BasePaginate<ReadCashBasedInvoice>) => (response = res))
          .catch((error: any) => {
            if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
            $notifier().open({ type: "error", content: apiErrorToString(error) });
            response = [];
          });
      }

      // Remove Avoir and invoice with recovery
      this.unpaidInvoices = response.data.filter(
        (item: FacturesItem) =>
          item.estimatesInvoice?.idEstimateInvoiceType !== EstimateInvoiceType.AVOIR &&
          isNotDefined(item.file?.paymentRecovery),
      );
      this.isLoadingUnpaid = false;
    },
    setStatusItem(itemId: number, status: PaymentRecoveryStatus) {
      const item = this.items ?? [];
      const itemIndexToChange = item.findIndex((a) => a.id === itemId);

      if (itemIndexToChange > -1 && isDefined(this.items)) {
        this.items[itemIndexToChange].status = status;
      }
    },
    setUnpaidInvoice(itemId: number, recovery: IFile["paymentRecovery"]) {
      const item = this.unpaidInvoices ?? [];
      const itemIndexToChange = item.findIndex((a) => a.id === itemId);

      if (itemIndexToChange > -1 && isDefined(this.items)) {
        // @ts-expect-error
        this.unpaidInvoices[itemIndexToChange].file = {
          ...this.unpaidInvoices[itemIndexToChange].file,
          paymentRecovery: recovery,
        };
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
