import type { IConnector } from "@silexpert/core";
import { ConnectorType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { TypeConnector } from "~/@types/localTypes/connector";

type ConnectorState = {
  connectors: IConnector[];
  integrations: TypeConnector[];
};

function getDefaultConnectors(): TypeConnector[] {
  const { getBrandName } = useBrandsComposable();
  const list: TypeConnector[] = [
    {
      id: ConnectorType.SHOPIFY,
      name: "shopify",
      form: {},
      status: "default",
      color: "#F6FAF0",
      type: "E-commerce",
      description: `Importez vos factures générées depuis Shopify sur ${getBrandName() ?? "Clementine"} et ce chaque nuit. `,
    },
    {
      id: ConnectorType.PRESTASHOP,
      name: "prestashop",
      form: {},
      status: "default",
      color: "#EFF9FB",
      type: "E-commerce",
      description:
        "Importez automatiquement les futures factures de vos commandes qui seront passées sur votre site Prestashop.",
    },
    {
      id: ConnectorType.WOOCOMMERCE,
      name: "woocommerce",
      form: {},
      status: "default",
      color: "#F4F1F9",
      type: "E-commerce",
      description:
        "Importez automatiquement les futures factures de vos commandes passées sur votre site WooCommerce.",
    },
    {
      id: ConnectorType.SEELSY,
      name: "sellsy",
      form: {},
      status: "default",
      color: "#F0F2FF",
      type: "Facturation",
      description:
        "Automatisez l’import de vos factures d’achat et de vente depuis votre boîte mail, un drive ou une application.",
    },
  ];

  if (getBrandName() === EBrandNames.CLEMENTINE) {
    list.push({
      id: 99,
      name: "zapier",
      form: {},
      status: "default",
      color: "#FFF1EB",
      type: "Automatisation",
      description:
        "Automatisez l’import de vos factures d’achat et de vente depuis votre boîte mail, un drive ou une application.",
      hasOnlyOneStep: true,
    });
  }

  return list;
}

export const useConnectorStore = defineStore("connector", {
  state: () =>
    ref<ConnectorState>({
      connectors: [],
      integrations: getDefaultConnectors(),
    }),
  getters: {
    // Attention, ce n'est pas Stripe Banque mais Stripe côté ventes gescom / paiements
    stripe: (state) => {
      return state.connectors.find((c) => c.idTypeConnector === ConnectorType.STRIPE_CONNECT);
    },
  },
  actions: {
    reset() {
      this.connectors = [];
      this.integrations = getDefaultConnectors();
    },
    async fetchConnectors() {
      try {
        const societyStore = useSocietyStore();
        this.connectors = await $silex().connector.getAllFromSociety(societyStore.society!.id!, {});
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    async test(id: number): Promise<boolean> {
      try {
        const result = await $silex().connector.test(id);
        return result;
      } catch {
        return false;
      }
    },
    async fetchIntegrations() {
      const promises: Promise<any>[] = [];
      const defaultIntegration = cloneDeep(
        this.integrations.map((i) => ({
          ...i,
          form: this.connectors.find((c) => c.idTypeConnector === i.id) ?? {},
        })),
      );

      const connected: number[] = [];
      const disconnected: number[] = [];

      this.integrations
        .filter((i) => isDefined(i.form?.id))
        .forEach((integration) => {
          promises.push(
            this.test(integration.form!.id!)
              .then((r) => {
                if (r) {
                  connected.push(integration.id);
                } else {
                  disconnected.push(integration.id);
                }
              })
              .catch((e) => {
                console.error("👀 ~ e:", e);
              }),
          );
        });

      await Promise.allSettled(promises);

      this.integrations = defaultIntegration.map((i) => ({
        ...i,
        status: connected.includes(i.id)
          ? "connected"
          : disconnected.includes(i.id)
            ? "disconnected"
            : "default",
      }));
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
