export function getPrimaryColors(): {
  primary50: string;
  primary100: string;
  primary300: string;
  primary500: string;
  primary700: string;
} {
  // const { $brand } = useNuxtApp();

  return (
    checkBrand()?.designColors ?? {
      primary50: "#EDF2FF",
      primary100: "#D0DDFF",
      primary300: "#739AFF",
      primary500: "#1656FF",
      primary700: "#0B2B80",
    }
  );
}

// Didn't find an other solution to export sass variables, we cant import the css file twice so :export doesnt work
export function getUdsColors() {
  // @ts-expect-error
  const rootVariablesNames: string[] = Array.from(document.styleSheets)
    .filter((sheet) => sheet.href === null || sheet.href.startsWith(window.location.origin))
    .reduce(
      (acc, sheet) =>
        // @ts-expect-error
        (acc = [
          ...acc,
          // @ts-expect-error
          ...Array.from(sheet.cssRules as { selectorText: string; style: string }[]).reduce(
            (def, rule) =>
              // @ts-expect-error
              (def =
                rule.selectorText === ":root"
                  ? [
                      ...def,
                      ...Array.from(rule.style).filter((name: string) => name.startsWith("--uds")),
                    ]
                  : def),
            [],
          ),
        ]),
      [],
    );
  // @ts-expect-error
  return {
    ...rootVariablesNames
      .map((colorName: string) => {
        return {
          [colorName.substring(2)]: getComputedStyle(document.body).getPropertyValue(colorName),
        };
      })
      .reduce(
        (obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }),
        [],
      ),
  } as {
    udsBasil50: string;
    udsBasil500: string;
    udsBasil700: string;
    udsBubblegum50: string;
    udsBubblegum500: string;
    udsBubblegum700: string;
    udsCaramel50: string;
    udsCaramel500: string;
    udsCaramel700: string;
    udsCerulean50: string;
    udsCerulean500: string;
    udsCerulean700: string;
    udsError50: string;
    udsError100: string;
    udsError300: string;
    udsError500: string;
    udsError700: string;
    udsFern50: string;
    udsFern500: string;
    udsFern700: string;
    udsFire50: string;
    udsFire500: string;
    udsFire700: string;
    udsGoogleBlue: string;
    udsGuacamole50: string;
    udsGuacamole500: string;
    udsGuacamole700: string;
    udsHasData: string;
    udsIris50: string;
    udsIris500: string;
    udsIris700: string;
    udsLipstick50: string;
    udsLipstick500: string;
    udsLipstick700: string;
    udsNeutral50: string;
    udsNeutral100: string;
    udsNeutral200: string;
    udsNeutral300: string;
    udsNeutral400: string;
    udsNeutral500: string;
    udsNeutral600: string;
    udsNeutral700: string;
    udsNeutral800: string;
    udsNeutral900: string;
    udsOrange50: string;
    udsOrange500: string;
    udsOrange700: string;
    udsSuccess50: string;
    udsSuccess100: string;
    udsSuccess300: string;
    udsSuccess500: string;
    udsSuccess700: string;
    udsWhite: string;
  };
}
