import type { IPrestation} from "@silexpert/core";
import { PrestationType } from "@silexpert/core";

type PrestationState = {
  prestations: IPrestation[];
};

export const usePrestationStore = defineStore("prestation", {
  state: () =>
    ref<PrestationState>({
      prestations: [],
    }),
  getters: {
    socialPrestations: (state) =>
      state.prestations.filter((p) =>
        [
          PrestationType.FRUCTUPAIE,
          PrestationType.SOCIAL,
          PrestationType.MYFRUCTUCOMPTA_SOCIAL,
          PrestationType.MYFRUCTUCOMPTA_EXPRESS_SOCIAL,
          PrestationType.FRUCTUCOMPTA_ASSISTANCE_EXPRESS_SOCIAL,
          PrestationType.FRUCTUCOMPTA_ASSISTANCE_SOCIAL,
          PrestationType.FRUCTUCOMPTA_EXPRESS_SOCIAL,
          PrestationType.FRUCTUCOMPTA_SOCIAL,
          PrestationType.COMPTA_PRO_PAIE,
          PrestationType.COMPTA_PRO_EXPRESS_PAIE,
          PrestationType.COMPTA_ENTREPRISE_PAIE,
          PrestationType.COMPTA_ENTREPRISE_EXPRESS_PAIE,
        ].includes(p.idPrestationList),
      ),
  },
  actions: {
    reset() {
      this.prestations = [];
    },
    async fetchSocietyPrestations(idSociety: number) {
      try {
        this.prestations = await $silex().prestation.getAllFromSociety(idSociety);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
