import revive_payload_client_X8cU4gUqZo from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tvktjujVz2 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FrzDINdZke from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_t8AeuMLysI from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1pTuVlsWnX from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XdwkpyeyDj from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dfDeAKrgBp from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_LVbtDsytPb from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_S6r4B30yrz from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._nukzjiv6oki5f6khr5iqvmdxnq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_voFHt4S1vA from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.4_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_DW3CQKYyS4 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.24.4_vite@5.4.10_@types+node@22.9.0_sass@1.80.6_q43hnggtqw6dbjm4tl6nzrzygi/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_GfjyAhT0bB from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_Sk1WEbMA5K from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_2mmzt5cyzfyp5k3yzg5oahiao4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_pCy9jFK4q0 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.4_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_TnfMQ5EG76 from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typesc_6hdmqbfkz2upyc25ljoahfoa5y/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_brand_qsKxBO1fpE from "/app/plugins/01.brand.ts";
import _02_inject_dynamic_css_variables_client_UQ9uISccVp from "/app/plugins/02.inject-dynamic-css-variables.client.ts";
import _03_crisp_P9wmxnXAa7 from "/app/plugins/03.crisp.ts";
import _04_socket_yt7UzoOqjD from "/app/plugins/04.socket.ts";
import quill_editor_client_tupKYLQr8r from "/app/plugins/quill-editor.client.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import universal_design_system_client_NoP6UuzpPW from "/app/plugins/universal-design-system.client.ts";
import vue_draggable_OKRqLUiGtd from "/app/plugins/vue-draggable.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_X8cU4gUqZo,
  unhead_tvktjujVz2,
  router_FrzDINdZke,
  payload_client_t8AeuMLysI,
  navigation_repaint_client_1pTuVlsWnX,
  check_outdated_build_client_XdwkpyeyDj,
  chunk_reload_client_dfDeAKrgBp,
  plugin_vue3_LVbtDsytPb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_S6r4B30yrz,
  plugin_client_voFHt4S1vA,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_DW3CQKYyS4,
  plugin_zoF4yPIBkC,
  plugin_wy0B721ODc,
  plugin_client_GfjyAhT0bB,
  plugin_Sk1WEbMA5K,
  plugin_pCy9jFK4q0,
  plugin_TnfMQ5EG76,
  _01_brand_qsKxBO1fpE,
  _02_inject_dynamic_css_variables_client_UQ9uISccVp,
  _03_crisp_P9wmxnXAa7,
  _04_socket_yt7UzoOqjD,
  quill_editor_client_tupKYLQr8r,
  sentry_3AyO8nEfhE,
  universal_design_system_client_NoP6UuzpPW,
  vue_draggable_OKRqLUiGtd,
  vue_final_modal_pML93k5qcp
]