import type { IExercice } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
export type ExerciceState = {
  exercices: IExercice[];
  current: IExercice | null;
  deletedExercices: IExercice[];
};

export const useExerciceStore = defineStore("exercice", {
  state: () =>
    ref<ExerciceState>({
      exercices: [],
      deletedExercices: [],
      current: null,
    }),
  getters: {
    fiscalPeriods: (state: ExerciceState): IExercice[] => {
      const dayjs = useDayjs();
      const exercices = cloneDeep(state.exercices ?? []);
      return exercices.sort((a, b) => {
        const aYear = dayjs(a.startDate).format(EDateFormat.FULL_YEAR);
        const bYear = dayjs(b.startDate).format(EDateFormat.FULL_YEAR);
        const aMonth = dayjs(a.startDate).format(EDateFormat.DIGIT_MONTH);
        const bMonth = dayjs(b.startDate).format(EDateFormat.DIGIT_MONTH);

        return parseInt(aYear) - parseInt(bYear) === 0
          ? parseInt(aMonth) - parseInt(bMonth)
          : parseInt(aYear) - parseInt(bYear);
      });
    },
    defaultExercice: (state: ExerciceState): Partial<IExercice> => {
      const currentYYYY = new Date().getFullYear().toString();

      const exercices = state.exercices ?? [];

      if (exercices.length > 0) {
        // if the society has a default exercice, return it
        const defaultExercice = exercices.find((exercice) => exercice.isDefault === true);
        if (isDefined(defaultExercice)) {
          return defaultExercice;
        }

        // if the society has no default exercice, return the oldest exercice
        let latestExercice = exercices[0];
        for (const exercice of exercices) {
          if (
            exercice.startDate &&
            latestExercice.endDate &&
            new Date(exercice.startDate) > new Date(latestExercice.endDate)
          ) {
            latestExercice = exercice;
          }
        }
        return {
          id: latestExercice?.id,
          ...latestExercice,
        };
      } else {
        // if the society has no exercice, create a fake one for the current year
        return {
          startDate: new Date(`${currentYYYY}-01-01`),
          endDate: new Date(`${currentYYYY}-12-31`),
        };
      }
    },
    firstExercice: (state: ExerciceState): IExercice => {
      return state.exercices.sort(
        (a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime(),
      )[0];
    },
    previousExerciceFromCurrent: (state: ExerciceState): IExercice | null => {
      if (!state.current) return null;

      const dayjs = useDayjs();
      const previousEndDate = dayjs(state.current?.startDate).subtract(1, "day");

      return (
        state.exercices.find(
          (e) =>
            dayjs(e.endDate).format("YYYY-MM-DD") === dayjs(previousEndDate).format("YYYY-MM-DD"),
        ) ?? null
      );
    },
  },
  actions: {
    reset() {
      this.exercices = [];
      this.current = null;
      this.deletedExercices = [];
    },
    async fetchExercices(idSociety: number) {
      try {
        const result = await $silex().exercice.getAllBySocietyId(idSociety, { paranoid: false });
        this.exercices = result.filter((r) => !r.deletedAt);
        this.deletedExercices = result.filter((r) => r.deletedAt);
      } catch (error) {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      }
    },
    setCurrentExerciceIfEmpty() {
      if (isDefined(this.current)) return;
      const dayjs = useDayjs();

      this.current =
        this.exercices.find((e) =>
          this.defaultExercice.id
            ? this.defaultExercice.id === e.id
            : dayjs(e.startDate).isSame(this.defaultExercice.startDate) &&
              dayjs(e.endDate).isSame(this.defaultExercice.endDate),
        ) ?? null;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
