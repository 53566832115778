const excludedPaths = (path: string): boolean => {
  return (
    path.endsWith("/declaration/balancesheet") ||
    path.endsWith("/accounting/revisionguide") ||
    path.endsWith("/accounting/pendingpoints")
  );
};

export default defineNuxtRouteMiddleware((to) => {
  if (excludedPaths(to.fullPath.split("?")[0])) return;

  const commonStore = useCommonStore();
  const regex = /(20|21)\d{2}(-)(0[1-9]|1[1,2])(-)(0[1-9]|[12][0-9]|3[01])/g;

  const startDate =
    typeof to.query?.startDate === "string"
      ? (to.query?.startDate?.match(regex)?.[0] ?? null)
      : null;

  const endDate =
    typeof to.query?.endDate === "string" ? (to.query?.endDate?.match(regex)?.[0] ?? null) : null;

  if (
    isDefined(startDate) &&
    commonStore.queryProperties.startDate !== startDate &&
    startDate !== "1970-01-01"
  )
    commonStore.queryProperties.startDate = startDate;
  if (
    isDefined(endDate) &&
    commonStore.queryProperties.endDate !== endDate &&
    endDate !== "2170-12-31"
  )
    commonStore.queryProperties.endDate = endDate;
});
